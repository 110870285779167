import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { getImageRating, rateImage } from "../firebase/functions";
import { useUtilityStore } from "../zustand/utilityStores";

const RATINGS = {
  gross: "🤮",
  meh: "😐",
  good: "😀",
  awesome: "😍",
};

export function ImageRating(props: {
  imageId: string;
  className?: string;
  isPrivate: boolean;
}) {
  const [selectedRating, setSelectedRating] = useState<string | null>(null);
  const uid = useUtilityStore((state) => state.uid);
  const setSignInRequired = useUtilityStore((state) => state.setSignInRequired);

  // Get image rating and set selectedRating
  useEffect(() => {
    getImageRating(props.imageId, props.isPrivate)
      .then((rating) => {
        if (rating) {
          setSelectedRating(rating.rating);
        }
      })
      .catch((err) => {
        // This just means the iamge wasn't found, we can ignore.
      });
  }, [props.imageId, props.isPrivate]);

  const onClick = useCallback(
    (rating: string) => {
      if (!uid) {
        setSignInRequired(true);
        return;
      }

      rateImage(props.imageId, rating, true, props.isPrivate);
      setSelectedRating(rating);
    },
    [props.imageId, props.isPrivate, setSignInRequired, uid]
  );

  // Generate buttons from Ratings
  const buttons = Object.entries(RATINGS).map(([rating, emoji]) => {
    return (
      <button
        onClick={() => {
          onClick(rating);
        }}
        className={classNames("mx-2 hover:scale-125", {
          "opacity-50": selectedRating !== null && selectedRating !== rating,
        })}
        key={rating}
      >
        {emoji}
      </button>
    );
  });

  let containerClass = "flex justify-center flex-col items-center";
  if (props.className) {
    containerClass += " " + props.className;
  }

  return (
    <div className={containerClass}>
      <div className="text-white">Rating</div>
      <div className="bg-slate-600 py-2 px-2 rounded-lg text-2xl">
        {buttons}
      </div>
    </div>
  );
}
