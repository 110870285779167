import React from "react";
import { CATEGORY_ID_TO_STRING } from "../common/categoryDefinitions";

export default function CategorySelector() {
  const options = Object.keys(CATEGORY_ID_TO_STRING).map((key) => {
    return (
      <option key={key} value={key}>
        {CATEGORY_ID_TO_STRING[key as keyof typeof CATEGORY_ID_TO_STRING]}
      </option>
    );
  });

  return <React.Fragment>{options}</React.Fragment>;
}
