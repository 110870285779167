import { useState } from "react";
import JSONPretty from "react-json-pretty";
import {
  adminGiveFreePro,
  adminLookupUser,
  bindCCBillSubscription,
} from "../firebase/functions";
import toast from "react-hot-toast";
import { convertFirestoreDate } from "./AdminTagReview";
import { set } from "immer/dist/internal";
var JSONPrettyMon = require("react-json-pretty/dist/monikai");

const emptyResult = {
  result: "not found",
};

function processResult(result: any) {
  Object.keys(result).forEach((key) => {
    if (result[key]?._seconds) {
      result[key] = convertFirestoreDate(result[key]).toDateString();
    }
  });

  if (result["patreonData"]) {
    // Convert any dates in subfield
    Object.keys(result["patreonData"]).forEach((key) => {
      if (result["patreonData"][key]?._seconds) {
        result["patreonData"][key] = convertFirestoreDate(
          result["patreonData"][key]
        ).toDateString();
      }
    });
  }
}

export function AdminUserLookup() {
  const [query, setQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<any>(emptyResult);
  const [freeProDate, setFreeProDate] = useState<Date | null>(null);
  const [freeProStatus, setFreeProStatus] = useState<string>("No free pro");
  const [uid, setUid] = useState<string>("");
  const [ccSubscriptionId, setCcSubscriptionId] = useState<string>("");
  const [ccNextRenewalDate, setCcNextRenewalDate] = useState<Date | null>(null);
  const [isBinding, setIsBinding] = useState<boolean>(false);

  const onClickSearch = () => {
    setLoading(true);

    adminLookupUser(query)
      .then((res: any) => {
        processResult(res);
        setResult(res);
        setLoading(false);

        if (res.uid) {
          setUid(res.uid);
        }

        if (res.free_pro_until) {
          const freeDate = new Date(res.free_pro_until);
          setFreeProDate(freeDate);

          if (freeDate < new Date()) {
            setFreeProStatus("Free pro expired");
          } else {
            setFreeProStatus(
              "Free pro active until " + freeDate.toDateString()
            );
          }
        }

        if (res.cc_next_renewal_date) {
          const ccDate = new Date(res.cc_next_renewal_date);
          setCcNextRenewalDate(ccDate);
        }

        if (res.cc_subscription_id) {
          setCcSubscriptionId(res.cc_subscription_id);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  return (
    <div className="w-full pb-32">
      <div className="text-center text-2xl text-white">User Lookup</div>
      <div className="flex flex-row mt-2 max-w-lg m-auto">
        <input
          type="text"
          className="w-full rounded-lg"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          placeholder="email/sub id/uid"
        />
        <button
          className="text-white border rounded-lg ml-2 px-2 hover:opacity-70 disabled:opacity-70"
          onClick={onClickSearch}
          disabled={loading}
        >
          Search
        </button>
      </div>
      {loading && (
        <div className="text-white animate-pulse text-center mt-2">
          Loading...
        </div>
      )}
      {!loading && (
        <div className="overflow-scroll max-w-2xl m-auto">
          <JSONPretty
            className="mt-4"
            data={result || emptyResult}
            theme={JSONPrettyMon}
          />
        </div>
      )}
      <div className="text-center text-white mt-2 font-bold">
        Grant Free Pro
      </div>
      <div className="text-center text-white mb-2">Status: {freeProStatus}</div>
      <div className="flex justify-center mb-16">
        <input
          type="date"
          id="start"
          name="trip-start"
          value={freeProDate?.toISOString().substr(0, 10)}
          min="2023-01-01"
          max="2040-12-31"
          onChange={(e) => {
            toast("Granting free pro..., please wait");
            let timestampMillis = Date.parse(e.target.value);
            adminGiveFreePro(uid, timestampMillis).then((res) => {
              toast.success("Free pro granted");
              setFreeProDate(new Date(timestampMillis));
              setFreeProStatus(
                "Free pro active until " +
                  new Date(timestampMillis).toDateString()
              );
            });
          }}
        />
      </div>
      <div className="text-center text-white mt-2 font-bold">CCBill Utils</div>
      <div className="flex justify-center flex-col items-center">
        <div className="text-white mb-2">Subscription ID</div>
        <input
          type="text"
          className="rounded-lg mb-4"
          value={ccSubscriptionId}
          onChange={(e) => {
            setCcSubscriptionId(e.target.value);
          }}
          placeholder="ccBill Subscription ID"
        />
        <div className="text-white mb-2">
          Next Renewal Date (must be in future)
        </div>
        <input
          type="date"
          className="mb-2"
          id="start"
          name="trip-start"
          value={ccNextRenewalDate?.toISOString().substr(0, 10)}
          min="2023-01-01"
          max="2040-12-31"
          onChange={(e) => {
            let timestampMillis = Date.parse(e.target.value);
            setCcNextRenewalDate(new Date(timestampMillis));
          }}
        />
        <button
          className="text-white border-white px-4 py-2 border rounded-lg hover:opacity-70 disabled:opacity-30"
          disabled={isBinding}
          onClick={async () => {
            let dateString = ccNextRenewalDate?.toISOString().substr(0, 10);

            if (!dateString) {
              toast.error("Please enter a date");
              return;
            }

            // Ensure date is in future
            if (new Date(dateString) < new Date()) {
              toast.error("Date must be in future");
              return;
            }

            setIsBinding(true);
            try {
              await bindCCBillSubscription(ccSubscriptionId, dateString, uid);
              toast.success("Subscription bound");
              onClickSearch();
            } catch (err: any) {
              toast.error(err.message);
            }

            setIsBinding(false);
          }}
        >
          Bind
        </button>
      </div>
    </div>
  );
}
