import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./AppRouter";
import "allotment/dist/style.css";
import "./firebase/auth";
import { QueryClient, QueryClientProvider } from "react-query";
import Maintenance from "./screens/Maintenance";

// constant for considering the site is in maintenance or not.
const IS_MAINTENANCE = false;

// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (IS_MAINTENANCE) {
  root.render(
    <Maintenance />
  );
} else {
  root.render(
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </QueryClientProvider>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
