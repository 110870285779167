import { useEffect, useState } from "react";
import { ScreenTitle } from "../components/ScreenTitle";
import { TagArtist } from "../common/userDefinitions";
import {
  adminGetTagArtists,
  adminUpdateTagArtist,
} from "../firebase/functions";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

export function AdminTagArtist() {
  const [query, setQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [result, setResult] = useState<TagArtist[]>([]);

  useEffect(() => {
    adminGetTagArtists().then((res) => {
      setResult(res);
      setLoading(false);
    });
  }, []);

  const onClickAdd = () => {
    setLoading(true);
    adminUpdateTagArtist(query, true)
      .then((res) => {
        toast.success("Added tag artist");
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to add tag artist");
        setLoading(false);
      });
  };

  const onClickRemove = (removeUid: string) => {
    setLoading(true);
    adminUpdateTagArtist(removeUid, false)
      .then((res) => {
        toast.success("Removed tag artist");
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to remove tag artist");
        setLoading(false);
      });
  };

  return (
    <div>
      <ScreenTitle>Tag Artists</ScreenTitle>
      <div className="flex flex-row mt-2 max-w-lg m-auto">
        <input
          type="text"
          className="w-full rounded-lg"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          placeholder="enter uid"
        />
        <button
          className="text-white border rounded-lg ml-2 px-2 hover:font-bold"
          onClick={onClickAdd}
        >
          Add
        </button>
      </div>
      {loading && <div className="text-white animate-pulse">Loading...</div>}
      {result.map((tagArtist) => {
        return (
          <div className="flex flex-row mt-2 max-w-lg m-auto">
            <Link
              to={`/tags/artist/${tagArtist.username}`}
              className="w-full rounded-lg text-white underline"
            >
              {tagArtist.username}
            </Link>
            <button
              className="text-white border rounded-lg ml-2 px-2 hover:font-bold"
              onClick={() => {
                onClickRemove(tagArtist.uid);
              }}
            >
              Remove
            </button>
          </div>
        );
      })}
    </div>
  );
}
