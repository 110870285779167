import create from "zustand";
import produce from "immer";
import { SearchResult } from "../firebase/searchUtils";
import { disclaimerKey } from "../components/DisclaimerModal";

const didAccept = localStorage.getItem(disclaimerKey) === "didAccept";
export const INPAINT_MODES = ["change", "keep", "morph", "fix"] as const;
export type INPAINT_MODE = typeof INPAINT_MODES[number];

interface HistoryStore {
  lastResults: SearchResult[];
  setLastResults: (results: SearchResult[]) => void;
  lastTaskId: string;
  setLastTaskId: (taskId: string) => void;
  isWaiting: boolean;
  setIsWaiting: (isWaiting: boolean) => void;
  didAccept: boolean;
  setDidAccept: (didAccept: boolean) => void;
  shouldInvertPaintingMask: INPAINT_MODE;
  setShouldInvertPaintingMask: (shouldInvertPaintingMask: INPAINT_MODE) => void;
  brushSize: number;
  setBrushSize: (brushSize: number) => void;
  colorHint: string;
  setColorHint: (colorHint: string) => void;
}

export const useHistoryStore = create<HistoryStore>((set) => ({
  lastResults: [],
  lastTaskId: "",
  didAccept: didAccept,
  setDidAccept: (didAccept) => {
    set(produce((draft) => {
      draft.didAccept = didAccept;
    }));
  },
  isWaiting: false,
  setIsWaiting: (isWaiting) =>
    set(
      produce((state) => {
        state.isWaiting = isWaiting;
      })
    ),
  setLastTaskId: (taskId: string) =>
    set(
      produce((state) => {
        state.lastTaskId = taskId;
      })
    ),
  setLastResults: (results: SearchResult[]) => {
    set(
      produce((draft) => {
        draft.lastResults = results;
      })
    );
  },
  shouldInvertPaintingMask: "change",
  setShouldInvertPaintingMask: (shouldInvertPaintingMask: INPAINT_MODE) => {
    set(
      produce((draft) => {
        draft.shouldInvertPaintingMask = shouldInvertPaintingMask;
      })
    );
  },
  brushSize: 20,
  setBrushSize: (brushSize: number) => {
    set(
      produce((draft) => {
        draft.brushSize = brushSize;
      })
    );
  },
  colorHint: "#000000",
  setColorHint: (colorHint: string) => {
    set(
      produce((draft) => {
        draft.colorHint = colorHint;
      })
    );
  }
}));
