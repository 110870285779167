import {
  collection,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { Select } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  CATEGORY_ID_TO_OUTPUT
} from "../common/categoryDefinitions";
import AllTagSelector from "../components/AllTagSelector";
import CategorySelector from "../components/CategorySelector";
import { GeneratorSelect } from "../components/GeneratorSelector";
import { TagModal } from "../components/TagModal";
import { sendPrompt } from "../firebase/functions";
import { SearchResult } from "../firebase/searchUtils";
import { db } from "../firebase/setupFirebase";
import { generatePayload } from "../generator/tagGenerator";
import { TagStore, useTagStore } from "../zustand/tagStore";
import { resolveImageUrl } from "../utils/strings";
import { SelectedTags } from "../components/SelectedTags";

export function TagStudio() {
  const [generator, setGenerator] =
    useState<TagStore["generator"]>("women_crisp");

  const setGeneratorGlobal = useTagStore((state) => state.setGenerator);
  const [promptText, setPromptText] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("tags");
  const [taskId, setTaskId] = useState<string>("");
  const [lastResults, setLastResults] = useState<SearchResult[]>([]);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const clearTags = useTagStore((state) => state.clearTags);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [seed, setSeed] = useState<string>("");
  const [potentialSeed, setPotentialSeed] = useState<string>("");

  const onClickGenerate = useCallback(async () => {
    setError(null);
    setLastResults([]);
    setPotentialSeed("");

    let payload = generatePayload();

    const outputCategory =
      CATEGORY_ID_TO_OUTPUT[
        selectedCategory as keyof typeof CATEGORY_ID_TO_OUTPUT
      ];

    if (promptText) {
      payload[outputCategory].unshift("placeholder");
    }

    let seedNum = parseInt(seed);
    let data: any = {
      prompt: payload,
      generator,
      isPrivate: true,
      studioText: promptText,
    };

    if (!isNaN(seedNum)) {
      data.studioSeed = seedNum;
    }

    try {
      setIsWaiting(true);
      let result = await sendPrompt(data);

      if (result.id) {
        setTaskId(result.id);
      }
    } catch (e) {
      setIsWaiting(false);
      setError((e as any).message);
      console.log(e);
    }
  }, [promptText, selectedCategory, generator, seed]);

  // Listen for the task ID to finish.
  useEffect(() => {
    if (!taskId) {
      return;
    }

    const collectionRef = collection(db, "studio");

    const q = query(collectionRef, where("task_id", "==", taskId), limit(10));

    return onSnapshot(q, (querySnapshot) => {
      let imageResults: SearchResult[] = [];

      // Ignore multiple results
      if (querySnapshot.docs.length > 0) {
        const doc = querySnapshot.docs[0];

        let data = doc.data();
        if (data.error) {
          if (data.error === "safety") {
            setError("Safety filter blocked this image. Try again.");
          }

          setIsWaiting(false);
          return;
        }

        if (!data.image_url || !data.task_id) {
          return;
        }

        if (data.seed) {
          setPotentialSeed(data.seed);
        }

        imageResults.push({
          imageUrl: data.image_url,
          imageId: doc.id,
        });
      }

      if (imageResults.length > 0) {
        setLastResults(imageResults);
        setIsWaiting(false);
      }
    });
  }, [taskId, setLastResults, setIsWaiting]);

  return (
    <div className="text-white w-full max-w-3xl flex justify-center flex-col items-center">
      <div className="text-2xl mb-2">Tag Studio</div>
      <div className="flex flex-row w-full mb-2">
        <div className="w-1/4 mr-2">
          <Select
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
            }}
          >
            <CategorySelector />
          </Select>
        </div>
        <div className="w-3/4 text-black">
          <input
            max={500}
            placeholder="prompt"
            className="w-full rounded-lg"
            type="text"
            value={promptText}
            onChange={(e) => setPromptText(e.target.value)}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between w-full">
        <div>
          Seed
          <input
            value={seed}
            onChange={(e) => setSeed(e.target.value)}
            className="ml-2 rounded-lg text-sm w-32 text-black"
            type="text"
          />
        </div>
        <div>
          <button
            className="underline mr-2"
            onClick={() => setShowSaveModal(true)}
          >
            Save
          </button>
          <Link to="/tags/manage" className="underline">
            My Tags
          </Link>
        </div>
      </div>
      <div>
        <button
          onClick={onClickGenerate}
          disabled={isWaiting}
          className="bg-blue-600 text-white px-8 py-4 rounded-lg disabled:opacity-70 mb-4"
        >
          Generate
        </button>
      </div>
      {error && <div className="text-red-500">{error}</div>}
      {isWaiting && (
        <div className="text-white text-center mt-4">
          <div className="animate-pulse max-w-sm sm:max-w-lg max-h-[512px] w-screen aspect-square bg-zinc-500 flex justify-center items-center flex-col">
            <div className="font-bold">
              <span className="text-yellow-400">
                <span className="font-bold">Tag Studio </span>
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-row overflow-auto justify-center mb-4">
        {lastResults.map((url) => (
          <div key={url.imageId}>
            <img src={resolveImageUrl(url.imageUrl)} alt="studio result" />
          </div>
        ))}
      </div>
      {potentialSeed && (
        <button
          className="text-white text-center my-2 underline"
          onClick={() => {
            setSeed(potentialSeed);
            setPotentialSeed("");
          }}
        >
          Set seed: {potentialSeed}
        </button>
      )}
      <GeneratorSelect
        value={generator}
        onChangeGenerator={(generator) => {
          setGenerator(generator);
          setGeneratorGlobal(generator);
          clearTags();
        }}
        showPreview
      />
      <div className="w-full">
        <SelectedTags />
      </div>
      <AllTagSelector showDefault showCommunityTags />
      <TagModal
        showModal={showSaveModal}
        setShowModal={setShowSaveModal}
        prompt={promptText}
        categoryId={selectedCategory}
      />
    </div>
  );
}
