import {
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { logPage } from "../analytics/googleAnalytics";
import { db } from "../firebase/setupFirebase";
import { ImageWithLink } from "../components/ImageWithLink";
import { SearchResult } from "../firebase/searchUtils";
import { useUtilityStore } from "../zustand/utilityStores";
import {
  sendPrompt,
  submitPainting,
  submitUncrop,
  upscaleToSDXL,
} from "../firebase/functions";
import { Result } from "../generator/tagGenerator";
import {
  lastPaintingInfo,
  lastSDXLUpscaleInfo,
  lastSmartEditInfo,
  lastUncropInfo,
} from "../utils/painting";
import { INPAINT_MODE } from "../zustand/historyStore";
import { ProError } from "../components/ProError";
import "./EditViewScreen.css";

const EDIT_TYPES = [
  "painting",
  "enhance",
  "uncrop",
  "upscale",
  "edit",
  "seed_edit",
  "smart_edit",
  "upscale_sdxl",
] as const;

type EditTypes = (typeof EDIT_TYPES)[number];

export default function EditViewScreen() {
  let match = useMatch("/edits/:imageId/:taskId");
  let imageId = match?.params.imageId;
  let taskId = match?.params.taskId;

  const [original, setOriginal] = useState<SearchResult>();
  const [editResult, setEditResult] = useState<SearchResult>();
  const [isWaiting, setIsWaiting] = useState(true);
  const [isWaitingReroll, setIsWaitingReroll] = useState(false);
  const [isError, setIsError] = useState(false);
  const isPro = useUtilityStore((state) => state.isPro);
  const navigate = useNavigate();
  const [type, setType] = useState<EditTypes>("painting");
  const [isPrivate, setIsPrivate] = useState(false);
  const uid = useUtilityStore((state) => state.uid);
  const [error, setError] = useState("");
  const [isUpscale, setIsUpscale] = useState(false);

  useEffect(() => {
    logPage("edit");

    // Look for query parameter called "type"
    const urlParams = new URLSearchParams(window.location.search);
    let typeParam = urlParams.get("type");
    if (typeParam && EDIT_TYPES.includes(typeParam as EditTypes)) {
      setType(typeParam as EditTypes);
    }

    if (typeParam === "upscale") {
      setIsUpscale(true);
    }

    // If this is a private gen, we need to change the urls
    typeParam = urlParams.get("private");
    if (typeParam && ["true", "false"].includes(typeParam)) {
      setIsPrivate(typeParam === "true");
    }
  }, []);

  // Listen for task id
  useEffect(() => {
    if (!taskId) {
      return;
    }

    if (isPrivate && !uid) {
      return;
    }

    const q = isPrivate
      ? query(
          collection(db, "users", uid, "private"),
          where("task_id", "==", taskId),
          limit(1)
        )
      : query(
          collection(db, "results"),
          where("task_id", "==", taskId),
          limit(1)
        );

    return onSnapshot(q, (querySnapshot) => {
      let imageResults: SearchResult[] = [];

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        if (data.error && data.error === "safety") {
          setError("Sorry, this image was flagged as unsafe. Please reroll.");
          setIsWaiting(false);
          return;
        }

        if (!data.image_url || !data.task_id) {
          return;
        }

        imageResults.push({
          imageUrl: data.image_url,
          imageId: doc.id,
        });
      });

      if (imageResults.length > 0) {
        setEditResult(imageResults[0]);
        setIsWaiting(false);
        const newImageId = imageResults[0].imageId;

        // For upscales, just take you to the page.
        if (isUpscale) {
          const link = isPrivate
            ? `/private/${newImageId}`
            : `/view/${newImageId}`;

          navigate(link, { replace: true });
        }
      }
    });
  }, [taskId, setEditResult, setIsWaiting, isPrivate, uid, isUpscale]);

  // Fetch original image
  useEffect(() => {
    if (!imageId) {
      return;
    }

    if (isPrivate) {
      if (!uid) {
        return;
      }
    }

    const docRef = isPrivate
      ? doc(db, "users", uid, "private", imageId)
      : doc(db, "results", imageId);

    const fetchData = async () => {
      const docSnap = await getDoc(docRef);
      let data = docSnap.data();

      if (docSnap.exists() && data) {
        setOriginal({
          imageUrl: data.image_url,
          imageId: docSnap.id,
        });
      } else {
        // doc.data() will be undefined in this case
      }
    };
    fetchData();
  }, [imageId, isPrivate, uid]);

  const onClickRerollPainting = () => {
    async function fetchData() {
      if (!imageId || !taskId || !lastPaintingInfo.lastPromptData) {
        console.log("No imageId or taskId");
        return;
      }

      setIsWaitingReroll(true);
      setIsError(false);
      setError("");

      try {
        let result = await submitPainting(
          imageId,
          lastPaintingInfo.lastPromptData as unknown as Result,
          lastPaintingInfo.lastMask,
          lastPaintingInfo.lastGenerator,
          lastPaintingInfo.lastDidInvert as INPAINT_MODE,
          lastPaintingInfo.lastColorHint,
          lastPaintingInfo.lastPrivate,
          lastPaintingInfo.lastEnhancePaint
        );

        if (result.id) {
          lastPaintingInfo.lastTaskId = result.id;
          navigate(
            `/edits/${imageId}/${result.id}?private=${isPrivate}&type=${type}`,
            { replace: false }
          );
          // Reset local state
          setEditResult(undefined);
          setIsWaiting(true);
        }
      } catch (e) {
        console.error(e);
        setIsError(true);
      }
      setIsWaitingReroll(false);
    }

    fetchData();
  };

  const onClickRerollUncrop = () => {
    async function fetchData() {
      if (!imageId || !taskId || !lastUncropInfo.scale) {
        console.error("No imageId or taskId");
        return;
      }

      setIsWaitingReroll(true);
      setIsError(false);
      setError("");

      try {
        const scale = lastUncropInfo.scale;
        const promptData = lastUncropInfo.lastPromptData as unknown as Result;

        const result = await submitUncrop(
          imageId,
          scale,
          promptData,
          lastUncropInfo.lastGenerator,
          lastUncropInfo.lastOffsetX,
          lastUncropInfo.lastOffsetY,
          lastUncropInfo.lastPrivate,
          lastUncropInfo.model
        );
        lastUncropInfo.lastTaskId = result.id;
        lastUncropInfo.scale = scale;

        if (result.id) {
          lastUncropInfo.lastTaskId = result.id;
          navigate(
            `/edits/${imageId}/${result.id}?private=${isPrivate}&type=${type}`,
            { replace: false }
          );

          // Reset local state
          setEditResult(undefined);
          setIsWaiting(true);
        }
      } catch (e) {
        console.error(e);
        setIsError(true);
      }
      setIsWaitingReroll(false);
    }

    fetchData();
  };

  const onClickRerollSmartEdit = async () => {
    setIsWaitingReroll(true);

    let data: any = {
      prompt: lastSmartEditInfo.lastPromptData,
      generator: lastSmartEditInfo.lastGenerator,
      isPrivate: lastSmartEditInfo.lastPrivate,
      smartEditId: lastSmartEditInfo.smartEditId,
    };

    try {
      let result = await sendPrompt(data);

      if (result.id) {
        lastSmartEditInfo.lastTaskId = result.id;
        navigate(
          `/edits/${imageId}/${result.id}?private=${isPrivate}&type=${type}`,
          { replace: false }
        );
        // Reset local state
        setEditResult(undefined);
        setIsWaiting(true);
      }
    } catch (e) {
      console.error(e);
      setIsError(true);
    }

    setIsWaitingReroll(false);
  };

  const onClickRerollSDXLUpscale = async () => {
    try {
      const result = await upscaleToSDXL(
        lastSDXLUpscaleInfo.lastImageId,
        lastSDXLUpscaleInfo.lastPrivate,
        lastSDXLUpscaleInfo.lastPromptData as any,
        lastSDXLUpscaleInfo.lastGenerator,
        false
      );

      if (result.id) {
        lastSDXLUpscaleInfo.lastTaskId = result.id;
        navigate(
          `/edits/${lastSDXLUpscaleInfo.lastImageId}/${result.id}?private=${lastSDXLUpscaleInfo.lastPrivate}&type=upscale_sdxl`,
          { replace: false }
        );
        // Reset local state
        setEditResult(undefined);
        setIsWaiting(true);
      }
    } catch (e) {
      console.error(e);
      setIsError(true);
    }
  };

  let rerollFunction = onClickRerollPainting;

  if (lastUncropInfo.lastTaskId === taskId) {
    rerollFunction = onClickRerollUncrop;
  }

  if (lastSmartEditInfo.lastTaskId === taskId) {
    rerollFunction = onClickRerollSmartEdit;
  }

  if (lastSDXLUpscaleInfo.lastTaskId === taskId) {
    rerollFunction = onClickRerollSDXLUpscale;
  }

  let subtitle = "Fast Edit";

  if (type === "enhance") {
    subtitle = "Fixing details";
  } else if (isUpscale) {
    subtitle = "Upscaling";
  } else if (type === "uncrop") {
    subtitle = "Uncropping";
  } else if (type === "smart_edit") {
    subtitle = "Smart Edit";
  } else if (type === "upscale_sdxl") {
    subtitle = "Upscaling w/ SDXL. This takes additional time 🙏";
  }

  return (
    <div className="text-white">
      <div>
        <div className="flex justify-center flex-wrap">
          {original && (
            <ImageWithLink
              imageId={original?.imageId}
              imageUrl={original?.imageUrl}
              isPrivate={isPrivate}
              subtitle="Original"
              appendClassname="animateOpen"
            />
          )}
          {isWaiting && (
            <div className="animate-pulse max-w-sm sm:max-w-lg max-h-[512px] w-screen aspect-square bg-zinc-500 flex justify-center items-center">
              <div className="text-center text-xl mt-2">
                {isPro ? (
                  <span className="text-yellow-400 animateOpen">
                    <span className="font-bold">Pro mode: </span>
                    {subtitle}
                    {type === "enhance" && (
                      <div>
                        <span className="font-bold">
                          This takes additional time 🙏 <br />
                          (max 5 mins)
                        </span>
                      </div>
                    )}
                  </span>
                ) : (
                  <div className="p-4">
                    Editing... This could take up to 5 minutes
                    <br />
                    <a
                      href="/getpro"
                      className="underline font-bold text-yellow-400"
                    >
                      Try Pro Mode for faster edits
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}
          {error && (
            <div className="max-w-sm sm:max-w-lg max-h-[512px] w-screen aspect-square bg-slate-800 flex justify-center items-center">
              <div className="text-center text-xl mt-2">
                <div className="text-red-500 mt-4 px-4 max-w-lg font-bold">
                  Sorry! This image accidentally triggered the safety filter.
                  Please try again. If this keeps happening, try changing the
                  tags/generator.
                </div>
              </div>
            </div>
          )}
          {editResult && (
            <ImageWithLink
              imageId={editResult?.imageId}
              imageUrl={editResult?.imageUrl}
              isPrivate={isPrivate}
              imageClassname={isUpscale ? "w-full" : undefined}
              appendClassname="animateOpen"
            />
          )}
        </div>
        {(lastPaintingInfo.lastTaskId === taskId ||
          lastUncropInfo.lastTaskId === taskId ||
          lastSmartEditInfo.lastTaskId === taskId ||
          lastSDXLUpscaleInfo.lastTaskId === taskId) &&
          !isWaiting && (
            <div className="flex justify-center mt-4 flex-col items-center">
              <div className="flex flex-row">
                {type === "painting" && (
                  <button
                    className="text-white rounded-lg py-4 px-8 mb-8 disabled:opacity-60 disabled:animate-pulse border mr-4"
                    onClick={() => {
                      // Go back
                      const link = isPrivate
                        ? `/private/${imageId}`
                        : `/view/${imageId}`;

                      navigate(link + "?editing=painting");
                    }}
                  >
                    Back
                  </button>
                )}
                <button
                  onClick={rerollFunction}
                  className="GradientButton text-white rounded-lg py-2 px-8 mb-8 disabled:opacity-60 disabled:animate-pulse text-2xl hover:scale-105 transform transition duration-300 ease-in-out"
                  disabled={isWaitingReroll}
                >
                  Reroll
                </button>
              </div>
              {isError && !isPro && (
                <div className="text-red-600">
                  Error: Server overloaded. Please try again.
                </div>
              )}
              {isError && isPro && <ProError />}
            </div>
          )}
      </div>
    </div>
  );
}
