import { useCallback, useEffect, useRef, useState } from "react";
import "./App.css";
import { generatePayload } from "./generator/tagGenerator";
import {
  collection,
  query,
  where,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { db } from "./firebase/setupFirebase";
import { Link, useNavigate } from "react-router-dom";
import { useHistoryStore } from "./zustand/historyStore";
import { logEvent, logPage } from "./analytics/googleAnalytics";
import {
  deleteSavedImage,
  getPublicTagDataFromIds,
  saveImage,
  sendPrompt,
  submitSearch,
} from "./firebase/functions";
import { ImageWithLink } from "./components/ImageWithLink";
import AllTagSelector from "./components/AllTagSelector";
import { SearchResult } from "./firebase/searchUtils";
import {
  getNumSelected,
  getNumSelectedText,
  useTagStore,
} from "./zustand/tagStore";
import { useUtilityStore } from "./zustand/utilityStores";
import classNames from "classnames";
import { GENERATOR_TO_STRING } from "./utils/strings";
import { generateTagURL } from "./utils/navigation";
import { ProError } from "./components/ProError";
import { ImageRating } from "./components/ImageRating";
import { toast } from "react-hot-toast";
import { DeleteModal } from "./components/DeleteModal";
import { Select } from "flowbite-react";
import { ImageFolderModal } from "./components/ImageFolderModal";
import FolderAddSvg from "./images/folder_add.svg";
import ThreeIcon from "./images/3d.svg";
import DoodleIcon from "./images/doodle.svg";
import CopyIcon from "./images/copy.svg";
import ClearTagsIcon from "./images/clear_tags.svg";
import GifIcon from "./images/gif.svg";
import ClearIcon from "./images/clear.svg";

import {
  DirectorScreen,
  getScreenshotDirector,
} from "./screens/DirectorScreen";
import { SelectedTags } from "./components/SelectedTags";
import { Doodle, getDoodleImage } from "./screens/Doodle";
import { GeneratorModal } from "./components/GeneratorModal";
import { useModelMap } from "./zustand/modelStore";
const lz = require("lz-string");

function App() {
  const lastResults = useHistoryStore((state) => state.lastResults);
  const setLastResults = useHistoryStore((state) => state.setLastResults);
  const lastTaskId = useHistoryStore((state) => state.lastTaskId);
  const setLastTaskId = useHistoryStore((state) => state.setLastTaskId);
  const isWaiting = useHistoryStore((state) => state.isWaiting);
  const setIsWaiting = useHistoryStore((state) => state.setIsWaiting);
  const didAccept = useHistoryStore((state) => state.didAccept);
  const [isOverload, setIsOverload] = useState(false);
  const clearTags = useTagStore((state) => state.clearTags);
  const setTag = useTagStore((state) => state.setTag);
  const generator = useTagStore((state) => state.generator);
  const setGenerator = useTagStore((state) => state.setGenerator);
  const selectedTags = useTagStore((state) => state.selectedTags);
  const isPro = useUtilityStore((state) => state.isPro);
  const [isUsingCachedResult, setIsUsingCachedResult] = useState(false);
  const isSignedIn = useUtilityStore((state) => state.isSignedIn);
  const setSignInRequired = useUtilityStore((state) => state.setSignInRequired);
  const uid = useUtilityStore((state) => state.uid);
  const [didCopy, setDidCopy] = useState(false);
  const didLoadQueryParams = useRef(false);
  const cachedResults = useRef<SearchResult[]>([]);
  const cacheIndex = useRef(0);
  const cacheHits = useRef(0);
  const setIsPrivateMode = useUtilityStore((state) => state.setIsPrivateMode);
  const studioTags = useTagStore((state) => state.studioTags);
  const appendStudioTags = useTagStore((state) => state.appendStudioTags);
  const [error, setError] = useState<string | null>("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageToDelete, setImageToDelete] = useState<SearchResult | null>(null);
  const [aspectRatio, setAspectRatio] = useState<string>("1:1");
  const [showImageFolderModal, setShowImageFolderModal] = useState(false);
  const [imageIdForFolder, setImageIdForFolder] = useState<string | null>(null);
  const [savedImages, setSavedImages] = useState<string[]>([]);
  const [directorMode, setDirectorMode] = useState(false);
  const [doodleMode, setDoodleMode] = useState(false);
  const [videoMode, setVideoMode] = useState(false);
  const [numResults, setNumResults] = useState(1);
  const referenceImageUrl = useUtilityStore((state) => state.referenceImageUrl);
  const setReferenceImageUrl = useUtilityStore(
    (state) => state.setReferenceImageUrl
  );
  const modelMap = useModelMap();

  // Bug if you set this after generating a normal pic, it will think its private (thelink).
  let isPrivateMode = useUtilityStore((state) => state.isPrivateMode) && isPro;
  const navigate = useNavigate();

  if (directorMode || doodleMode) {
    isPrivateMode = true;
  }

  useEffect(() => {
    logPage("make");
  }, []);

  useEffect(() => {
    if (generator === "all") {
      setGenerator("women_crisp");
    }
  }, [generator, setGenerator]);

  // Load tags from URL
  useEffect(() => {
    if (didLoadQueryParams.current) {
      return;
    }
    // Get window location query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const compressed = urlParams.get("tags");
    const parsed = lz.decompressFromEncodedURIComponent(compressed || "");
    const parsedParams = new URLSearchParams("?" + parsed);

    const generator = parsedParams.get("generator");
    const animated = urlParams.get("animated");
    const tags = parsedParams.get("tags");

    if (animated === "true") {
      setVideoMode(true);
    }

    if (
      generator &&
      GENERATOR_TO_STRING[generator as keyof typeof GENERATOR_TO_STRING]
    ) {
      setGenerator(generator);
    }

    didLoadQueryParams.current = true;

    if (!tags) {
      return;
    }

    // Remove ?tags= from the URL
    window.history.replaceState({}, document.title, "/make");

    clearTags();

    // Parse tags, as comma separated values
    const parsedTags = tags.split(",");
    let unresolvedTags: string[] = [];

    // Set tags
    parsedTags.forEach((tag) => {
      if (tag.startsWith("studio_")) {
        if (!studioTags.some((t) => t.id === tag)) {
          unresolvedTags.push(tag);
        }
      } else {
        setTag(tag, true);
      }
    });

    // Must be set timeout or else it will not work
    // Due to some weird race condition
    setTimeout(() => {
      toast("Loaded tags from URL", { icon: "🔗" });
    }, 1000);

    getPublicTagDataFromIds(unresolvedTags).then((data) => {
      // Convert object to array, but add new property "id" which is the key
      const values = Object.keys(data).map((key) => ({
        ...data[key],
        id: key,
        isSaved: true,
      }));

      // Set studio tags
      values.forEach((tag) => {
        setTag("studio_" + tag.id, true);
      });

      // Append to studio tags
      appendStudioTags(values);
    });
  });

  const onClickVideo = useCallback(() => {
    if (!isPro) {
      navigate("/getpro");
      toast("You need to be a Pro member to use this generator.");
      return;
    }

    setDirectorMode(false);
    setDoodleMode(false);
    setVideoMode(!videoMode);
  }, [videoMode, isPro, navigate]);

  let onClickGenerate = useCallback(async () => {
    if (modelMap[generator].sdModel === "sdxl" && !isPro) {
      navigate("/getpro");
      toast("You need to be a Pro member to use this generator.");
      return;
    }

    logEvent("click_generate");
    window.scrollTo(0, 0);
    setIsWaiting(true);
    setLastResults([]);
    setIsOverload(false);
    setIsUsingCachedResult(false);
    setError(null);

    // If a cached result exists, use that and ease load on server
    if (
      cachedResults.current.length > 0 &&
      cacheIndex.current < cachedResults.current.length - 1 &&
      cacheHits.current < 10
    ) {
      cacheIndex.current += 1;
      cacheHits.current += 1;

      setLastResults(
        cachedResults.current.slice(cacheIndex.current, cacheIndex.current + 1)
      );
      setIsUsingCachedResult(true);
      setIsWaiting(false);
      return;
    }

    cacheHits.current = 0;

    // if (executeRecaptcha) {
    //   try {
    //     let responseToken = await executeRecaptcha("generate");
    //     submitRecaptcha(responseToken);
    //   } catch (e) {
    //     console.error(e);
    //   }
    // }

    // Free users will still have the state "isPrivateMode"
    // So we need to check if they are pro
    let usePrivateMode = isPrivateMode && isPro;

    // Director mode forces private mode
    if (directorMode || doodleMode) {
      usePrivateMode = true;
    }

    let data: any = {
      prompt: generatePayload(),
      generator,
      isPrivate: usePrivateMode,
      aspectRatio,
    };

    // If director mode, then we need to send the director image
    if (directorMode) {
      data["directorImg"] = getScreenshotDirector().replace(
        /^data:image\/[a-z]+;base64,/,
        ""
      );
    }

    if (doodleMode) {
      data["doodleImg"] = getDoodleImage().replace(
        /^data:image\/[a-z]+;base64,/,
        ""
      );
    }

    if (videoMode) {
      data.animated = true;
      data.model = "animate";
    }

    data.referenceImageUrl = referenceImageUrl;

    try {
      let result = await sendPrompt(data);

      if (result.id) {
        setLastTaskId(result.id);

        // Check how many images we are getting from the server.
        if (result.numTasks) {
          setNumResults(result.numTasks);
        } else {
          setNumResults(1);
        }
      } else {
        setIsOverload(true);
        setIsWaiting(false);
        // Clear cached results
        cachedResults.current = [];
      }
    } catch (e: any) {
      // If we have a cached result, increment index and use that
      if (
        cachedResults.current.length > 0 &&
        cacheIndex.current < cachedResults.current.length - 1
      ) {
        cacheIndex.current += 1;
        setLastResults(
          cachedResults.current.slice(
            cacheIndex.current,
            cacheIndex.current + 1
          )
        );
        setIsUsingCachedResult(true);
      } else {
        // Only fetch cached results if not pro
        if (!isPro) {
          try {
            // Remove default keys
            let filteredKeys = Object.keys(selectedTags)
              .filter((tag) => tag.indexOf("_default") === -1)
              .filter((tag) => selectedTags[tag]);

            let results = (await submitSearch(
              filteredKeys,
              generator,
              "make"
            )) as SearchResult[];

            cachedResults.current = results;
            cacheIndex.current = 0;

            if (results && results.length > 0) {
              setLastResults(results.slice(0, 1));
              setIsUsingCachedResult(true);
            }
          } catch (e) {
            console.error(e);
          }
        }
      }

      if (e.code === "functions/resource-exhausted") {
        if (!isPro) {
          setIsOverload(true);
        } else {
          toast(e.message || "Please wait between images");
        }
      } else {
        setIsOverload(true);
      }

      setIsWaiting(false);
    }
  }, [
    generator,
    selectedTags,
    setIsWaiting,
    setLastResults,
    setLastTaskId,
    isPro,
    isPrivateMode,
    aspectRatio,
    directorMode,
    doodleMode,
    modelMap,
    navigate,
    videoMode,
  ]);

  // Listen for the task ID to finish.
  useEffect(() => {
    if (!lastTaskId) {
      return;
    }

    const isPrivate = isPrivateMode && uid && isPro;

    const collectionRef = isPrivate
      ? collection(db, "users", uid, "private")
      : collection(db, "results");

    const q = query(
      collectionRef,
      where("task_id", "==", lastTaskId),
      limit(10)
    );

    return onSnapshot(q, (querySnapshot) => {
      let imageResults: SearchResult[] = [];

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        if (data.error) {
          setError(data.error);
          setIsWaiting(false);
          return;
        }

        if (!data.image_url || !data.task_id) {
          return;
        }

        imageResults.push({
          imageUrl: data.image_url,
          imageId: doc.id,
        });
      });
      if (imageResults.length > 0) {
        setLastResults(imageResults);
        setIsWaiting(false);
      }
    });
  }, [lastTaskId, setLastResults, setIsWaiting, uid, isPrivateMode, isPro]);

  // TODO refactor and remove duplication between ImageViewScreen
  const onClickSave = useCallback(
    (imageId: string) => {
      if (!imageId) {
        return;
      }

      if (!isSignedIn) {
        setSignInRequired(true);
        return;
      }

      if (savedImages.includes(imageId)) {
        deleteSavedImage(imageId, uid);
        setSavedImages(savedImages.filter((id) => id !== imageId));
      } else {
        saveImage(imageId || "", uid, isPrivateMode);
        setSavedImages([...savedImages, imageId]);
      }
    },
    [uid, savedImages, isSignedIn, setSignInRequired, isPrivateMode]
  );

  const onClickDelete = useCallback(
    (idx: number) => {
      if (lastResults.length === 0) {
        return;
      }

      const imageToDelete = lastResults[idx];
      setImageToDelete(imageToDelete);
      setShowDeleteModal(true);
    },
    [lastResults]
  );

  const copyTags = useCallback(() => {
    const tags = Object.keys(selectedTags)
      .filter((tag) => selectedTags[tag])
      .filter((tag) => tag.indexOf("_default") === -1);

    const url = generateTagURL(generator, tags);
    navigator.clipboard.writeText(url);
    setDidCopy(true);
    toast("Copied to clipboard");
  }, [generator, selectedTags]);

  const onClickClearTags = useCallback(() => {
    clearTags();
    setDidCopy(false);
    // Clear the query params
    window.history.replaceState({}, document.title, "/");
    toast("Cleared tags");
  }, [clearTags]);

  // When the tags or generator changes, setDidCopy false
  useEffect(() => {
    setDidCopy(false);
    cachedResults.current = [];
    cacheIndex.current = 0;
  }, [selectedTags, generator]);

  const togglePrivateMode = useCallback(() => {
    setIsPrivateMode(!isPrivateMode);
  }, [isPrivateMode, setIsPrivateMode]);

  // Switching private mode should clear results (if any).
  useEffect(() => {
    setLastResults([]);
  }, [isPrivateMode]);

  const onClickRandomizeTags = useCallback(() => {
    // Get all elements with classname PPChip

    let els = document.getElementsByClassName("PPChip");
    onClickClearTags();

    // Convert to array
    let elements: HTMLElement[] = Array.from(els) as HTMLElement[];

    // Shuffle them
    for (let i = elements.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i);
      const temp = elements[i];
      elements[i] = elements[j];
      elements[j] = temp;
    }

    let limit = elements.length;
    limit = Math.min(12, elements.length);

    // Randomly click them
    for (let i = 0; i < limit; i++) {
      const element = elements[i] as HTMLElement;
      element.click();
    }
  }, []);

  useEffect(() => {
    if (modelMap[generator].sdModel === "sdxl") {
      setDoodleMode(false);
      setDirectorMode(false);
      setVideoMode(false);
      setAspectRatio("1:1");
    }
  }, [generator, modelMap]);

  if (!didAccept) {
    return <div className="text-white">Please accept terms</div>;
  }

  return (
    <div className="w-full md:w-10/12">
      <div className="flex flex-col-reverse md:flex-row min-h-screen">
        <div className="grow px-4">
          <div className="flex flex-row">
            <GeneratorModal className="ml-2" />
            <div className="w-20 mb-4 ml-4">
              <div className="mb-2 block text-white">Ratio</div>
              <Select
                value={aspectRatio}
                onChange={(e) => {
                  if (!isPro) {
                    toast("Pro feature", {
                      position: "top-center",
                    });
                    return;
                  }

                  setAspectRatio(e.target.value);
                }}
                required={true}
              >
                <option value="3:4">3:4</option>
                <option value="9:16">9:16</option>
                <option value="1:1">1:1</option>
                <option value="4:3">4:3</option>
                <option value="16:9">16:9</option>
              </Select>
            </div>
          </div>
          {videoMode && (
            <div className="text-yellow-500 font-bold animate-pulse ml-2">
              GIF mode activated
            </div>
          )}
          <div className="mb-4">
            <button
              onClick={onClickClearTags}
              disabled={isWaiting}
              className="text-white rounded-lg disabled:opacity-70 align-middle ml-2 relative"
            >
              <img
                src={ClearTagsIcon}
                alt="copy"
                className="w-[34px] aspect-square"
              />
              <span className="absolute bottom-[-12px] left-15px text-xs bg-gray-500 rounded-md px-1 font-bold">
                {getNumSelectedText(getNumSelected(selectedTags))}
              </span>
            </button>
            <button
              onClick={copyTags}
              className="text-white rounded-lg disabled:opacity-70 mx-2 align-middle"
            >
              <img
                src={CopyIcon}
                alt="copy"
                className="w-[30px] aspect-square"
              />
            </button>
            <button
              onClick={onClickRandomizeTags}
              className="text-3xl align-middle mx-2 hover:animate-spin"
              aria-label="Randomize tags"
            >
              🎲
            </button>
            {modelMap[generator].sdModel !== "sdxl" && (
              <>
                <button
                  onClick={() => {
                    if (isPro) {
                      setDirectorMode(!directorMode);
                      setDoodleMode(false);
                    } else {
                      navigate("/getpro");
                      toast.error("Pro required for director mode");
                    }
                  }}
                  className="mx-2 text-white rounded-lg align-middle"
                >
                  {/* {directorMode ? "- 3D Pose" : "+ 3D Pose"} */}
                  <img
                    src={ThreeIcon}
                    alt="3D Pose"
                    className="w-[30px] aspect-square"
                  />
                </button>
                <button
                  onClick={() => {
                    if (isPro) {
                      setDoodleMode(!doodleMode);
                      setDirectorMode(false);
                    } else {
                      navigate("/getpro");
                      toast.error("Pro required for doodle mode");
                    }
                  }}
                  className="mx-2 text-white rounded-lg align-middle"
                >
                  {/* {doodleMode ? "- Doodle" : "+ Doodle"} */}
                  <img
                    src={DoodleIcon}
                    alt="Doodle"
                    className="w-[30px] aspect-square"
                  />
                </button>
                <button
                  className="mx-2 text-white rounded-lg align-middle relative disabled:opacity-30"
                  onClick={onClickVideo}
                >
                  <img
                    src={GifIcon}
                    alt="Gif"
                    className="w-[30px] aspect-square"
                  />
                </button>
              </>
            )}
          </div>
          {referenceImageUrl && (
            <div>
              <img
                src={referenceImageUrl}
                alt="Reference Image"
                className="w-64 h-64 ml-2 rounded-lg"
              />
              <div className="text-white flex flex-row ml-2 mb-4">
                <button
                  onClick={() => {
                    setReferenceImageUrl("");
                  }}
                >
                  <img src={ClearIcon} className="w-4 mr-2 hover:scale-105" />
                </button>
                <div className="text-white">Character</div>
              </div>
            </div>
          )}
          <SelectedTags />
          <AllTagSelector showDefault showSavedTags />
        </div>
        <div className="w-full md:w-fit md:flex-none md:min-w-[512px]">
          {error && (
            <div className="text-red-500 mt-4 px-4 max-w-lg font-bold mb-4">
              Sorry! This image accidentally triggered the safety filter. Please
              try again. If this keeps happening, try changing the
              tags/generator.
            </div>
          )}
          {(isOverload || isWaiting) && (
            <div
              className={classNames(
                "mb-8 flex justify-center items-center flex-col max-w-lg",
                {
                  "sticky top-0":
                    !directorMode && !doodleMode && lastResults.length === 1,
                }
              )}
            >
              {isOverload && !isPro && (
                <div className="text-red-500 mt-4 px-4 text-center">
                  Server is overloaded, please try again later.
                  <br />
                  <a
                    className="underline text-yellow-400 font-bold text-lg"
                    href="/getpro"
                  >
                    Tired of overloads? Get Pro Mode!
                  </a>
                  {isUsingCachedResult && (
                    <p className="text-center text-white">
                      We found a similar image previously generated ⬇️
                    </p>
                  )}
                </div>
              )}
              {isOverload && isPro && <ProError />}
              {isWaiting && (
                <div className="text-white text-center mt-4 w-full">
                  <div className="animate-pulse md:rounded-2xl w-full md:max-w-lg max-h-[512px] aspect-square bg-zinc-500 flex justify-center items-center flex-col">
                    <div className="font-bold">
                      {isPro ? (
                        <span className="text-yellow-400">
                          <span className="font-bold">Pro mode: </span>
                          <span className="font-normal">
                            Priority queue + higher quality
                          </span>
                        </span>
                      ) : (
                        "Generating results, this might take up to 5 minutes."
                      )}
                    </div>
                    {!isPro && (
                      <div className="mb-8">
                        Waiting too long?{" "}
                        <a
                          className="underline text-yellow-400 font-bold text-lg"
                          href="/getpro"
                        >
                          Get Pro Mode!
                        </a>
                      </div>
                    )}
                  </div>
                  {numResults > 1 && (
                    <div className="animate-pulse md:rounded-2xl w-full md:max-w-lg max-h-[512px] aspect-square bg-zinc-500 flex justify-center items-center flex-col mt-4">
                      <div className="font-bold">
                        <span className="text-yellow-400">
                          <span className="font-bold">Pro mode: </span>
                          <span className="font-normal">Extra Gen</span>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <div
            className={classNames(
              "flex flex-col overflow-auto mb-8 justify-center",
              {
                "sticky top-0":
                  !directorMode &&
                  !doodleMode &&
                  lastResults.length === 1 &&
                  numResults === 1,
              }
            )}
          >
            {/* Empty waiting */}
            {directorMode && <DirectorScreen />}
            {doodleMode && <Doodle />}
            {lastResults.length === 0 && !isWaiting && (
              <div className="hidden md:rounded-2xl md:visible md:flex w-full bg-black/30 md:max-w-lg max-h-[512px] aspect-square md:justify-center md:items-center">
                <div className="text-white">
                  Choose some tags and click generate to get started!
                </div>
              </div>
            )}
            {lastResults.map((url, idx) => (
              <div className="mb-4" key={url.imageId}>
                <ImageWithLink
                  imageUrl={url.imageUrl}
                  imageId={url.imageId}
                  isPrivate={isPrivateMode}
                />
                <div className="flex justify-center mt-2">
                  <button
                    onClick={() => onClickSave(url.imageId)}
                    className={classNames(
                      "text-white py-2 px-4 rounded-lg mr-2 w-20 border",
                      {
                        "border rounded-md": !savedImages.includes(url.imageId),
                        "bg-blue-800 border-transparent": savedImages.includes(
                          url.imageId
                        ),
                      }
                    )}
                  >
                    {savedImages.includes(url.imageId) ? "Saved" : "Save"}
                  </button>
                  <button
                    onClick={() => {
                      setImageIdForFolder(url.imageId);
                      setShowImageFolderModal(true);
                    }}
                    className="text-white py-2 px-4 rounded-lg mr-2"
                    style={{ backgroundColor: "#585858" }}
                  >
                    <img
                      style={{ transform: "scale(1.8)" }}
                      className="w-4"
                      src={FolderAddSvg}
                      alt="flag"
                    />
                  </button>
                  <Link
                    className="bg-purple-800 text-white py-2 px-4 rounded-lg mr-2 flex justify-center items-center"
                    to={
                      isPrivateMode
                        ? `/private/${url.imageId}`
                        : `/view/${url.imageId}`
                    }
                  >
                    Edit
                  </Link>
                  {isPrivateMode && (
                    <button
                      onClick={() => onClickDelete(idx)}
                      className="bg-red-800 text-white py-2 px-4 rounded-lg mr-2"
                    >
                      Delete
                    </button>
                  )}
                </div>
                <ImageRating
                  imageId={url.imageId}
                  isPrivate={isPrivateMode}
                  className="mt-4"
                />
              </div>
            ))}
          </div>
          {lastResults.length > 0 && lastResults.length < numResults && (
            <div className="text-white text-center mt-4 w-full">
              <div className="animate-pulse md:rounded-2xl w-full md:max-w-lg max-h-[512px] aspect-square bg-zinc-500 flex justify-center items-center flex-col">
                <div className="font-bold">
                  <span className="text-yellow-400">
                    <span className="font-bold">Pro mode: </span>
                    <span className="font-normal">Extra Gen</span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="sticky bottom-0 p-4 z-40 flex justify-center items-center flex-col pointer-events-none">
        <div className="bg-gray-700/40 p-4 rounded-lg pointer-events-auto backdrop-blur">
          <div className="flex flex-row">
            <button
              onClick={onClickGenerate}
              disabled={isWaiting}
              className="GradientButton text-white px-8 py-4 rounded-lg disabled:opacity-70 mb-4 text-xl transition-transform hover:scale-105 cursor-pointer"
            >
              Generate
            </button>
          </div>
          {isPro && (
            <div className="mt-2 text-center">
              <input
                className="mr-2 disabled:opacity-30"
                type="checkbox"
                checked={isPrivateMode || directorMode}
                onChange={togglePrivateMode}
                disabled={isWaiting || directorMode}
              />
              <span
                onClick={togglePrivateMode}
                className={classNames("text-white align-middle select-none", {
                  "opacity-30": isWaiting,
                })}
              >
                Private Mode
              </span>
            </div>
          )}
        </div>
      </div>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        imageId={imageToDelete?.imageId || ""}
        imageUrl={imageToDelete?.imageUrl || ""}
        onDelete={() => {
          // Find imageToDelete based on id, remove from lastResults
          const newLastResults = lastResults.filter(
            (result) => result.imageId !== imageToDelete?.imageId
          );
          setLastResults(newLastResults);
          setShowDeleteModal(false);
        }}
      />
      <ImageFolderModal
        showModal={showImageFolderModal}
        setShowModal={setShowImageFolderModal}
        imageId={imageIdForFolder || ""}
        isPrivate={isPrivateMode}
      />
    </div>
  );
}

export default App;
