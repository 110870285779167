import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="w-full bg-slate-800 text-gray-400 flex flex-col justify-center items-center mt-5">
      <div className="flex flex-wrap justify-center">
        <div className="mx-4 my-2">
          <Link className="underline" to="/2257">
            18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement
          </Link>
        </div>
        <div className="mx-4 my-2">
          <Link className="underline" to="/reportcontent">
            Report Content
          </Link>
        </div>
        <div className="mx-4 my-2">
          <Link className="underline" to="/privacy">
            Privacy Policy
          </Link>
        </div>
        <div className="mx-4 my-2">
          <a
            className="underline"
            href="https://epoch.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Billing Support
          </a>
        </div>
        <div className="mx-4 my-2">
          <Link className="underline" to="/contact">
            Contact Us
          </Link>
        </div>
      </div>
      <div className="my-4 text-center">
        © {new Date().getFullYear()} pornpen.ai
      </div>
    </div>
  );
}
