import { useUtilityStore } from "../zustand/utilityStores";

export function UidViewScreen() {
  const uid = useUtilityStore((state) => state.uid);

  return (
    <div className="text-white text-center text-lg">
      <div className="font-bold">Your uid is {uid}</div>
      <div>
        You can provide this information to admins for user lookup purposes
      </div>
    </div>
  );
}
