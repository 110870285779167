import { Link } from "react-router-dom";
import { IS_DEVELOPMENT, PLACEHOLDER_IMAGE } from "../test/SetupTest";
import { resolveImageUrl } from "../utils/strings";
import { isAnimatedUrl } from "../utils/gifs";

interface Props {
  imageId: string;
  imageUrl: string;
  isPrivate?: boolean;
  imageClassname?: string;
  appendClassname?: string;
  subtitle?: string;
}

export function ImageWithLink(props: Props) {
  let imageUrl = props.imageUrl;
  if (IS_DEVELOPMENT) {
    imageUrl = PLACEHOLDER_IMAGE;
  }

  const link = props.isPrivate
    ? `/private/${props.imageId}`
    : `/view/${props.imageId}`;

  return (
    <div
      key={props.imageId}
      className="text-white text-center underline m-auto relative"
    >
      <Link to={link}>
        {isAnimatedUrl(imageUrl) ? (
          <video
            src={imageUrl}
            autoPlay
            loop
            muted
            className={
              props.imageClassname ||
              "m-auto max-w-sm sm:max-w-lg " + props.appendClassname
            }
          ></video>
        ) : (
          <img
            className={
              props.imageClassname ||
              "m-auto max-w-sm sm:max-w-lg " + props.appendClassname
            }
            src={resolveImageUrl(imageUrl)}
            alt="Results"
          />
        )}
      </Link>
      {props.subtitle && (
        <div className="absolute bottom-0 p-4 m-2 rounded-lg bg-black bg-opacity-50 text-center font-bold hover:scale-105 transition-transform cursor-pointer">
          <p className="text-xs sm:text-sm">{props.subtitle}</p>
        </div>
      )}
    </div>
  );
}
