export function TwoTwoFiveSevenScreen() {
  return (
    <div className="text-white m-auto max-w-lg ml-4 mr-4">
      <h2>Section 2257 Compliance Notice</h2>
      <p>
        All content displayed on pornpen.ai is exempt from the record-keeping
        and reporting requirements set forth in 18 U.S.C. § 2257 and C.F.R. 75
        because said visual content does not consist of depictions of conduct as
        specifically listed in 18 U.S.C § 2256 (2) (A) – (D), but are merely, at
        most, AI generated pictures and videos of non-existent people.
      </p>
    </div>
  );
}
