import { Link } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { query, collection, limit, orderBy, getDocs } from "firebase/firestore";
import { db } from "../firebase/setupFirebase";
import "./Home.css";
import TagStrings from "../zustand/strings.json";
import { useModelStore } from "../zustand/modelStore";
import { BASE, ETHNICITY } from "../zustand/data";
import ImageTags from "../images/image_tags.jpeg";
import GreenCheck from "../images/green_check.png";
import Inpainting from "../images/inpainting.jpeg";
import Feed from "../images/feed.png";
import classNames from "classnames";
import EnhanceImg from "../images/getpro/enhance.png";
import Doodle from "../images/getpro/doodle.jpeg";
import Uncrop from "../images/getpro/uncrop.png";
import UpscaleExample from "../images/getpro/upscale.png";
import SearchGrid from "../images/getpro/search_grid.png";
import DirectorImg from "../images/getpro/director.webp";
import Consistent from "../images/getpro/consistent.png";
import Games from "../images/getpro/games.png";
import { isAnimatedUrl } from "../utils/gifs";
import { useSpring, animated } from "@react-spring/web";
import ArrowForward from "../images/arrow_forward.svg";

interface HomeFeedItem {
  imageUrl: string;
  tags: string[];
  imageId: string;
  hideLink?: boolean;
}

export function Home() {
  const [feedItems, setFeedItems] = useState<HomeFeedItem[]>([
    {
      imageUrl:
        "https://cdn.pornpen.ai/eee7bf0a-30a4-4d60-9d84-4af6dff398cb.jpg",
      imageId: "1",
      tags: ["Selfie", "Asian", "Big Boobs"],
      hideLink: true,
    },
    {
      imageUrl:
        "https://cdn.pornpen.ai/c8a19ba9-86d9-4e2b-b1e0-caa6b953def6.jpg",
      imageId: "2",
      tags: ["Indian", "Lingerie", "Bedroom"],
      hideLink: true,
    },
    {
      imageUrl:
        "https://cdn.pornpen.ai/820efad4-8201-481b-90ee-9c8e72ef5c78.jpg",
      imageId: "3",
      tags: ["Blue Hair", "Casino", "Anime"],
      hideLink: true,
    },
  ]);
  const models = useModelStore((state) => state.models);

  const addNewItem = useCallback(async () => {
    let q = query(
      collection(db, "results"),
      limit(1),
      orderBy("create_date", "desc")
    );

    let querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length > 0) {
      let lastResult = querySnapshot.docs[0].data();
      let lastId = querySnapshot.docs[0].id;

      let tags: string[] = [];

      let generator = lastResult.generator;
      if (generator === "men" || generator === "men_detailed") {
        return;
      }

      if (isAnimatedUrl(lastResult.image_url)) {
        return;
      }

      // If lastId is already in feedItems, return
      if (feedItems.find((item) => item.imageId === lastId)) {
        return;
      }

      let model = models.find((model) => model.id === generator);

      if (model) {
        // Uppercase first letter of model.prompt_system and concatenate with model.name
        tags.push(
          model.prompt_system.charAt(0).toUpperCase() +
            model.prompt_system.slice(1) +
            ": " +
            model.name
        );
      }

      Object.keys(lastResult).forEach((key) => {
        if (BASE.includes(key)) {
          tags.push(TagStrings[key as keyof typeof TagStrings]);
        }

        if (ETHNICITY.includes(key)) {
          tags.push(TagStrings[key as keyof typeof TagStrings]);
        }

        let otherTag = TagStrings[key as keyof typeof TagStrings];
        if (otherTag && !tags.includes(otherTag)) {
          tags.push(otherTag);
        }
      });

      // Limit to 3 tags
      tags = tags.slice(0, 3);

      if (tags.length === 0) {
        tags.push("Upscaled image");
      }

      const newFeedItem: HomeFeedItem = {
        imageUrl: lastResult.image_url,
        tags,
        imageId: lastId,
      };

      // Prepend
      setFeedItems([newFeedItem, ...feedItems]);
    }
  }, [feedItems]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      addNewItem();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [addNewItem]);

  return (
    <div className="w-full">
      <div className="flex justify-center">
        <Link
          className="text-white text-center bg-blue-600 px-16 py-2 rounded-lg text-lg flex justify-between items-center"
          to="/games"
        >
          <div className="mr-2 font-bold">NEW: Dating App Game!</div>
          <img src={ArrowForward} alt="Arrow" className="h-6" />
        </Link>
      </div>

      <div className="flex sm:flex-row flex-col items-center sm:mb-16 my-4 text-white text-5xl font-sans font-extralight max-w-[2400px] m-auto">
        <div className="mx-24 min-w-max">
          <div className="mb-8">
            <span>Create & edit free</span>
            <br />
            <b>AI generated porn</b>
            <br />
            in just a few clicks!
            <br />
          </div>
          <Link
            to="/tutorial"
            className="text-white GradientButton rounded-full px-8 py-2 text-5xl font-bold mb-4 border-2 hover:opacity-30 mt-32"
          >
            Make Porn
          </Link>
        </div>
        <div className="sm:mt-0 mt-8 sm:w-auto w-full sm:min-h-[400px] min-h-[210px] overflow-hidden">
          <div className="flex flex-grow flex-nowrap">
            {feedItems.map((item) => {
              return <HomeFeedItemContainer key={item.imageId} item={item} />;
            })}
          </div>
        </div>
      </div>
      <SectionHeader title="Features" />
      <HomeSection
        title="Image Generation"
        description="Make customized images by selecting tags. Stop fiddling with complicated Stable Diffusion settings and start generating high quality images in seconds."
      >
        <img src={ImageTags} alt="generation example" className="rounded-lg" />
      </HomeSection>
      <HomeSection
        title="Inpainting"
        description="Edit specific parts of the image with inpainting. Add, remove, or enhance regions that you select. (Note: image upload not allowed)"
        reverse
      >
        <img src={Inpainting} alt="Inpainting example" className="rounded-lg" />
      </HomeSection>
      <HomeSection
        title="Search"
        description={
          <div>
            <Link className="underline" to="/search">
              Search
            </Link>{" "}
            our database of 50+ million images
          </div>
        }
      >
        <img src={SearchGrid} alt="Search example" className="rounded-lg" />
      </HomeSection>
      <HomeSection
        title="Feed"
        description={
          <div>
            Browse through a customizable,{" "}
            <Link className="underline" to="/feed">
              live feed
            </Link>
          </div>
        }
        reverse
      >
        <img
          src={Feed}
          alt="Feed example"
          className="rounded-lg border border-gray-200/30"
        />
      </HomeSection>
      <HomeSection
        title="Safety"
        description="Unlike other sites, deepfakes are not allowed here. We utilize AI filters to detect and remove illegal content."
      >
        <img src={GreenCheck} alt="Green check" className="rounded-lg w-32" />
      </HomeSection>

      <SectionHeader title="Pro Mode" />
      <div className="flex justify-center text-white">
        <div className="max-w-md text-center text-3xl mb-8 px-8">
          Join{" "}
          <span className="text-yellow-400 font-extrabold animate-pulse">
            thousands of subscribers
          </span>{" "}
          using Pro Mode!
        </div>
      </div>
      <div className="flex justify-center">
        <ShinyGetProButton />
      </div>
      {/* <HomeSection
        title="Faster and unlimited generations!"
        description="Quickly generate unlimited images with a priority queue for Pro members! No credits or tokens required!"
      >
        <div className="border p-4 rounded-lg font-bold bg-slate-600">
          ⏱️ ~10s for 2 images
        </div>
      </HomeSection>

      <HomeSection
        title="Private mode + no watermark"
        description="Generate images privately without watermarks! Private images are only visible to you."
        reverse
      >
        <img src={NoWatermark} alt="No watermark" className="rounded-lg" />
      </HomeSection> */}
      <div className="flex justify-center text-white mt-16 px-8">
        <div className="max-w-lg text-center text-3xl mb-8">
          Check out these{" "}
          <span className="text-yellow-400 font-extrabold animate-pulse">
            exclusive features
          </span>{" "}
          available only to Pro members!
        </div>
      </div>

      <HomeSection
        title="GIFs"
        description="Create short GIFs with your favorite tags!"
        reverse
        isPro
      >
        <video playsInline autoPlay loop muted>
          <source
            src="https://cdn.pornpen.ai/2f5aab8b-025b-439d-b3f2-de34b3a30b3a.mp4"
            type="video/mp4"
          />
        </video>
      </HomeSection>

      <HomeSection
        title="HD Generators"
        description="Generate high quality images powered by SDXL!"
        isPro
      >
        <img
          src="https://cdn.pornpen.ai/6fa1512b-2878-4461-a08c-6b82804127bf.jpg"
          alt="SDXL example"
        />
      </HomeSection>

      <HomeSection
        title="Consistent Characters"
        description="Use the same character across multiple generations!"
        reverse
        isPro
      >
        <img src={Consistent} alt="Consistent character example" />
      </HomeSection>

      <HomeSection
        title="Uncropping"
        description="Zoom out to reveal more!"
        isPro
      >
        <img src={Uncrop} alt="Uncrop" />
      </HomeSection>

      <HomeSection
        title="Upscaling"
        description="Upscale an image by up to 4x to 2048x2048 resolution!"
        reverse
        isPro
      >
        <img src={UpscaleExample} alt="Upscale example" />
      </HomeSection>

      <HomeSection
        title="Fix details"
        description="Enhance image details and faces with a single tap!"
        isPro
      >
        <img src={EnhanceImg} alt="Enhance images" className="" />
      </HomeSection>
      <HomeSection
        title="Unlimited Search"
        description="Search through our database of 50+ million images! Why generate when you can just find?"
        reverse
        isPro
      >
        <img src={SearchGrid} alt="Search grid" className="rounded-lg" />
      </HomeSection>
      <HomeSection
        title="3D Posing"
        description="Customize a 3D model and pose it however you want!"
        isPro
      >
        <img src={DirectorImg} alt="Director" className="scale-" />
      </HomeSection>
      <HomeSection
        title="Doodles"
        description="Sketch a doodle to guide generation!"
        reverse
        isPro
      >
        <img src={Doodle} alt="Director" />
      </HomeSection>
      <HomeSection
        title="Games"
        description="Unlimited chatting with AIs and interactive games!"
        isPro
      >
        <img src={Games} alt="Games" />
      </HomeSection>
      <div className="flex justify-center pb-64">
        <ShinyGetProButton />
      </div>
    </div>
  );
}

function HomeSection(props: {
  title: string;
  description: React.ReactNode;
  reverse?: boolean;
  children: React.ReactNode;
  isPro?: boolean;
}) {
  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center mb-32 sm:flex-row sm:px-32 px-2",
        {
          "sm:flex-row-reverse": props.reverse,
        }
      )}
    >
      <div>
        <div className="text-white sm:text-4xl text-3xl font-serif">
          {props.title}
        </div>
        <div className="text-white text-lg w-[300px] sm:mb-0 mb-4">
          {props.description}
        </div>
        {props.isPro && (
          <Link
            to="/getpro"
            className="text-yellow-400 underline font-extrabold text-3xl"
          >
            Get Pro
          </Link>
        )}
      </div>
      <div className="flex-[0.1]"></div>
      <div className="flex flex-row flex-wrap justify-center items-center mt-2">
        {props.children}
      </div>
    </div>
  );
}

export function SectionHeader(props: { title: string }) {
  return (
    <div className="flex flex-row w-full mb-16">
      <div className="h1-box-line"></div>
      <div className="text-white text-4xl">{props.title}</div>
      <div className="h1-box-line"></div>
    </div>
  );
}

function ShinyGetProButton() {
  return (
    <Link
      to="/getpro"
      className="text-white GradientButtonPro rounded-lg px-16 py-4 text-3xl font-bold mb-4 border-2 hover:scale-105 transition-transform"
    >
      Get Pro
    </Link>
  );
}

function HomeFeedItemContainer(props: { item: HomeFeedItem }) {
  // Spring blur
  const fadeInSpring = useSpring({
    from: { opacity: 0, transform: "scale(0.8)" },
    to: { opacity: 1, transform: "scale(1)" },
    tension: 120,
    friction: 14,
  });

  // If imageId is 1,2, or 3 dont use fadeInSpring
  let imageId = parseInt(props.item.imageId);
  const isStarterImage = imageId === 1 || imageId === 2 || imageId === 3;

  let spring = isStarterImage ? {} : fadeInSpring;

  return (
    <div key={props.item.imageId}>
      <animated.div
        className="flex flex-col items-center justify-center mx-2 sm:w-96 w-48 shrink-0 aspect-square hover:scale-105 transition-all"
        style={{
          ...spring,
        }}
      >
        <Link to={props.item.hideLink ? "#" : `/view/${props.item.imageId}`}>
          <img
            src={props.item.imageUrl}
            className="rounded-lg"
            alt="Feed example"
          />
        </Link>
      </animated.div>
      <div className="text-right mt-2 select-none">
        {props.item.tags.map((tag, i) => {
          return (
            <HomeFeedTagElement
              key={props.item.imageId + tag}
              tag={tag}
              i={i}
            />
          );
        })}
      </div>
    </div>
  );
}

function HomeFeedTagElement(props: { tag: string; i: number }) {
  const slideInLeftSpring = useSpring({
    from: { opacity: 0, transform: "translateX(-100px)" },
    to: { opacity: 1, transform: "translateX(0px)" },
    delay: props.i * 100,
    tension: 120,
    friction: 14,
  });

  return (
    <animated.div
      className="text-2xl sm:text-4xl"
      style={{
        ...slideInLeftSpring,
      }}
    >
      {props.tag}
    </animated.div>
  );
}
