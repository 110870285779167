// TODO: Use the modelstorefor this
export const GENERATOR_TO_STRING = {
  women_crisp: "Women (detailed)",
  women_real: "Women (realistic)",
  women_accurate: "Women (accurate)",
  women_photography: "Women (photography)",
  women: "Women",
  doggystyle: "Doggystyle",
  titfuck: "Titfuck",
  blowjob: "Blowjob",
  missionary: "Missionary",
  anime: "Anime",
  anime_detailed: "Anime (detailed)",
  furry: "Furry",
  men: "Men",
  men_detailed: "Men (detailed)",
  men_photography: "Men (photography)",
  women_hd: "Women HD",
  women_intricate: "Women Intricate",
  anime_hd: "Anime HD",
  all: "All",
} as const;

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function resolveImageUrl(url: string) {
  let firstStage = url.replace("pornpen.art", "pornpen.ai");
  return firstStage;
}
