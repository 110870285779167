import classnames from "classnames";
import { Modal } from "flowbite-react";
import React from "react";
import { FilterSettings } from "../screens/Feed";
import { GENERATOR_TO_STRING } from "../utils/strings";
import { TOP_LEVEL } from "../zustand/data";
import TagStrings from "../zustand/strings.json";
import { ModalContainer } from "./ModalContainer";

// Measured in max seconds between each new image
export const FEED_SPEEDS = {
  slow: 4,
  medium: 2,
  fast: 1,
  max: 0,
};

export function FilterModal(props: {
  showModal?: boolean;
  setShowModal: (disableModal: boolean) => void;
  filterSettings: FilterSettings;
  setFilterSettings: (filterSettings: FilterSettings) => void;
}) {
  const handleClose = () => {
    props.setShowModal(false);
  };

  const onClickToggleShowEdits = () => {
    // Create a deep copy of the filter settings
    // Toggle the showEdits property
    // Set the filter settings to the new object
    const newFilterSettings = JSON.parse(JSON.stringify(props.filterSettings));
    newFilterSettings.showEdits = !newFilterSettings.showEdits;
    props.setFilterSettings(newFilterSettings);
  };

  const onClickToggleShowGenerator = (generator: string) => {
    // Create a deep copy of the filter settings
    // Toggle the showEdits property
    // Set the filter settings to the new object
    const newFilterSettings = JSON.parse(JSON.stringify(props.filterSettings));
    newFilterSettings.showGenerators[
      generator as keyof typeof GENERATOR_TO_STRING
    ] =
      !!!newFilterSettings.showGenerators[
        generator as keyof typeof GENERATOR_TO_STRING
      ];
    props.setFilterSettings(newFilterSettings);
  };

  const onClickBlockTag = (tag: string) => {
    // Create a deep copy of the filter settings
    // toggle filterSettings.blockedTags[tag]
    // Set the filter settings to the new object

    const newFilterSettings = JSON.parse(JSON.stringify(props.filterSettings));
    newFilterSettings.blockedTags[tag] = !!!newFilterSettings.blockedTags[tag];
    props.setFilterSettings(newFilterSettings);
  };

  const generatorSelect = Object.keys(GENERATOR_TO_STRING)
    .filter((key) => key !== "all")
    .map((key) => {
      return (
        <button
          key={key}
          className={classnames(
            "px-4 py-2 rounded-lg border-transparent border mr-4 mb-4",
            {
              "text-white bg-green-500":
                props.filterSettings.showGenerators[
                  key as keyof typeof GENERATOR_TO_STRING
                ] !== false,
              "border text-white bg-red-500":
                props.filterSettings.showGenerators[
                  key as keyof typeof GENERATOR_TO_STRING
                ] === false,
            }
          )}
          onClick={() => onClickToggleShowGenerator(key)}
        >
          {GENERATOR_TO_STRING[key as keyof typeof GENERATOR_TO_STRING]}
        </button>
      );
    });

  const TagList = Object.keys(TOP_LEVEL).map((key) => {
    return (
      <div key={key} className="flex flex-col">
        <br />
        <div className="flex flex-wrap">
          {TOP_LEVEL[key as keyof typeof TOP_LEVEL].map((tag) => {
            return (
              <button
                key={tag}
                className={classnames(
                  "px-4 py-2 rounded-lg border-transparent border mr-4 mb-4",
                  {
                    "text-white bg-red-500":
                      props.filterSettings.blockedTags[tag],
                    "border bg-green-500 text-white":
                      !props.filterSettings.blockedTags[tag],
                  }
                )}
                onClick={() => onClickBlockTag(tag)}
              >
                {TagStrings[tag as keyof typeof TagStrings]}
              </button>
            );
          })}
        </div>
      </div>
    );
  });

  const SpeedButtons = Object.entries(FEED_SPEEDS).map(([key, value]) => {
    return (
      <button
        key={key}
        className={classnames("px-4 py-2 rounded-lg border mr-4 mb-4", {
          "text-white bg-green-500 border border-transparent":
            props.filterSettings.feedSpeed === key,
          "border border-gray-400": props.filterSettings.feedSpeed !== key,
        })}
        onClick={() => {
          const newFilterSettings = JSON.parse(
            JSON.stringify(props.filterSettings)
          );
          newFilterSettings.feedSpeed = key;
          props.setFilterSettings(newFilterSettings);
        }}
      >
        {key}
      </button>
    );
  });

  return (
    <ModalContainer
      title="Feed Settings"
      showModal={props.showModal}
      setShowModal={props.setShowModal}
    >
      <div className="text-white">
        <div className="text-lg font-bold">Speed</div>
        <div className="mb-4">Adjust the speed of the feed</div>
        <div className="flex flex-row flex-wrap">{SpeedButtons}</div>
        <div className="text-lg font-bold">Generators</div>
        <div className="mb-4">Choose which generators appear in the feed</div>
        <div>{generatorSelect}</div>
        <div className="text-lg font-bold">Image Edits</div>
        <div className="mb-4">Show inpainting, crops, etc.</div>
        <div>
          <button
            onClick={onClickToggleShowEdits}
            className={classnames("px-4 py-2 rounded-lg mb-4", {
              "text-white bg-green-500 border border-transparent":
                props.filterSettings.showEdits,
              "border border-transparent bg-red-500":
                !props.filterSettings.showEdits,
            })}
          >
            Show Edits
          </button>
        </div>
        <div className="text-lg font-bold">Tags</div>
        <div>Select individual tags to block</div>
        {TagList}
      </div>
    </ModalContainer>
  );
}
