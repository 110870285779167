import { deleteDoc, doc, getDoc } from "firebase/firestore";
import { Select } from "flowbite-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { CATEGORY_ID_TO_STRING } from "../common/categoryDefinitions";
import { Tag } from "../common/tagDefinitions";
import CategorySelector from "../components/CategorySelector";
import { submitTag, unpublishTag, updateTag } from "../firebase/functions";
import { db } from "../firebase/setupFirebase";
import { useModelStore } from "../zustand/modelStore";
import classNames from "classnames";

export function EditTag(props: { tagId?: string }) {
  let match = useMatch("/tags/edit/:tagId");
  let tagId = match?.params.tagId || props.tagId;

  const [name, setName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [status, setStatus] = useState("draft");
  const [prompt, setPrompt] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");
  const [notFound, setNotFound] = useState(false);
  const originalData = useRef<Tag | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [finishedDelete, setFinishedDelete] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [showLimitToGenerators, setShowLimitToGenerators] = useState(false);
  const models = useModelStore((state) => state.models);
  const [onlyGenerators, setOnlyGenerators] = useState<string[]>([]);
  const [disableForAnimated, setDisableForAnimated] = useState<boolean>(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (tagId) {
      const docRef = doc(db, "tags", tagId);

      getDoc(docRef)
        .then((doc) => {
          if (doc.exists()) {
            const data = doc.data() as Tag;
            console.log(data);
            originalData.current = data;
            setName(data.name);
            setCategoryId(data.category);
            setStatus(data.status || "draft");
            setPrompt(data.prompt);
            if (data.description) {
              setDescription(data.description);
            }
            if (data.imageUrl) {
              setImageUrl(data.imageUrl);
            }
            if (data.rejectReason) {
              setRejectReason(data.rejectReason);
            }

            if (data.onlyGenerators && data.onlyGenerators.length > 0) {
              setOnlyGenerators(data.onlyGenerators);
              setShowLimitToGenerators(true);
            } else {
              setShowLimitToGenerators(false);
            }

            if (data.disableForAnimated) {
              setDisableForAnimated(data.disableForAnimated);
            }
          } else {
            setNotFound(true);
          }
        })
        .catch((e) => {
          console.error(e);
          setNotFound(true);
        });
    }
  }, [tagId]);

  const onClickDelete = useCallback(() => {
    if (!tagId) {
      return;
    }

    const docRef = doc(db, "tags", tagId);
    deleteDoc(docRef).then(() => {
      setFinishedDelete(true);
      setTimeout(() => {
        navigate("/tags/manage");
      }, 1000);
    });
  }, [navigate, tagId]);

  const onClickSave = useCallback(async () => {
    setError(null);

    if (originalData.current && tagId) {
      let updates: Parameters<typeof updateTag>[0] = {};

      if (originalData.current.name !== name) {
        updates["name"] = name;
      }

      if (originalData.current.category !== categoryId) {
        updates["category"] = categoryId;
      }

      if (originalData.current.prompt !== prompt) {
        updates["prompt"] = prompt;
      }

      if (originalData.current.description !== description) {
        updates["description"] = description;
      }

      if (originalData.current.imageUrl !== imageUrl) {
        updates["imageUrl"] = imageUrl;
      }

      if (originalData.current.disableForAnimated !== disableForAnimated) {
        updates["disableForAnimated"] = disableForAnimated;
      }

      // Emptying out list
      if (!showLimitToGenerators) {
        updates["onlyGenerators"] = [];
      }

      if (showLimitToGenerators) {
        const original = originalData.current.onlyGenerators || [];

        // Sort and join to string to compare
        const originalString = original.sort().join(",");
        const newString = onlyGenerators.sort().join(",");

        if (originalString !== newString) {
          updates["onlyGenerators"] = onlyGenerators;
        }

        if (onlyGenerators.length === 0) {
          setShowLimitToGenerators(false);
        }
      }

      if (Object.keys(updates).length > 0) {
        setIsSaving(true);
        try {
          await updateTag(updates, tagId);
          toast("Saved!", { icon: "👍" });
        } catch (e: any) {
          setError("Error:" + e.message);
        }

        setIsSaving(false);
      } else {
        toast("No changes to save");
      }
    }
  }, [
    tagId,
    name,
    categoryId,
    prompt,
    description,
    imageUrl,
    onlyGenerators,
    showLimitToGenerators,
    disableForAnimated,
  ]);

  const onClickSubmit = useCallback(async () => {
    if (!tagId) {
      return;
    }

    setError(null);

    await onClickSave();

    try {
      setIsSaving(true);
      await submitTag(tagId);
      setStatus("submitted");
      setIsSaving(false);
    } catch (e: any) {
      setError("Error:" + e.message);
    }
  }, [tagId, onClickSave]);

  const onClickUnpublish = useCallback(async () => {
    if (!tagId) {
      return;
    }

    setError(null);

    try {
      setIsSaving(true);
      await unpublishTag(tagId);
      setStatus("draft");
      setIsSaving(false);
      toast("Unpublished tag");
    } catch (e: any) {
      setError("Error:" + e.message);
    }
  }, [tagId]);

  if (!tagId || notFound) {
    return (
      <div className="text-2xl mb-2 text-center text-white">Tag not found</div>
    );
  }

  return (
    <div className="text-white w-full max-w-lg overflow-x-scroll p-4">
      <Link className="text-left hover:opacity-30" to="/tags/manage">
        ⬅️ Back
      </Link>
      <div className="text-2xl mb-2 text-center">{name}</div>
      {imageUrl && (
        <div className="mb-4 w-32 m-auto">
          <img src={imageUrl} alt="thumbnail" className="rounded-lg" />
        </div>
      )}
      <div>
        <div className="text-lg font-bold">Status</div>
        <div className="mb-4">
          {status}
          {rejectReason && status === "rejected" && `: ${rejectReason}`}
        </div>
        <div className="text-lg font-bold mt-4">Name</div>
        <div className="mb-4">
          <input
            className="w-full rounded-lg text-black py-2 px-2"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className="text-lg font-bold">Category</div>
        <Select
          value={categoryId}
          onChange={(e) => {
            setCategoryId(e.target.value as string);
          }}
        >
          <CategorySelector />
        </Select>
        <div className="text-lg font-bold mt-4">Thumbnail URL</div>
        <div className="mb-4">
          <input
            className="w-full rounded-lg text-black py-2 px-2"
            value={imageUrl}
            onChange={(e) => {
              const newUrl = e.target.value;
              if (newUrl.indexOf("https://cdn.pornpen.ai") !== 0) {
                setError("Invalid URL, must start with https://cdn.pornpen.ai");
              }

              setImageUrl(newUrl);
            }}
            placeholder="https://cdn.pornpen.ai/abcxyz"
          />
        </div>
        <div className="text-lg font-bold">Prompt</div>
        <div>
          <textarea
            maxLength={500}
            className="mb-4 text-black p-2 rounded-lg w-full disabled:opacity-70"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            disabled={status !== "draft" && status !== "rejected"}
          />
        </div>
        <div className="text-lg font-bold">Description</div>
        <div>
          <textarea
            maxLength={500}
            className="w-full rounded-lg text-black"
            value={description}
            placeholder="Optional, describe your tag"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div>
          <div className="mb-2">
            <input
              className="mr-2 disabled:opacity-30"
              type="checkbox"
              checked={showLimitToGenerators}
              onChange={(e) => {
                setShowLimitToGenerators(e.target.checked);
              }}
            />
            <span
              className="mr-2 select-none"
              onClick={() => {
                setShowLimitToGenerators(!showLimitToGenerators);
              }}
            >
              Limit to generators
            </span>
          </div>
          {showLimitToGenerators && (
            <div className="flex flex-row flex-wrap">
              {models.map((model) => (
                <div
                  className={classNames(
                    "rounded-lg border px-2 py-1 mr-2 mb-2 cursor-pointer select-none",
                    {
                      "bg-blue-600 text-white": onlyGenerators.includes(
                        model.id
                      ),
                    }
                  )}
                  key={model.id}
                  onClick={() => {
                    if (onlyGenerators.includes(model.id)) {
                      setOnlyGenerators(
                        onlyGenerators.filter((id) => id !== model.id)
                      );
                    } else {
                      setOnlyGenerators([...onlyGenerators, model.id]);
                    }
                  }}
                >
                  {model.prompt_system}: {model.name}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="mb-2">
          <input
            className="mr-2 disabled:opacity-30"
            type="checkbox"
            checked={disableForAnimated}
            onChange={(e) => {
              setDisableForAnimated(e.target.checked);
            }}
          />
          <span
            className="mr-2 select-none"
            onClick={() => {
              setDisableForAnimated(!disableForAnimated);
            }}
          >
            Disable for animated
          </span>
        </div>
        <div className="flex flex-row justify-end">
          <button
            onClick={onClickSave}
            className="mr-4 disabled:opacity-30 px-4 py-2 rounded-lg bg-green-500 font-bold"
            disabled={isSaving}
          >
            {isSaving ? "Saving" : "Save"}
          </button>
        </div>
        {error && <div className="text-red-600">{error}</div>}
        <div className="flex justify-center items-center mt-8">
          <div>
            <button
              onClick={() => {
                if (status === "draft" || status === "rejected") {
                  onClickSubmit();
                } else {
                  onClickUnpublish();
                }
              }}
              className="bg-blue-600 text-white px-8 py-4 rounded-lg disabled:opacity-70 mb-4"
              disabled={isSaving}
            >
              {status === "draft" || status === "rejected"
                ? "Submit for review"
                : "Unpublish"}
            </button>
          </div>
        </div>
        <div className="mt-4 text-sm text-center">
          Note: Unpublishing a tag removes it from the public tag list. Users
          will not be able to use it in their gens.
        </div>
        <div className="mt-8 flex justify-center">
          <button
            onClick={() => {
              onClickDelete();
            }}
            className="text-white bg-red-800 px-4 py-2 rounded-lg disabled:opacity-70"
            disabled={isSaving || status === "live"}
          >
            {finishedDelete ? "Deleted" : "Delete"}
          </button>
        </div>
      </div>
    </div>
  );
}
