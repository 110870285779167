import React, { useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { CATEGORY_ID_TO_STRING } from "../common/categoryDefinitions";
import "./TagSelectorContainer.css";
import { useTagStore } from "../zustand/tagStore";
import { Chip } from "./TagSelector";
import { toast } from "react-hot-toast";

export function TagSelectorContainer(props: any) {
  const recentTags = useTagStore((state) => state.recentTags);
  const generator = useTagStore((state) => state.generator);

  // Create title list
  let tabList = props.children
    .filter((c: any) => !!c)
    .map((child: any) => {
      return (
        <Tab key={child.props.categoryId}>
          {
            CATEGORY_ID_TO_STRING[
              child.props.categoryId as keyof typeof CATEGORY_ID_TO_STRING
            ]
          }
        </Tab>
      );
    });

  tabList.unshift(
    <Tab key={0}>
      <span className="font-bold">All</span>
    </Tab>
  );

  // Create tab panels for each props.children
  let tabPanels = props.children
    .filter((c: any) => !!c)
    .map((child: any) => {
      return <TabPanel key={child.props.categoryId}>{child}</TabPanel>;
    });

  tabPanels.unshift(<TabPanel key={"init"}>{props.children}</TabPanel>);
  return (
    <Tabs className="flex flex-col items-start text-white" forceRenderTabPanel>
      <TabList>{tabList}</TabList>
      {recentTags.length > 0 && (
        <>
          <div className="ml-4 font-bold text-white">Recently Added</div>
          <div className="flex col flex-wrap">
            {recentTags
              .filter((tag) => {
                if (tag.onlyGenerators && tag.onlyGenerators.length > 0) {
                  if (!tag.onlyGenerators.includes(generator)) {
                    return false;
                  }
                }
                return true;
              })
              .map((tag) => (
                <Chip
                  key={tag.id}
                  tagId={"studio_" + tag.id}
                  displayName={tag.name}
                  isSingleChoice={props.isSingleChoice}
                  clickOverride={() => {
                    // Find element with data-tagid attribute and click it
                    const element = document.querySelector(
                      `[data-tagid="studio_${tag.id}"]`
                    ) as HTMLElement;
                    if (element) {
                      element.click();
                    } else {
                      toast.error("Tag not found in this generator");
                    }
                  }}
                />
              ))}
          </div>
        </>
      )}

      {tabPanels}
    </Tabs>
  );
}
