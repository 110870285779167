import { useCallback, useEffect, useRef, useState } from "react";
import { clearCanvas, loadDrawing, redo, undo } from "../utils/painting";
import classNames from "classnames";
import PaintIcon from "../images/edit.svg";
import EraseIcon from "../images/eraser.svg";

// TODO refactor
export let getDoodleImage: () => string;

export function Doodle() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const detailCanvasRef = useRef<HTMLCanvasElement>(null);
  const canvasWidth = Math.min(512, window.innerWidth);
  const [brushMode, setBrushMode] = useState<"paint" | "eraser">("paint");
  const [brushSize, setBrushSize] = useState(10);
  const clearDetailCanvasTimeout = useRef<NodeJS.Timeout | null>(null);

  // Load the inpainting features into canvas
  useEffect(() => {
    if (canvasRef && canvasRef.current) {
      loadDrawing(canvasRef.current, null, {
        brushColor: "black",
        lineWidth: 10,
      });
    }
  }, [canvasRef]);

  const getDoodleDataUri = () => {
    if (canvasRef && canvasRef.current) {
      return canvasRef.current.toDataURL();
    }
    return "";
  };

  const onClickUndo = () => {
    if (canvasRef && canvasRef.current) {
      undo(canvasRef.current);
    }
  };

  const onClickRedo = () => {
    if (canvasRef && canvasRef.current) {
      redo(canvasRef.current);
    }
  };

  const onClickClear = () => {
    if (canvasRef && canvasRef.current) {
      clearCanvas(canvasRef.current);
    }
  };

  const onClickChangeBrush = useCallback(() => {
    const newBrush = brushMode === "paint" ? "eraser" : "paint";
    setBrushMode(newBrush);

    // If we're switching to eraser, then get the canvas ctx set composite to "destination-out"
    // and change the ctx fill color
    // else set it to "source-over" and set the ctx fill color to black
    if (newBrush === "paint") {
      let ctx = canvasRef.current?.getContext("2d");
      ctx && (ctx.globalCompositeOperation = "source-over");
    } else {
      let ctx = canvasRef.current?.getContext("2d");
      ctx && (ctx.globalCompositeOperation = "destination-out");
    }
  }, [brushMode]);

  // Change the brush size on the canvas
  useEffect(() => {
    let origCtx = canvasRef.current?.getContext("2d");

    if (origCtx) {
      origCtx.lineWidth = brushSize * 2;
    }
  }, [brushSize]);

  getDoodleImage = getDoodleDataUri;

  return (
    <div className="text-white mb-4">
      <div>Sketch a reference</div>
      <div className="relative">
        <canvas
          width={512}
          height={512}
          ref={canvasRef}
          className="bg-white"
          style={{
            width: canvasWidth,
            height: canvasWidth,
          }}
        ></canvas>
        <canvas
          width={512}
          height={512}
          ref={detailCanvasRef}
          className="opacity-70 absolute left-0 top-0 select-none z-10 pointer-events-none"
          style={{
            width: canvasWidth,
            height: canvasWidth,
          }}
        ></canvas>
      </div>
      <div className="flex justify-between gap-2 mt-2 decoration-sky-300">
        <div className="flex gap-2">
          <div className="relative flex flex-col items-center group">
            <div className="select-none overflow-hidden w-auto h-10 flex rounded-lg text-sm font-medium border-2 bg-primary-100 text-primary-700 border-blue-700">
              <button
                type="button"
                className={classNames(
                  "transition-all gap-1.5 h-full flex items-center justify-center py-3 px-5",
                  {
                    "bg-blue-700": brushMode === "paint",
                  }
                )}
                style={{ width: "auto" }}
                onClick={() => {
                  onClickChangeBrush();
                }}
              >
                <img
                  src={PaintIcon}
                  alt="Paint"
                  style={{ width: 22, height: 22 }}
                />
              </button>
              <button
                type="button"
                className={classNames(
                  "transition-all gap-1.5 h-full flex items-center justify-center py-3 px-5",
                  {
                    "bg-blue-700": brushMode === "eraser",
                  }
                )}
                style={{ width: "auto" }}
                onClick={() => {
                  onClickChangeBrush();
                }}
              >
                <img
                  src={EraseIcon}
                  alt="Erase"
                  style={{ width: 22, height: 22 }}
                  className="scale-110"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <div
            className="relative flex flex-col items-center group"
            onClick={onClickClear}
          >
            <button className="transition-all select-none h-10 flex items-center justify-center rounded-lg text-sm font-medium gap-1.5 disabled:opacity-35 disabled:pointer-events-none py-3 px-5 bg-blue-700">
              Clear
            </button>
          </div>
          <div
            className="relative flex flex-col items-center group"
            onClick={onClickUndo}
          >
            <button className="transition-all select-none h-10 flex items-center justify-center rounded-lg text-sm font-medium gap-1.5 disabled:opacity-35 disabled:pointer-events-none p-0 w-10 bg-blue-700">
              <svg
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.82843 6.99955L8.36396 9.5351L6.94975 10.9493L2 5.99955L6.94975 1.0498L8.36396 2.46402L5.82843 4.99955L13 4.99955C17.4183 4.99955 21 8.58127 21 12.9996C21 17.4178 17.4183 20.9996 13 20.9996H4L4 18.9996H13C16.3137 18.9996 19 16.3133 19 12.9996C19 9.68585 16.3137 6.99955 13 6.99955L5.82843 6.99955Z"
                  fill="currentColor"
                ></path>
              </svg>
            </button>
          </div>
          <div
            className="relative flex flex-col items-center group"
            onClick={onClickRedo}
          >
            <button className="transition-all select-none h-10 flex items-center justify-center rounded-lg text-sm font-medium gap-1.5 disabled:opacity-35 disabled:pointer-events-none p-0 w-10 bg-blue-700">
              <svg
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.1716 6.99955L11 6.99955C7.68629 6.99955 5 9.68585 5 12.9996C5 16.3133 7.68629 18.9996 11 18.9996L20 18.9996V20.9996L11 20.9996C6.58172 20.9996 3 17.4178 3 12.9996C3 8.58127 6.58172 4.99955 11 4.99955H18.1716L15.636 2.46402L17.0503 1.0498L22 5.99955L17.0503 10.9493L15.636 9.5351L18.1716 6.99955Z"
                  fill="currentColor"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div>
        <label htmlFor="default-range" className="block mt-4 text-white">
          Brush size
        </label>
        <input
          id="default-range"
          type="range"
          min="2"
          max="100"
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
          value={brushSize}
          onChange={(e) => {
            setBrushSize(parseInt(e.target.value));

            // TODO dedupe this code
            // Draw a white circle in the center of the detail canvas
            let ctx = detailCanvasRef.current?.getContext("2d");
            if (!ctx || !detailCanvasRef.current) {
              return;
            }

            ctx.clearRect(
              0,
              0,
              detailCanvasRef.current.width,
              detailCanvasRef.current.height
            );
            ctx.beginPath();
            ctx.arc(
              detailCanvasRef.current.width / 2,
              detailCanvasRef.current.height / 2,
              brushSize,
              0,
              2 * Math.PI
            );
            ctx.fillStyle = "black";
            ctx.fill();

            // Set a timeout to clear the detail canvas
            // If it already exists, clear it
            if (clearDetailCanvasTimeout.current) {
              clearTimeout(clearDetailCanvasTimeout.current);
            }
            clearDetailCanvasTimeout.current = setTimeout(() => {
              if (!detailCanvasRef.current || !ctx) {
                return;
              }

              ctx.clearRect(
                0,
                0,
                detailCanvasRef.current.width,
                detailCanvasRef.current.height
              );
            }, 400);
          }}
        />
      </div>
    </div>
  );
}
