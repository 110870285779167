import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom"; // Import React Router's navigate
import { updateSubscriberInfo } from "../firebase/auth";
import { useUtilityStore } from "../zustand/utilityStores";

interface GiftCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  giftCardCode: string;
  setGiftCardCode: React.Dispatch<React.SetStateAction<string>>;
}

const GiftCardModal: React.FC<GiftCardModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  giftCardCode,
  setGiftCardCode,
}) => {
  // Get the Firebase Auth instance
  const auth = getAuth();
  const navigate = useNavigate(); // React Router's navigate hook
  const isPro = useUtilityStore((state) => state.isPro);

  // Error message state
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Loading state
  const [loading, setLoading] = useState(false);

  // Function to fetch the JWT token and make the API call
  const redeemGiftCard = async () => {
    setLoading(true); // Start loading
    setErrorMessage("");
    
    try {
      const user = auth.currentUser;

      if (user) {
        // Get the JWT token
        const idToken = await user.getIdToken();

        // Construct the Authorization header
        const headers = {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        };

        // Define the body
        const body = JSON.stringify({
          userUID: user.uid,
          code: giftCardCode,
        });

        // Send the POST request to the API
        const response: any = await fetch(
          "https://cluster1api.cdn2.io/Payment/dreampen/giftcard/g2a/redeemAGiftCard",
          {
            method: "POST",
            headers: headers,
            body: body,
          }
        );

        if (!response.ok) {
          setErrorMessage("Failed to redeem gift card.");
          setLoading(false); // Stop loading
          return;
        }

        const responseData = await response.json();
        if (responseData.hasError) {
          setErrorMessage(responseData.message || "Failed to redeem gift card");
          setLoading(false); // Stop loading
          return;
        }

        await updateSubscriberInfo();
        setGiftCardCode('');
        setLoading(false); // Stop loading after interval setup
        navigate("/subscriber"); 
      } else {
        setErrorMessage("No user is signed in.");
        setLoading(false); // Stop loading
      }
    } catch (error) {
      console.error("Error redeeming gift card:", error);
      setErrorMessage("There was an error redeeming the gift card.");
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if (isOpen) {
      // Clear error message when modal is opened
      setErrorMessage(null);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40">
      <div className="bg-white p-8 rounded-lg w-96">
        <div className="text-2xl font-bold mb-4 text-black">Redeem G2A Gift Card</div>
        {/* Display error message if it exists */}
        {(errorMessage && !loading) && (
          <div className="text-red-500 mb-2">{errorMessage}</div>
        )}
        {/* Loading spinner */}
        {loading && (
          <div role="status" className="mb-2">
            <svg aria-hidden="true" className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        )}
        <input
          type="text"
          value={giftCardCode}
          onChange={(e) => setGiftCardCode(e.target.value)}
          className="w-full p-2 mb-4 border rounded text-black"
          placeholder="Enter Gift Card Code"
          disabled={loading} // Disable input when loading
        />
        {!loading && <div className="flex justify-between">
          <button
            onClick={() => {
              setGiftCardCode('');
              onClose();
            }}
            className="px-4 py-2 bg-gray-500 text-white rounded"
          >
            Close
          </button>
          <button
            onClick={redeemGiftCard}
            className="px-4 py-2 bg-yellow-500 text-white rounded"
          >
            Submit
          </button>
        </div>}
      </div>
    </div>
  );
};

export default GiftCardModal;
