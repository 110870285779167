export const CATEGORY_ID_TO_STRING = {
  tags: "Body",
  ethnicity: "Ethnicity",
  hair_color: "Hair Color",
  hair_style: "Hair Style",
  base: "Base",
  environment: "Setting",
  style: "Style",
  view: "View",
  clothing: "Clothing",
  clothing_modifiers: "Clothing Modifiers",
  number: "Number of people",
  age: "Age",
  face: "Face",
  action: "Action",
  accessories: "Accessories/Objects",
  modifiers: "Modifiers/Effects",
  titfuck_view: "Titfuck View",
  titfuck_tags: "Titfuck Tags",
  titfuck_pose: "Titfuck Pose",
  anime_action: "Anime Action",
  anime_subject: "Anime Base",
  anime_tags: "Anime Tags",
  anime_style: "Anime Style",
  furry_base: "Furry Base",
  furry_tags: "Furry Tags",
  furry_action: "Furry Action",
  furry_style: "Furry Style",
  furry_gender: "Furry Gender",
  men_tags: "Men Tags",
  men_base: "Men Base",
  negative: "Advanced Style",
  character: "Character (beta)",
};

// How the category maps to the outputted API key
export const CATEGORY_ID_TO_OUTPUT = {
  tags: "TAGS",
  ethnicity: "TAGS",
  hair_color: "TAGS",
  hair_style: "TAGS",
  accessories: "TAGS",
  modifiers: "TAGS",
  base: "BASE",
  environment: "ENVIRONMENT",
  style: "STYLE",
  view: "VIEW",
  clothing: "CLOTHING",
  clothing_modifiers: "CLOTHING",
  number: "NUMBER",
  age: "AGE",
  face: "FACE",
  action: "ACTION",
  titfuck_view: "TITFUCK_VIEW",
  titfuck_tags: "TITFUCK_TAGS",
  titfuck_pose: "TITFUCK_POSE",
  anime_action: "ANIME_ACTION",
  anime_subject: "ANIME_SUBJECT",
  anime_tags: "ANIME_TAGS",
  anime_style: "ANIME_STYLE",
  furry_base: "FURRY_BASE",
  furry_tags: "FURRY_TAGS",
  furry_style: "FURRY_STYLE",
  furry_action: "FURRY_ACTION",
  furry_gender: "FURRY_GENDER",
  men_tags: "MEN_TAGS",
  men_base: "MEN_BASE",
  negative: "NEGATIVE",
  character: "CHARACTER",
};
