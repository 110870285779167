import { Button, Label, Modal, TextInput } from "flowbite-react";
import React, { useCallback, useEffect } from "react";
import { deleteFolder, getFolders, updateFolder } from "../firebase/functions";
import { useNavigate } from "react-router-dom";
import { useUtilityStore } from "../zustand/utilityStores";

export function UpdateFolderModal(props: {
  showModal?: boolean;
  setShowModal: (disableModal: boolean) => void;
  name: string;
  folderId: string;
  thumbUrl?: string;
}) {
  const [folderName, setFolderName] = React.useState(props.name);
  const [isWaiting, setIsWaiting] = React.useState(false);
  const [deleteStage, setDeleteStage] = React.useState(0);
  const navigate = useNavigate();
  const rootRef = React.useRef<HTMLDivElement>(null);
  const setFolders = useUtilityStore((state) => state.setFolders);
  const [thumbUrl, setThumbUrl] = React.useState(props.thumbUrl || "");

  useEffect(() => {
    setFolderName(props.name);
  }, [props.name]);

  const handleClose = useCallback(() => {
    props.setShowModal(false);
  }, [props]);

  const onClickDone = () => {
    setIsWaiting(true);
    updateFolder(props.folderId, folderName, thumbUrl).then(() => {
      setIsWaiting(false);
      handleClose();
      getFolders().then((folders) => {
        setFolders(folders);
      });
    });
  };

  const onClickDelete = useCallback(() => {
    if (deleteStage === 0) {
      setDeleteStage(1);
    } else if (deleteStage === 1) {
      setIsWaiting(true);
      deleteFolder(props.folderId).then(() => {
        setIsWaiting(false);
        handleClose();
        navigate("/profile");
      });
    }
  }, [deleteStage, handleClose, props.folderId]);

  return (
    <div ref={rootRef}>
      <Modal
        root={rootRef.current ?? undefined}
        className="dark"
        show={props.showModal}
        onClose={handleClose}
      >
        <Modal.Header>Update Folder</Modal.Header>
        <Modal.Body>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="folderName" value="Folder Name" />
            </div>
            <TextInput
              id="folderName"
              placeholder="Cool Pictures"
              maxLength={30}
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              helperText={<React.Fragment>30 characters max</React.Fragment>}
            />
            <div className="mt-4 mb-2 block">
              <Label htmlFor="thumbUrl" value="Thumbnail URL (optional)" />
            </div>
            <TextInput
              id="thumbUrl"
              placeholder="https://cdn.pornpen.ai/xxx"
              value={thumbUrl}
              onChange={(e) => setThumbUrl(e.target.value)}
            />
            <div className="flex flex-col items-center justify-center mt-2">
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded-lg disabled:opacity-30"
                disabled={isWaiting}
                onClick={onClickDone}
              >
                Save
              </button>
              <button
                className="underline text-red-600 mt-8 disabled:opacity-30"
                disabled={isWaiting}
                onClick={onClickDelete}
              >
                {deleteStage === 0
                  ? "Delete Folder"
                  : "Are you sure? Tap again to confirm."}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
