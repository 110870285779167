import { useEffect, useState } from "react";
import { Flag, adminGetFlags, adminReviewFlag } from "../firebase/functions";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase/setupFirebase";
import { doc, getDoc } from "firebase/firestore";
import { isAnimatedUrl } from "../utils/gifs";

export function AdminFlagReview() {
  const [flags, setFlags] = useState<Flag[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    adminGetFlags().then((flags) => {
      setFlags(flags);
      setIsLoading(false);
    });
  }, []);

  const onClickReview = (
    action: Parameters<typeof adminReviewFlag>[0],
    imageId: string
  ) => {
    // Review flag, then remove from list

    setFlags(flags.filter((flag) => flag.flag_image_id !== imageId));
    adminReviewFlag(action, imageId).then(() => {});
  };

  const onClickViewBase = async (imageId: string) => {
    // Navigate to edit_from id in a loop until edit_type is missing
    let parent = await doc(db, "results", imageId);
    let parentData = await getDoc(parent);

    while (parentData.data()?.edited_from) {
      parent = await doc(db, "results", parentData.data()?.edited_from);
      parentData = await getDoc(parent);
    }

    // Get id of base image and navigate to /view/id
    const baseId = parent.id;

    // Open in new tab
    window.open(`/view/${baseId}`, "_blank");
  };

  const flagList = flags.map((flag) => {
    const create = flag.create_date || flag.createDate;

    const imageCreateDate = new Date(
      create._seconds * 1000
    ).toLocaleDateString();

    const flagCreateDate = new Date(
      flag.flag_create_date._seconds * 1000
    ).toLocaleDateString();

    return (
      <div className="text-white border p-4 w-64" key={flag.id}>
        <Link to={`/view/${flag.flag_image_id}`} className="underline">
          View Image
        </Link>
        {flag.edit_type && (
          <button
            className="underline ml-4"
            onClick={() => onClickViewBase(flag.flag_image_id)}
          >
            View Base
          </button>
        )}
        {isAnimatedUrl(flag.image_url) ? (
          <video
            className="w-full"
            autoPlay
            loop
            muted
            playsInline
            src={flag.image_url}
          />
        ) : (
          <img src={flag.image_url} />
        )}
        <div>Image created: {imageCreateDate}</div>
        <div>Flag created: {flagCreateDate}</div>
        <div>Edit type: {flag.edit_type ? flag.edit_type : "base_image"}</div>
        <div className="flex justify-between flex-wrap">
          <button
            onClick={() => {
              onClickReview("approve", flag.flag_image_id);
            }}
            className="px-4 py-2 bg-green-500 mb-2 rounded-md hover:opacity-30"
          >
            Approve
          </button>
          <button
            onClick={() => {
              onClickReview("delete_ancestors", flag.flag_image_id);
            }}
            className="px-4 py-2 bg-red-500 mb-2 rounded-md hover:opacity-30"
          >
            Delete + descendants
          </button>
          <button
            onClick={() => {
              onClickReview("delete_seed_ancestors", flag.flag_image_id);
            }}
            className="px-4 py-2 bg-red-500 mb-2 rounded-md hover:opacity-30"
          >
            Delete + seed + descendants
          </button>
        </div>
      </div>
    );
  });

  return (
    <div>
      <div className="text-white text-2xl text-center">Flags</div>
      {isLoading && <div className="text-white animate-pulse">Loading...</div>}
      <div className="flex flex-row flex-wrap">{flagList}</div>
    </div>
  );
}
