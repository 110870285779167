import { Button, Label, Modal, TextInput } from "flowbite-react";
import React, { useCallback, useRef } from "react";

export function AddFolderModal(props: {
  showModal?: boolean;
  setShowModal: (disableModal: boolean) => void;
  addFolder: (name: string) => Promise<void>;
  refreshFolders: Function;
}) {
  const [isWaiting, setIsWaiting] = React.useState(false);
  const [folderName, setFolderName] = React.useState("");
  const rootRef = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(() => {
    props.setShowModal(false);
  }, [props]);

  const onClickDone = useCallback(() => {
    setIsWaiting(true);
    props.addFolder(folderName).then(() => {
      props.refreshFolders();
      handleClose();
      setIsWaiting(false);
    });
  }, [handleClose, props, folderName]);

  return (
    <div ref={rootRef}>
      <Modal
        root={rootRef.current ?? undefined}
        className="dark"
        show={props.showModal}
        onClose={handleClose}
        dismissible
      >
        <Modal.Header>New Folder</Modal.Header>
        <Modal.Body>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="folderName" value="Folder Name" />
            </div>
            <TextInput
              id="folderName"
              placeholder="Cool Pictures"
              maxLength={30}
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              helperText={<React.Fragment>30 characters max</React.Fragment>}
            />
            <div className="flex flex-row justify-center">
              <Button
                className="disabled:opacity-30"
                disabled={isWaiting}
                onClick={onClickDone}
              >
                Done
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
