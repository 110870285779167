import classNames from "classnames";
import { IS_DEVELOPMENT, IS_TEST_CLOUD_FN } from "../test/SetupTest";
import { useState } from "react";

export function DebugOverlay() {
  const isDevelopment = IS_DEVELOPMENT;
  const isTestCloudFn = IS_TEST_CLOUD_FN;

  const showModal = process.env.NODE_ENV === "development" || isTestCloudFn;
  const [hide, setHide] = useState(false);

  if (!showModal) {
    return null;
  }

  if (hide) {
    return null;
  }

  return (
    <div
      className={classNames(
        "absolute text-white bg-red-800 top-0 left-0 py-2 px-6",
        {
          "bg-yellow-700": isTestCloudFn,
        }
      )}
      onClick={() => setHide(!hide)}
    >
      <div className="font-bold">Debug</div>
      <div>Environment: {isDevelopment ? "Dev" : "Prod"}</div>
      <div>Test Cloud Fn: {isTestCloudFn ? "Yes" : "No"}</div>
    </div>
  );
}
