import { useEffect, useState } from "react";
import { logEvent } from "../analytics/googleAnalytics";
import AllTagSelector from "../components/AllTagSelector";
import {
  getNumSelected,
  getNumSelectedText,
  TagStore,
  useTagStore,
} from "../zustand/tagStore";
import { useMediaQuery } from "react-responsive";

import { Allotment } from "allotment";
import "./Feed.css";
import { useUtilityStore } from "../zustand/utilityStores";
import { GeneratorSelect } from "../components/GeneratorSelector";
import { submitSearch } from "../firebase/functions";
import "lazysizes";
import { InfiniteImageList } from "../components/InfiniteImageList";
import { SearchResult } from "../firebase/searchUtils";
import { Link } from "react-router-dom";
import { SelectedTags } from "../components/SelectedTags";
import { FirebaseError } from "firebase/app";
import { toast } from "react-hot-toast";

export default function Search() {
  const selectedTags = useTagStore((state) => state.selectedTags);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const headerHeight = useUtilityStore((state) => state.headerHeight);
  const clearTags = useTagStore((state) => state.clearTags);
  const setTag = useTagStore((state) => state.setTag);
  const generator = useTagStore((state) => state.generator);
  const setGenerator = useTagStore((state) => state.setGenerator);
  const [listKey, setListKey] = useState(0);
  const isPro = useUtilityStore((state) => state.isPro);

  // Load tags from url, split by comma, then call set Tag
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tags = urlParams.get("tags");
    if (tags) {
      const tagArr = tags.split(",");
      tagArr.forEach((tag) => {
        setTag(tag, true);
      });
    }

    const generator = urlParams.get("generator");
    if (generator) {
      setGenerator(generator as TagStore["generator"]);
    }
  });

  const fetchMore = async (cursor: any) => {
    let filteredKeys = Object.keys(selectedTags)
      .filter((tag) => tag.indexOf("_default") === -1)
      .filter((tag) => selectedTags[tag]);

    try {
      const results = await submitSearch(
        filteredKeys,
        generator,
        "search",
        cursor
      );
      let feedResults: SearchResult[] = [];
      results.forEach((result) => {
        feedResults.push({
          imageUrl: result.imageUrl,
          imageId: result.imageId,
          isPrivate: false,
        });
      });

      let isEnd = results.length < 100;
      if (!isPro) {
        isEnd = true;
      }

      let lastDate: any = null;

      if (results.length > 0) {
        lastDate = results[results.length - 1].createTime || null;
      }
      return [results, lastDate, isEnd];
    } catch (e) {
      if ((e as FirebaseError).code === "functions/failed-precondition") {
        toast.error(
          "Search rate limited for free users. Please wait a second."
        );
      }

      return [[], null, true];
    }
  };

  const onClickClear = () => {
    clearTags();
    logEvent("click_clear_filters");
  };

  // When tags or generator changes, clear results.
  useEffect(() => {
    // If there are no tags selected, the default tags haven't been set.
    if (Object.keys(selectedTags).length === 0) {
      return;
    }

    setListKey((key) => key + 1);
  }, [selectedTags, generator]);

  return (
    <div
      className="flex flex-col justify-center items-center FullMinusHeader"
      style={{ height: `calc(100vh - ${headerHeight}px)` }}
    >
      <Allotment vertical={isTabletOrMobile}>
        <Allotment.Pane>
          <div className="w-full h-full overflow-auto">
            <div className="flex justify-center my-2 text-white text-lg font-bold">
              Select tags to search
            </div>
            <SelectedTags></SelectedTags>
            <GeneratorSelect
              onChangeGenerator={setGenerator}
              value={generator}
              className="ml-2"
              addAllOption
            />
            <AllTagSelector forceGenerator={generator} showSavedTags />
          </div>
        </Allotment.Pane>
        <div id="feed-parent" className="w-full h-full overflow-scroll">
          <InfiniteImageList
            emptyEndMessage="No results"
            fullEndMessage={
              isPro ? (
                "End of list"
              ) : (
                <Link className="text-yellow-500 underline" to="/getpro">
                  Upgrade to Pro to see more results
                </Link>
              )
            }
            getMore={fetchMore as any}
            imageClassName="sm:w-56 w-36 relative aspect-square"
            skipCheck
            scrollableTarget="feed-parent"
            key={listKey}
            showSave
          />
        </div>
      </Allotment>
    </div>
  );
}
