import { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import { tagIdToDataMap, useTagStore } from "../zustand/tagStore";
import TagStrings from "../zustand/strings.json";
import { logEvent } from "../analytics/googleAnalytics";
import { CATEGORY_ID_TO_STRING } from "../common/categoryDefinitions";
import { Link, useNavigate } from "react-router-dom";
import { usePopperTooltip } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";
import { TagCard } from "../screens/BrowseTags";
import { isTouchDevice } from "../screens/DirectorScreen";
import { getLiveTags } from "../firebase/functions";
import { useUtilityStore } from "../zustand/utilityStores";
import toast from "react-hot-toast";

interface Props {
  categoryId: keyof typeof CATEGORY_ID_TO_STRING;
  data: string[];
  // Only one tag can be selected at a time
  isSingleChoice?: boolean;
  // Whether to show the default selection option (such as 'woman" for base)
  showDefault?: boolean;
  alphabetical?: boolean;
  // For Tag artists
  showCommunityTags?: boolean;
  // For all users
  showSavedTags?: boolean;
  // Shows below category title
  subtitle?: string;
  // Shows a button that prompts you to community tags
  hideAddTagPromo?: boolean;
  // A default tag to select in a multi-select situation (such as clothing)
  multiSelectDefault?: string;
  // Exclusive tags for pro
  isPro?: boolean;
}

const NEW_TAGS: string[] = [];

export default function TagSelector(props: Props) {
  const setTag = useTagStore((state) => state.setTag);
  let preClick: (
    tagId: string,
    isSelect: boolean
  ) => void | undefined = () => {};
  const studioTags = useTagStore((state) => state.studioTags);
  const [finalTags, setFinalTags] = useState<string[]>([]);
  const generator = useTagStore((state) => state.generator);

  const getDisplayName = useCallback((tag: string) => {
    let displayName = TagStrings[tag as keyof typeof TagStrings];

    // If the tag has "studio_" in it, we need to grab the display name from the studio tags
    // which is loaded in global state.
    if (tag.indexOf("studio_") !== -1) {
      const plainId = tag.replace("studio_", "");
      const tagInfo = tagIdToDataMap[plainId];
      if (tagInfo) {
        displayName = tagInfo.name;
      }
    }

    return displayName || tag;
  }, []);

  if (props.isSingleChoice) {
    // Set every tag false
    preClick = (tagId: string, isSelected: boolean) => {
      props.data.forEach((tag) => {
        setTag(tag, false);
      });

      // Also set appropriate studio tags to false if the cateogryId matches
      studioTags.forEach((tag) => {
        if (tag.category === props.categoryId) {
          setTag("studio_" + tag.id, false);
        }
      });
    };
  }

  if (props.multiSelectDefault) {
    preClick = (tagId: string, isSelected: boolean) => {
      if (tagId === props.multiSelectDefault) {
        if (isSelected) {
          setTag(tagId, false);
        } else {
          props.data.forEach((tag) => {
            setTag(tag, false);
          });

          // Also set appropriate studio tags to false if the cateogryId matches
          studioTags.forEach((tag) => {
            if (tag.category === props.categoryId) {
              setTag("studio_" + tag.id, false);
            }
          });
        }
      } else {
        if (!isSelected && props.multiSelectDefault) {
          setTag(props.multiSelectDefault, false);
        }
      }
    };
  }

  useEffect(() => {
    // Make a copy to avoid mutating the original
    let finalData = [...props.data];
    if (!props.showDefault) {
      finalData = props.data.filter((tag) => tag.indexOf("_default") === -1);
    }

    if (props.alphabetical) {
      // Sort the tags
      // _default should be first
      // other tags are sorted alphabetically by tag
      finalData = finalData.sort((a, b) => {
        if (a.indexOf("_default") !== -1) {
          return -1;
        }
        if (b.indexOf("_default") !== -1) {
          return 1;
        }

        // Compare based on visible string.
        const aString = TagStrings[a as keyof typeof TagStrings] || a;
        const bString = TagStrings[b as keyof typeof TagStrings] || b;
        return aString.localeCompare(bString);
      });
    }

    // Append any saved tags
    if (props.showSavedTags || props.showCommunityTags) {
      studioTags.forEach((tag) => {
        // Show tag if it's in the category and not already in the list
        let baselineShow =
          tag.category === props.categoryId &&
          !finalData.includes("studio_" + tag.id);

        // Should we show saved tags here? Some Tag Selectors only show base tags
        let savedShow = baselineShow && tag.isSaved && props.showSavedTags;

        // This is for tag artists
        let communityShow = baselineShow && props.showCommunityTags;

        if (savedShow || communityShow) {
          if (tag.onlyGenerators && tag.onlyGenerators.length > 0) {
            // If the tag is only for certain generators, we need to check if the current generator is one of them
            if (tag.onlyGenerators.includes(generator)) {
              finalData.push("studio_" + tag.id);
            }
          } else {
            finalData.push("studio_" + tag.id);
          }
        }
      });
    }

    setFinalTags(finalData);
  }, [
    getDisplayName,
    props.alphabetical,
    props.categoryId,
    props.data,
    props.showCommunityTags,
    props.showDefault,
    props.showSavedTags,
    studioTags,
    generator,
  ]);

  return (
    <div className="mb-4">
      <div className="ml-4 font-bold text-white">
        {CATEGORY_ID_TO_STRING[props.categoryId]}
      </div>
      {props.subtitle && (
        <div className="ml-4 text-white">{props.subtitle}</div>
      )}
      <div className="flex col flex-wrap">
        {finalTags.map((tag) => (
          <Chip
            key={tag}
            tagId={tag}
            displayName={getDisplayName(tag)}
            preClick={preClick}
            isSingleChoice={props.isSingleChoice}
            isPro={props.isPro}
          />
        ))}
        {!props.hideAddTagPromo && (
          <AddTagPromoChip categoryId={props.categoryId} />
        )}
      </div>
    </div>
  );
}

export function Chip(props: {
  tagId: string;
  displayName: string;
  preClick?: Function;
  isSingleChoice?: boolean;
  clickOverride?: Function;
  isPro?: boolean;
}) {
  const isSelected = useTagStore((state) => state.selectedTags[props.tagId]);
  const toggleTag = useTagStore((state) => state.toggleTag);
  const isStudioTag = props.tagId.indexOf("studio_") !== -1;
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({}, { placement: "auto" });
  const tagMap = useTagStore((state) => state.tagIdToDataMap);
  const foundTag = tagMap[props.tagId.replace("studio_", "")];
  const isPro = useUtilityStore((state) => state.isPro);
  const navigate = useNavigate();

  useEffect(() => {
    getLiveTags();
  });

  const onClick = (e: React.MouseEvent) => {
    // Only log the event if it's a real click
    if (e?.nativeEvent?.isTrusted) {
      logEvent("click_tag");
    }

    if (props.isPro && !isPro) {
      toast.error("This tag is only available to Pro users.");
      navigate("/getpro");
      return;
    }

    if (props.clickOverride) {
      props.clickOverride();
      return;
    }

    let prevState = isSelected;

    // Sometimes we need to change the state of other tags before we toggle this one
    // This is useful for turning off other tags when we select a single choice tag
    if (props.preClick) {
      props.preClick(props.tagId, isSelected);
    }

    // This turns off the tag if it was already selected
    if (prevState && props.isSingleChoice) {
      return;
    }

    // @ts-ignore
    if (TagStrings[props.tagId]) {
      toggleTag(props.tagId);
    }

    // If the tag is a studio tag, we need to toggle it in the studio tags
    if (isStudioTag) {
      toggleTag(props.tagId);
    }
  };

  const newIndicator = NEW_TAGS.includes(props.tagId) ? (
    <div
      className="absolute bg-red-800 w-3 h-3 -top-1 -right-1 rounded-lg"
      title="new tag"
    ></div>
  ) : null;

  return (
    <>
      <div
        key={props.tagId}
        ref={setTriggerRef}
        className={classnames(
          "text-base px-4 py-2 m-2 border rounded-lg text-white select-none relative cursor-pointer PPChip",
          {
            "bg-green-700 border border-transparent": isSelected,
            "border-purple-500": isStudioTag,
            "bg-purple-500 border border-transparent":
              isSelected && isStudioTag,
          }
        )}
        onClick={onClick}
        data-tagid={props.clickOverride ? "" : props.tagId}
      >
        {props.displayName.toLocaleLowerCase()}
        {newIndicator}
      </div>
      {visible && isStudioTag && !isTouchDevice() && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: "w-64 z-50" })}
        >
          <TagCard
            name={foundTag?.name || ""}
            description={foundTag?.description || ""}
            id={props.tagId}
            category={foundTag?.category || ""}
            imageUrl={foundTag?.imageUrl || ""}
            numSaves={foundTag?.numSaves || 0}
            ownerUsername={foundTag?.ownerUsername || ""}
            hideSaveButton
            showFullDescription
          />
        </div>
      )}
    </>
  );
}

export function AddTagPromoChip(props: {
  categoryId: keyof typeof CATEGORY_ID_TO_STRING;
}) {
  return (
    <Link
      to={`/tags/browse?category=${props.categoryId}`}
      className="text-base px-4 py-2 m-2 border rounded-lg text-white select-none relative border-purple-500 cursor-pointer"
    >
      +
    </Link>
  );
}
