import { useEffect, useState } from "react";
import { adminGetSubmittedTags, adminUpdateTag } from "../firebase/functions";
import { Tag } from "../common/tagDefinitions";
import { toast } from "react-hot-toast";
import { useModelStore } from "../zustand/modelStore";

export function convertFirestoreDate(date: any) {
  return new Date(date._seconds * 1000);
}

export function AdminTagReview() {
  const [tags, setTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rejectReason, setRejectReason] = useState<string>("");
  const [activeRejection, setActiveRejection] = useState<string>("");
  const [isRejecting, setIsRejecting] = useState<boolean>(false);
  const models = useModelStore((state) => state.models);

  useEffect(() => {
    adminGetSubmittedTags().then((tags) => {
      // Sort tags by _seconds for updateDate
      tags.sort((a, b) => {
        return b.updateDate._seconds - a.updateDate._seconds;
      });

      setTags(tags);
      setIsLoading(false);
    });
  }, []);

  const onClickApprove = (tagId: string) => {
    return adminUpdateTag(tagId, "live").then(() => {
      const newTags = tags.filter((tag) => tag.id !== tagId);
      setTags(newTags);
    });
  };

  const onClickReject = (tagId: string) => {
    if (!activeRejection || activeRejection !== tagId) {
      setActiveRejection(tagId);
    } else {
      if (!rejectReason) {
        toast.error("Please enter a reason for rejection");
        return;
      }

      setIsRejecting(true);
      adminUpdateTag(tagId, "rejected", rejectReason).then(() => {
        const newTags = tags.filter((tag) => tag.id !== tagId);
        setTags(newTags);
        setActiveRejection("");
        setRejectReason("");
        setIsRejecting(false);
      });
    }
  };

  const tagList = tags.map((tag) => {
    // Convert firestore timestamp to date
    const updateDate = convertFirestoreDate(
      tag.updateDate
    ).toLocaleDateString();

    return (
      <div className="text-white border p-4 w-60" key={tag.id}>
        <div className="text-center text-lg">{tag.name}</div>
        <div>
          <img src={tag.imageUrl} />
        </div>
        <div className="text-center">
          <button
            className="px-4 py-2 bg-blue-500 rounded-md my-2 hover:border"
            onClick={() => {
              onClickApprove(tag.id);
            }}
          >
            Approve
          </button>
          <button
            className="px-4 py-2 bg-red-500 rounded-md my-2 hover:border disabled:opacity-30"
            disabled={isRejecting}
            onClick={() => {
              onClickReject(tag.id);
            }}
          >
            {activeRejection === tag.id ? "Confirm" : "Reject"}
          </button>
        </div>
        {activeRejection === tag.id && (
          <input
            value={rejectReason}
            onChange={(event) => {
              setRejectReason(event.target.value);
            }}
            className="text-black"
            placeholder="rejection reason"
            type="text"
          />
        )}
        <div>Artist Name: {tag.ownerUsername}</div>
        <div>Category: {tag.category}</div>
        <div>Submitted: {updateDate}</div>
        <br />
        <div className="font-bold">Description</div>
        <div>{tag.description}</div>
        <br />
        <div className="font-bold">Prompt</div>
        <div className="w-36">{tag.prompt}</div>
        <br />
        {tag.onlyGenerators && tag.onlyGenerators.length > 0 && (
          <>
            <div className="font-bold">Only Generators</div>
            <div>
              {tag.onlyGenerators
                .map((gen) => {
                  let model = models.find((m) => m.id === gen);

                  if (!model) {
                    return "";
                  } else {
                    return `${model.prompt_system}:${model.name}`;
                  }
                })
                .join(", ") || ""}
            </div>
          </>
        )}
      </div>
    );
  });

  return (
    <div>
      <div className="text-white text-2xl text-center">Tag Review</div>
      {isLoading && (
        <div className="text-white text-center animate-pulse">Loading...</div>
      )}
      <div className="flex flex-row flex-wrap">{tagList}</div>
    </div>
  );
}
