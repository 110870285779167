import { Modal } from "flowbite-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { deletePrivateImage } from "../firebase/functions";
import { resolveImageUrl } from "../utils/strings";
import { isAnimatedUrl } from "../utils/gifs";

export function DeleteModal(props: {
  showModal?: boolean;
  setShowModal: (disableModal: boolean) => void;
  imageUrl: string;
  imageId: string;
  onDelete?: Function;
}) {
  const [didSubmit, setDidSubmit] = React.useState(false);
  const [isWaiting, setIsWaiting] = React.useState(false);
  const navigate = useNavigate();

  // If imageId changes, reset state
  React.useEffect(() => {
    setDidSubmit(false);
    setIsWaiting(false);
  }, [props.imageId]);

  const handleClose = () => {
    props.setShowModal(false);
  };

  const onClickDelete = async () => {
    setIsWaiting(true);
    try {
      await deletePrivateImage(props.imageId);
    } catch (e) {
      console.error(e);
    }
    setDidSubmit(true);
    setIsWaiting(false);

    if (props.onDelete) {
      props.onDelete();
    } else {
      setTimeout(() => {
        navigate("/private");
      }, 1000);
    }
  };

  return (
    <React.Fragment>
      <Modal show={props.showModal} onClose={handleClose} dismissible>
        <Modal.Header>Delete content</Modal.Header>
        <Modal.Body>
          <div className="mt-4 text-red-600">
            Warning: This will delete the image permanently. Other edits will
            not be deleted. Are you sure you want to continue?
          </div>
          <div className="flex flex-row justify-center mt-4">
            <div className=" max-w-sm">
              {/* <img src={resolveImageUrl(props.imageUrl)} alt="reporting" /> */}
              {isAnimatedUrl(props.imageUrl) ? (
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  src={resolveImageUrl(props.imageUrl)}
                />
              ) : (
                <img src={resolveImageUrl(props.imageUrl)} alt="reporting" />
              )}
            </div>
          </div>
          {!didSubmit && (
            <div className="flex flex-row justify-center">
              <button
                disabled={isWaiting}
                className="text-white py-2 px-4 rounded-lg mt-4
                disabled:opacity-50 bg-red-800"
                onClick={onClickDelete}
              >
                Delete
              </button>
            </div>
          )}
          {isWaiting && (
            <div className="flex flex-row justify-center">
              <div className="text-green-500 mt-4 animate-pulse">
                Deleting...
              </div>
            </div>
          )}
          {didSubmit && (
            <div className="flex flex-row justify-center">
              <div className="text-green-500 mt-4">Content deleted</div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
