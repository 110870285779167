import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { IS_DEVELOPMENT } from "../test/SetupTest";

const firebaseConfig = {
  apiKey: "AIzaSyBL4bXeUdpCbe1JujITfxLmlNt2cupxynM",
  authDomain: "auth.pornpen.ai",
  projectId: "dreampen-2273f",
  storageBucket: "dreampen-2273f.appspot.com",
  messagingSenderId: "797017377342",
  appId: "1:797017377342:web:e1c3463fe93bd5146adf6f",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

if (IS_DEVELOPMENT) {
  console.log("Emulator");
  connectFirestoreEmulator(db, "localhost", 8080);
}

export function getRandomFirestoreId() {
  // Alphanumeric characters
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  // The largest byte value that is a multiple of `char.length`.
  const maxMultiple = Math.floor(256 / chars.length) * chars.length;

  let autoId = "";
  const targetLength = 20;
  while (autoId.length < targetLength) {
    const bytes = randomBytes(40);
    for (let i = 0; i < bytes.length; ++i) {
      // Only accept values that are [0, maxMultiple), this ensures they can
      // be evenly mapped to indices of `chars` via a modulo operation.
      if (autoId.length < targetLength && bytes[i] < maxMultiple) {
        autoId += chars.charAt(bytes[i] % chars.length);
      }
    }
  }

  return autoId;
}

function randomBytes(n: number) {
  // Browsers
  var crypto = window.crypto,
    QUOTA = 65536;
  var a = new Uint8Array(n);
  for (var i = 0; i < n; i += QUOTA) {
    crypto.getRandomValues(a.subarray(i, i + Math.min(n - i, QUOTA)));
  }
  return a;
}
