import { Modal } from "flowbite-react";
import React from "react";

export function ModalContainer(props: {
  showModal?: boolean;
  setShowModal: (disableModal: boolean) => void;
  onClose?: () => void;
  title: string;
  children: React.ReactNode;
  disableDark?: boolean;
  footer?: React.ReactNode;
}) {
  const rootRef = React.useRef<HTMLDivElement>(null);

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }

    props.setShowModal(false);
  };

  let theme = "dark";
  if (props.disableDark) {
    theme = "light";
  }

  return (
    <div ref={rootRef}>
      <Modal
        root={rootRef.current ?? undefined}
        className={theme}
        show={props.showModal}
        onClose={handleClose}
        dismissible
      >
        <Modal.Header>{props.title}</Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        {props.footer && <Modal.Footer>{props.footer}</Modal.Footer>}
      </Modal>
    </div>
  );
}
