import { CATEGORY_ID_TO_OUTPUT } from "../common/categoryDefinitions";
import { Tag } from "../common/tagDefinitions";
import { getPublicTagDataFromIds } from "../firebase/functions";
import { TOP_LEVEL } from "../zustand/data";
import { useTagStore } from "../zustand/tagStore";

type Keys = keyof typeof TOP_LEVEL;

export interface Result {
  [key: string]: string[];
}

export function generatePayload() {
  let state = useTagStore.getState();

  let result: Result = {};
  Object.keys(TOP_LEVEL).forEach((key) => {
    result[key] = [];
    let options = TOP_LEVEL[key as Keys];
    options.forEach((option, index) => {
      // Don't push default options since they are just vanity.
      if (option.indexOf("_default") !== -1) {
        return;
      }

      if (state.selectedTags[option]) {
        result[key].push(option);
      }
    });
  });

  Object.keys(state.selectedTags).forEach((key) => {
    // For studio tags, we need to lookup the tag category to include it in the payload.
    if (key.indexOf("studio_") !== -1) {
      const firestoreId = key.replace("studio_", "");
      let tag = state.studioTags.find((tag) => tag.id === firestoreId);

      if (tag && tag.category && state.selectedTags[key]) {
        result[
          CATEGORY_ID_TO_OUTPUT[
            tag.category as keyof typeof CATEGORY_ID_TO_OUTPUT
          ]
        ].push(key);
      }
    }
  });

  return result;
}

// TODO cleanup this
// Doesn't use global state, takes generator and tags as input.
export function generatePayloadGeneric(
  tags: string[],
  resolvedTags: Record<string, Tag>
) {
  let result: Result = {};
  Object.keys(TOP_LEVEL).forEach((key) => {
    result[key] = [];
    let options = TOP_LEVEL[key as Keys];
    options.forEach((option, index) => {
      // Don't push default options since they are just vanity.
      if (option.indexOf("_default") !== -1) {
        return;
      }

      if (tags.includes(option)) {
        result[key].push(option);
      }
    });
  });

  tags.forEach((key) => {
    // For studio tags, we need to lookup the tag category to include it in the payload.
    if (key.indexOf("studio_") !== -1) {
      const firestoreId = key.replace("studio_", "");
      let tag = resolvedTags[firestoreId];

      if (tag && tag.category) {
        result[
          CATEGORY_ID_TO_OUTPUT[
            tag.category as keyof typeof CATEGORY_ID_TO_OUTPUT
          ]
        ].push(key);
      }
    }
  });

  return result;
}
