export function EpochMock() {
  return (
    <div className="border rounded-md p-4 m-4 text-white">
      <div className="text-lg font-bold text-center text-yellow-400">
        Epoch Pro Membership
      </div>
      <div className="font-bold">Status: active</div>
      <div>Price: $15 per month</div>
      <div>Next Renewal Date: 2/19/2024</div>
      <div>Last Charge Date: 1/19/2024</div>
      <div>Exp Date: 11/12</div>
      <br />
      <div className="font-bold">CCBill Info</div>
      <div>Email: test@epoch.com</div>
      <div>Subscription ID: 12345</div>
      <br />
      <a className="underline text-white" href="https://epoch.com">
        Manage Epoch Subscription
      </a>
      <br />
    </div>
  );
}
