import { collection, getDocs, limit, query, where } from "@firebase/firestore";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebase/setupFirebase";

export function ReverseLookupScreen() {
  const [input, setInput] = React.useState<string>("");
  const [result, setResult] = React.useState<string>("");
  const [notFound, setNotFound] = React.useState<boolean>(false);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const onClickSearch = useCallback(() => {
    setNotFound(false);
    // Input could be in the form of
    //https://cdn.pornpen.ai/abcd.jpeg
    // or just the filename with our without the extension

    // We need to parse all the possible inputs, and then construct
    // a URL in the form of https://cdn.pornpen.ai/abcd.jpeg

    // Then we can use the same code as the search screen to find the
    // original link

    const fullUrlRegex = /https:\/\/cdn\.pornpen\.ai\/(.*)\.(jpeg|jpg|png)/;
    const filenameRegex = /(.*)\.(jpeg|jpg|png)/;
    const justFilenameRegex = /(.*)/;

    let match = input.match(fullUrlRegex);
    let filename = "";

    if (match && match[1]) {
      filename = match[1];
    }

    match = input.match(filenameRegex);
    if (match && match[1] && !filename) {
      filename = match[1];
    }

    match = input.match(justFilenameRegex);
    if (match && match[1] && !filename) {
      filename = match[1];
    }

    if (filename) {
      getOriginalLink(filename).then((url) => {
        if (!url) {
          setNotFound(true);
        } else {
          setResult(url);
        }
      });
    }
  }, [input]);

  return (
    <div className="text-white p-4 max-w-lg">
      <h1 className="text-white">Reverse Lookup</h1>
      <div className="text-white">
        Put in a filename or URL to find the original link. On Discord, you can
        often find the filename when you click on on image, and then click on
        "Open in browser".
      </div>
      <br />
      <div className="font-bold">Example inputs</div>
      <ul className="list-disc">
        <li>https://cdn.pornpen.ai/FB0757644A90AF14.jpeg</li>
        <li>FB0757644A90AF14.jpeg</li>
        <li>FB0757644A90AF14</li>
      </ul>
      <div className="mt-8">
        <input
          className="text-black w-full"
          value={input}
          onChange={onInputChange}
          type="text"
        ></input>
      </div>
      <div className="text-white underline text-right">
        <button onClick={onClickSearch} className="underline text-xl mt-4">
          Search
        </button>
      </div>
      {result && (
        <div className="mt-8">
          <div className="font-bold text-yellow-400 text-xl">Result</div>
          <div>
            <Link
              className="underline"
              to={result.replace("https://pornpen.ai", "")}
            >
              {result}
            </Link>
          </div>
        </div>
      )}
      {notFound && <div className="text-xl">Image not found 🥲</div>}
    </div>
  );
}

async function getOriginalLink(filename: string) {
  const hasDash = filename.includes("-");

  const url = hasDash
    ? `https://cdn.pornpen.ai/${filename}.jpg`
    : `https://cdn.pornpen.ai/${filename}.jpeg`;

  let q = query(collection(db, "results"), where("image_url", "==", url));
  q = query(q, limit(1));

  const querySnapshot = await getDocs(q);
  let result = "";

  querySnapshot.forEach((doc) => {
    result = doc.id;
  });

  if (!result) {
    return "";
  }
  return `https://pornpen.ai/view/${result}`;
}
