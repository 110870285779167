import { useCallback, useMemo, useState } from "react";
import { useModelStore } from "../zustand/modelStore";
import { useTagStore } from "../zustand/tagStore";
import { ModalContainer } from "./ModalContainer";
import { GeneratorSelect } from "./GeneratorSelector";
import InfoIcon from "../images/info.svg";

export const FIXED_MODEL_CATEGORIES = [
  "women",
  "men",
  "anime",
  "furry",
  "other",
];

export function GeneratorModal(props: {
  className?: string;
  onChangeGenerator?: (generator: string) => void;
}) {
  const models = useModelStore((state) => state.models);
  const generator = useTagStore((state) => state.generator);
  const setGenerator = useTagStore((state) => state.setGenerator);
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(
    "women"
  );

  const selectedModel = useMemo(() => {
    return models.find((model) => model.id === generator);
  }, [models, generator]);

  const onClickMainButton = useCallback(() => {
    setShowModal(true);
  }, []);

  let onChange = (e: any) => setGenerator(e.target.value);

  if (props.onChangeGenerator) {
    onChange = (e: any) => {
      if (props.onChangeGenerator) {
        props.onChangeGenerator(e.target.value);
      }
    };
  }

  const onClickHelp = useCallback(() => {
    setShowModal(true);
  }, []);

  return (
    <>
      <div className={"flex flex-col w-48 " + props.className}>
        <div className="mb-2 text-white flex flex-row items-center">
          <div className="mr-2">Generator</div>
          <button onClick={onClickHelp}>
            <img alt="" src={InfoIcon} className="w-4 h-4" />
          </button>
        </div>
        <GeneratorSelect value={generator} />
      </div>
      <ModalContainer
        title="Choose a Generator"
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <div className="">
          <div className="flex flex-row justify-center flex-wrap">
            {FIXED_MODEL_CATEGORIES.map((category) => {
              return (
                <button
                  key={category}
                  className={
                    "px-4 py-2 rounded-lg border-transparent border mr-4 mb-4 text-white " +
                    (selectedCategory === category
                      ? "bg-green-500 text-white border"
                      : "border border-white")
                  }
                  onClick={() => {
                    setSelectedCategory(category);
                  }}
                >
                  {category}
                </button>
              );
            })}
          </div>
          <div className="flex flex-row flex-wrap justify-center items-center">
            {models
              .filter((model) => {
                if (model.preview) {
                  return false;
                }

                if (model.prompt_system === selectedCategory) {
                  return true;
                }

                if (
                  selectedCategory === "other" &&
                  !FIXED_MODEL_CATEGORIES.includes(model.prompt_system)
                ) {
                  return true;
                }
              })
              .map((model) => {
                return (
                  <div
                    key={model.id}
                    className="flex rounded-lg  bg-slate-900 shadow-md flex-col w-full max-w-[256px] mb-4 mr-4 select-none cursor-pointer"
                    onClick={() => {
                      setGenerator(model.id);
                      setShowModal(false);
                    }}
                  >
                    <div className="rounded-t-lg aspect-square relative">
                      <img
                        alt=""
                        className="lazyload rounded-t-lg"
                        data-src={model.thumbnail_url}
                      />
                      <div className="text-white absolute top-0 left-0 p-2 rounded-tl-lg rounded-br-lg bg-black/80 font-bold">
                        {model.name}
                      </div>
                    </div>
                    <div className="text-white p-2 h-16 text-sm text-center">
                      {model.description}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </ModalContainer>
    </>
  );
}
