import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export default function FinishSignUp() {
  const [didSignIn, setDidSignIn] = useState(false);
  const [isWaitingForEmail, setIsWaitingForEmail] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // Confirm the link is a sign-in with email link.
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        setIsWaitingForEmail(true);
        return;
      }

      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          setDidSignIn(true);
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          console.log(error);
          setError(error.message);
        });
    }
  }, []);

  const onSubmitEmail = useCallback(() => {
    const auth = getAuth();

    // The client SDK will parse the code from the link for you.
    signInWithEmailLink(auth, inputEmail, window.location.href)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem("emailForSignIn");
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        setIsWaitingForEmail(false);
        setDidSignIn(true);
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        console.log(error);
        setError(error.message);
      });
  }, [inputEmail]);

  // If waiting for email, show an input text box and a button
  //
  if (isWaitingForEmail) {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="text-white p-4">
          New browser detected. Please enter in your email one more time to
          finish sign in.
        </div>
        <input
          className="bg-gray-800 text-white"
          type="email"
          value={inputEmail}
          onChange={(e) => setInputEmail(e.target.value)}
        />
        <button
          className="text-white underline text-lg py-4"
          onClick={onSubmitEmail}
          disabled={inputEmail.length === 0}
        >
          Submit
        </button>
        {error && <div className="text-red-500">{error}</div>}
      </div>
    );
  }

  if (didSignIn) {
    return <Navigate to="/profile" />;
  }
  return (
    <div>
      <div className="text-white animate-pulse">Finishing sign up</div>
      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
}
