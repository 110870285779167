import { useEffect, useRef, useState } from "react";
import TagSelector from "../components/TagSelector";
import { BASE, BASE_TAGS, ETHNICITY, TAGS } from "../zustand/data";
import { ImageWithLink } from "../components/ImageWithLink";
import { useTagStore } from "../zustand/tagStore";
import { submitSearch } from "../firebase/functions";
import { SearchResult } from "../firebase/searchUtils";
import { on } from "events";
import { Link } from "react-router-dom";

const TUTORIAL_TEXT = [
  "Welcome to pornpen.ai! Choose a base tag below to get started. The base tag determines the overall theme of the person you will be generating.",
  "Step 2. Choose some tags to add body features to your base",
  "Step 3. Choose an ethnicity. Then click Generate when you are done!",
  "Step 4. Keep clicking generate to see different results! When you are done, click Finish to move on to the full generator.",
];

export function MakeTutorialScreen() {
  const [isWaiting, setIsWaiting] = useState(false);
  const tagSelectorRef = useRef<HTMLDivElement>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const generateButtonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const onboardingStep = useRef(0);
  const [tagSelectorIndex, setTagSelectorIndex] = useState(0);
  const [showGenerate, setShowGenerate] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const [tutorialText, setTutorialText] = useState(TUTORIAL_TEXT[0]);
  const [imageDetails, setImageDetails] = useState({
    imageUrl: "",
    imageId: "",
  });
  const selectedTags = useTagStore((state) => state.selectedTags);
  const [showFinish, setShowFinish] = useState(false);

  const TAG_SELECTORS = [
    <TagSelector
      showCommunityTags={false}
      showSavedTags={false}
      categoryId="base"
      data={BASE}
      isSingleChoice
      showDefault={true}
      hideAddTagPromo={true}
    />,
    <TagSelector
      showCommunityTags={false}
      showSavedTags={false}
      categoryId="tags"
      data={BASE_TAGS}
      hideAddTagPromo={true}
    />,
    <TagSelector
      showCommunityTags={false}
      showSavedTags={false}
      categoryId="ethnicity"
      data={ETHNICITY}
      isSingleChoice
      hideAddTagPromo={true}
    />,
  ];

  const onClickGenerate = async () => {
    if (onboardingStep.current === 2) {
      onboardingStep.current = 3;
      setTutorialText(TUTORIAL_TEXT[3]);
    }

    setIsWaiting(true);
    setImageDetails({
      imageUrl: "",
      imageId: "",
    });

    let filteredKeys = Object.keys(selectedTags)
      .filter((tag) => tag.indexOf("_default") === -1)
      .filter((tag) => selectedTags[tag]);

    let results = (await submitSearch(
      filteredKeys,
      "all",
      "make"
    )) as SearchResult[];

    if (results.length > 0) {
      setImageDetails({
        imageUrl: results[0].imageUrl,
        imageId: results[0].imageId,
      });
    }

    setIsWaiting(false);
    setShowFinish(true);
  };

  const onClickNext = () => {
    if (onboardingStep.current === 0) {
      onboardingStep.current = 1;
      setTagSelectorIndex(1);
      setTutorialText(TUTORIAL_TEXT[1]);
    } else if (onboardingStep.current === 1) {
      onboardingStep.current = 2;
      setTagSelectorIndex(2);
      setShowGenerate(true);
      setShowNext(false);
      setTutorialText(TUTORIAL_TEXT[2]);
    }
  };

  return (
    <div
      className="text-white text-center max-w-lg relative"
      ref={containerRef}
    >
      <div className="text-xl">Tutorial</div>
      <div className="mb-4">
        <Link to="/make" className="underline">
          Skip Tutorial
        </Link>
      </div>
      {/* <div className="mb-8">Welcome to the pornpen.ai tutorial!</div> */}
      <div className="text-lg mb-4 bg-black p-4 rounded-lg border border-slate-500">
        {tutorialText}
      </div>
      {isWaiting && (
        <div className="animate-pulse md:rounded-2xl w-full md:max-w-lg max-h-[512px] aspect-square bg-zinc-500 flex justify-center items-center flex-col">
          <div className="text-white">Generating...</div>
        </div>
      )}
      {imageDetails.imageUrl && (
        <div>
          <ImageWithLink
            imageUrl={imageDetails.imageUrl}
            imageId={imageDetails.imageId}
            isPrivate={false}
          />
        </div>
      )}
      <div className="max-w-lg" ref={tagSelectorRef}>
        {TAG_SELECTORS[tagSelectorIndex]}
      </div>
      {showNext && (
        <div>
          <button
            className="bg-blue-700 px-4 py-2 text-lg rounded-lg hover:opacity-70"
            onClick={onClickNext}
            ref={nextButtonRef}
          >
            Next
          </button>
        </div>
      )}
      {showGenerate && (
        <div className="sticky bottom-0 p-4 z-40 flex justify-center items-center flex-col pointer-events-none">
          <div className="bg-gray-700/40 p-4 rounded-lg pointer-events-auto backdrop-blur">
            <div className="flex flex-col">
              <button
                onClick={onClickGenerate}
                disabled={isWaiting}
                className="GradientButton text-white px-8 py-4 rounded-lg disabled:opacity-70 mb-4 text-xl transition-transform hover:scale-105 cursor-pointer"
                ref={generateButtonRef}
              >
                Generate
              </button>
              {showFinish && (
                <Link
                  to="/make"
                  className="bg-blue-600 text-white px-8 py-4 rounded-lg disabled:opacity-70 mb-4 text-xl transition-transform hover:scale-105 cursor-pointer font-bold"
                >
                  Finish
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
