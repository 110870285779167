let undoStack = [];
let redoStack = [];

export function undo(c) {
  if (undoStack.length > 0) {
    var ctx = c.getContext("2d");
    var data = undoStack.pop();
    redoStack.push(c.toDataURL());
    var img = new Image();
    img.onload = function () {
      let compositeOperation = ctx.globalCompositeOperation;
      ctx.globalCompositeOperation = "source-over";

      ctx.clearRect(0, 0, c.width, c.height);
      ctx.drawImage(img, 0, 0);

      // Restore the original composition
      ctx.globalCompositeOperation = compositeOperation;
    };
    img.src = data;
  }
}

export function redo(c) {
  if (redoStack.length > 0) {
    var ctx = c.getContext("2d");
    var data = redoStack.pop();
    console.log("redo: pushing to undo stack", data);
    undoStack.push(c.toDataURL());
    var img = new Image();
    img.onload = function () {
      let compositeOperation = ctx.globalCompositeOperation;
      ctx.globalCompositeOperation = "source-over";

      ctx.clearRect(0, 0, c.width, c.height);
      ctx.drawImage(img, 0, 0);

      // Restore the original composition
      ctx.globalCompositeOperation = compositeOperation;
    };
    img.src = data;
  }
}
function midPointBtw(p1, p2) {
  return {
    x: p1.x + (p2.x - p1.x) / 2,
    y: p1.y + (p2.y - p1.y) / 2,
  };
}

export function loadDrawing(c, maskDataUri, options = {}) {
  // Clear undo/redo stack
  undoStack.length = 0;
  redoStack.length = 0;

  var el = c;
  var ctx = el.getContext("2d");
  ctx.lineJoin = "round";
  ctx.lineCap = "round";
  ctx.lineWidth = 40;

  if (options.lineWidth) {
    ctx.lineWidth = options.lineWidth;
  }

  var isDrawing;
  var points = [];

  // Add to undo stack
  undoStack.push(c.toDataURL());
  redoStack.length = 0;

  function onStart(x, y) {
    isDrawing = true;
    const rect = el.getBoundingClientRect();
    let scaleX = el.width / rect.width;
    let scaleY = el.height / rect.height;
    points.push({ x: (x - rect.left) * scaleX, y: (y - rect.top) * scaleY });

    // Add to undo stack
    undoStack.push(c.toDataURL());
    redoStack.length = 0;
  }

  function onMove(x, y) {
    if (!isDrawing) return;
    const rect = el.getBoundingClientRect();
    let scaleX = el.width / rect.width;
    let scaleY = el.height / rect.height;
    var currentPoint = {
      x: (x - rect.left) * scaleX,
      y: (y - rect.top) * scaleY,
    };

    points.push(currentPoint);

    var p1 = points[0];
    var p2 = points[1];

    ctx.beginPath();
    ctx.strokeStyle = "rgba(242, 22, 35, 1)";
    if (options.brushColor) {
      ctx.strokeStyle = options.brushColor;
    }
    ctx.moveTo(p1.x, p1.y);

    for (var i = 1, len = points.length; i < len; i++) {
      // we pick the point between pi+1 & pi+2 as the
      // end point and p1 as our control point
      var midPoint = midPointBtw(p1, p2);
      ctx.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);
      p1 = points[i];
      p2 = points[i + 1];
    }
    // Draw last line as a straight line while
    // we wait for the next point to be able to calculate
    // the bezier control point
    ctx.lineTo(p1.x, p1.y);
    ctx.stroke();
  }

  function onEnd() {
    isDrawing = false;
    points.length = 0;
  }

  if (maskDataUri) {
    var img = new Image();
    img.onload = function () {
      ctx.drawImage(img, 0, 0);
    };
    img.src = maskDataUri;
  }

  // For all of the below, grab the first touch and use clientX
  el.ontouchstart = function (e) {
    const touch = e.touches[0];
    onStart(touch.clientX, touch.clientY);
  };

  el.ontouchmove = function (e) {
    e.preventDefault();
    const touch = e.touches[0];
    onMove(touch.clientX, touch.clientY);
  };

  el.ontouchend = function (e) {
    onEnd();
  };

  el.onmousedown = function (e) {
    onStart(e.clientX, e.clientY);
  };

  el.onmousemove = function (e) {
    onMove(e.clientX, e.clientY);
  };

  el.onmouseup = function () {
    onEnd();
  };

  document.onmouseup = function () {
    onEnd();
  };
}

// If selection is transparent, make it "rgba(242, 22, 35, 1)";
// If its non-transparent, make it transparent
export function invertSelection(c) {
  var ctx = c.getContext("2d");

  // Add to undo stack
  undoStack.push(c.toDataURL());
  redoStack.length = 0;

  var imageData = ctx.getImageData(0, 0, c.width, c.height);
  var data = imageData.data;
  for (var i = 0; i < data.length; i += 4) {
    if (data[i + 3] === 0) {
      data[i] = 242;
      data[i + 1] = 22;
      data[i + 2] = 35;
      data[i + 3] = 255;
    } else {
      data[i + 3] = 0;
    }
  }
  ctx.putImageData(imageData, 0, 0);
}

export function clearCanvas(c) {
  // Add to undo stack
  undoStack.push(c.toDataURL());
  redoStack.length = 0;

  // Accidentally passed ref
  if (c.current) {
    let ctx = c.current.getContext("2d");
    ctx && ctx.clearRect(0, 0, c.current.width, c.current.height);
  }

  // Passed canvas
  if (c.getContext) {
    let ctx = c.getContext("2d");
    ctx && ctx.clearRect(0, 0, c.width, c.height);
  }
}

export function drawToWhiteCanvas(canvas) {
  var newCanvas = canvas.cloneNode(true);
  var ctx = newCanvas.getContext("2d");
  ctx.fillStyle = "#FFF";
  ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);
  ctx.drawImage(canvas, 0, 0);
  return newCanvas
    .toDataURL("image/jpeg")
    .replace(/^data:image\/[a-z]+;base64,/, "");
}

export let lastPaintingInfo = {
  lastMask: "",
  lastTaskId: "",
  lastPromptData: null,
  lastDidInvert: "",
  lastMaskAlpha: "",
  lastColorHint: null,
  lastGenerator: "women_crisp",
  lastPrivate: false,
  lastEnhancePaint: false,
};

export let lastUncropInfo = {
  lastTaskId: "",
  scale: 1,
  lastPromptData: null,
  lastGenerator: "women_crisp",
  lastOffsetX: 0,
  lastOffsetY: 0,
  lastPrivate: false,
  model: "sd15",
};

export let lastSmartEditInfo = {
  lastTaskId: "",
  smartEditId: "",
  lastPromptData: null,
  lastGenerator: "women_crisp",
  lastPrivate: false,
};

export let lastSDXLUpscaleInfo = {
  lastTaskId: "",
  lastPromptData: null,
  lastImageId: "",
  lastPrivate: false,
  lastGenerator: "women_intricate",
};

export function rgbToHex(r, g, b) {
  if (r > 255 || g > 255 || b > 255) {
    return "000000";
  }
  return ((r << 16) | (g << 8) | b).toString(16);
}
