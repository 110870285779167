import { useCallback, useRef, useState } from "react";
import { InfiniteImageList } from "../components/InfiniteImageList";
import { getSavedImages } from "../firebase/functions";
import { useUtilityStore } from "../zustand/utilityStores";
import ScrollToTopOverlay from "../components/ScrollToTopOverlay";
import { MultiMoveModal } from "../components/MultiMoveModal";
import { SearchResult } from "../firebase/searchUtils";
import toast from "react-hot-toast";
import { Datepicker } from "flowbite-react";

export function SavedImagesScreen() {
  const uid = useUtilityStore((state) => state.uid);
  const [isSelectingMode, setIsSelectingMode] = useState(false);
  const [selected, setSelected] = useState<SearchResult[]>([]);
  const [showMultiMoveModal, setShowMultiMoveModal] = useState(false);
  const [key, setKey] = useState("init");
  const startDate = useRef<Date | null>(null);

  const getMore = useCallback(
    (lastDoc: any) => {
      return getSavedImages(uid, lastDoc, startDate.current);
    },
    [uid]
  );

  const onClickMove = useCallback(() => {
    setIsSelectingMode(true);
  }, []);

  const onClickConfirmMove = useCallback(() => {
    if (selected.length === 0) {
      toast.error("No images selected");
      return;
    }

    setShowMultiMoveModal(true);
  }, [selected]);

  return (
    <div>
      <div className="text-center mb-2 text-2xl text-white">Saved Images</div>
      <div className="flex justify-center mb-4">
        {isSelectingMode ? (
          <div className="text-white text-center">
            Choose images 👇 Press shift to select a range <br />
          </div>
        ) : (
          <button
            onClick={onClickMove}
            className="bg-blue-600 text-white py-2 px-4 rounded-lg mr-2"
          >
            Multi-Move
          </button>
        )}
        {!isSelectingMode && (
          <Datepicker
            title="Search Date"
            onSelectedDateChanged={(date) => {
              startDate.current = date;
              setKey(Math.random().toString());
            }}
          />
        )}
      </div>
      {uid && (
        <InfiniteImageList
          key={key}
          emptyEndMessage="You have 0 saved images, time to get started!"
          fullEndMessage="End of list"
          getMore={getMore}
          isSelectingMode={isSelectingMode}
          onSelectionChange={(selected, results) => {
            setSelected(results);
          }}
        />
      )}
      <ScrollToTopOverlay />
      <MultiMoveModal
        showModal={showMultiMoveModal}
        setShowModal={setShowMultiMoveModal}
        images={selected.map((result) => {
          return {
            imageId: result.imageId,
            isPrivate: !!result.isPrivate,
          };
        })}
        refreshList={() => {
          setKey(Math.random().toString());
        }}
      />
      {isSelectingMode && (
        <div className="sticky bottom-0 p-4 z-50 flex justify-center items-center flex-col pointer-events-none">
          <div className="bg-gray-700/40 p-4 rounded-lg pointer-events-auto backdrop-blur">
            <div className="flex flex-row">
              <button
                onClick={onClickConfirmMove}
                className="GradientButton text-white px-8 py-4 rounded-lg disabled:opacity-70 text-xl"
              >
                <span>Move Selected ({selected.length})</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
