export const PRELOAD_MODELS = [
  {
    id: "women_crisp",
    name: "Detailed",
    description: "Good at poses, sex positions. Follows tags",
    thumbnail_url:
      "https://cdn.pornpen.ai/fc27c2ed-be7e-452a-9033-80ecbef0da83.jpg",
    prompt_system: "women",
    sdModel: "sd15",
  },
  {
    id: "women_accurate",
    name: "Accurate",
    description: "Great at following tags, producing variety",
    thumbnail_url:
      "https://cdn.pornpen.ai/3c7804ff-339f-4c1f-b895-244c94cd8eab.jpg",
    prompt_system: "women",
    sdModel: "sd15",
  },
  {
    id: "women_real",
    name: "Realistic",
    description: "Good at faces and hair, skews towards Asian",
    thumbnail_url:
      "https://cdn.pornpen.ai/94ae80f1-dda1-4c3c-8bea-f5ae34785eed.jpg",
    prompt_system: "women",
    sdModel: "sd15",
  },
  {
    id: "women_photography",
    name: "Photography",
    description: "Good at photorealism",
    thumbnail_url:
      "https://cdn.pornpen.ai/9ea387c8-da42-4b8e-9a1c-875988269d52.jpg",
    prompt_system: "women",
    sdModel: "sd15",
  },
  {
    id: "women",
    name: "Legacy",
    description: "The base SD1.5 model, not generally recommended.",
    thumbnail_url:
      "https://cdn.pornpen.ai/977ddbf8-5733-472c-8fb4-0e39151979bc.jpg",
    prompt_system: "women",
    sdModel: "sd15",
  },
  {
    id: "women_hd",
    name: "HD (SDXL)",
    description: "Higher resolution. Good at art and photorealism. Asian skew.",
    thumbnail_url:
      "https://cdn.pornpen.ai/0abaf682-f3e5-4c9e-bf63-65c251f8e34a.jpg",
    prompt_system: "women",
    sdModel: "sdxl",
  },
  {
    id: "women_intricate",
    name: "Intricate (SDXL)",
    description: "Higher resolution. More photorealistic",
    thumbnail_url:
      "https://cdn.pornpen.ai/ab0811a0-1456-4ee0-9a8d-220190305bc2.jpg",
    prompt_system: "women",
    sdModel: "sdxl",
  },
  {
    id: "anime",
    name: "Base",
    description: "Base anime model good at following tags",
    thumbnail_url:
      "https://cdn.pornpen.ai/8d7dabd8-014e-4120-b13e-e440857b7743.jpg",
    prompt_system: "anime",
    sdModel: "sd15",
  },
  {
    id: "anime_detailed",
    name: "Detailed",
    description: "Higher detail anime, powered by Hassaku",
    thumbnail_url:
      "https://cdn.pornpen.ai/a265cfa2-b822-4fe7-9aaa-8171eac390cf.jpg",
    prompt_system: "anime",
    sdModel: "sd15",
  },
  {
    id: "anime_hd",
    name: "HD (SDXL)",
    description: "SDXL based anime model for higher resolutions and quality.",
    thumbnail_url:
      "https://cdn.pornpen.ai/6dc3368d-cc66-41fb-acb7-eccdd8ba406e.jpg",
    prompt_system: "anime",
    sdModel: "sdxl",
  },
  {
    id: "furry",
    name: "Detailed",
    description: "Furry model, powered by Indigo",
    thumbnail_url:
      "https://cdn.pornpen.ai/612cddb3-1e37-4d4d-af8a-967bd083eb17.jpg",
    prompt_system: "furry",
    sdModel: "sd15",
  },
  {
    id: "men",
    name: "Base",
    description: "Base men model good at following tags",
    thumbnail_url:
      "https://cdn.pornpen.ai/16f20678-59bc-404d-8d4d-f7fa06be72df.jpg",
    prompt_system: "men",
    sdModel: "sd15",
  },
  {
    id: "men_detailed",
    name: "Detailed",
    description: "Higher quality gens, but tends to be more feminine",
    thumbnail_url:
      "https://cdn.pornpen.ai/106d5812-8493-4d60-b9cc-75a76f7d364a.jpg",
    prompt_system: "men",
    sdModel: "sd15",
  },
  {
    id: "men_photography",
    name: "Photography",
    description: "Good at photorealism",
    thumbnail_url:
      "https://cdn.pornpen.ai/69c277a5-9fd4-48d6-8f63-e8b8d27b1a19.jpg",
    prompt_system: "men",
    sdModel: "sd15",
  },
  {
    id: "doggystyle",
    name: "Doggystyle",
    description: "Only generates doggystyle poses",
    thumbnail_url:
      "https://cdn.pornpen.ai/17fe17c4-ef65-461b-85e5-a49c2c32517b.jpg",
    prompt_system: "doggystyle",
    sdModel: "sd15",
  },
  {
    id: "blowjob",
    name: "Blowjob",
    description: "Only generates blowjob poses",
    thumbnail_url:
      "https://cdn.pornpen.ai/ae9fe2b0-b553-4d7e-b01f-ce69c7d5c3b7.jpg",
    prompt_system: "blowjob",
    sdModel: "sd15",
  },
  {
    id: "missionary",
    name: "Missionary",
    description: "Only generates missionary poses",
    thumbnail_url:
      "https://cdn.pornpen.ai/c28d36c7-dd05-4287-b13c-2f2d3f23bcf5.jpg",
    prompt_system: "missionary",
    sdModel: "sd15",
  },
  {
    id: "titfuck",
    name: "Titfuck",
    description: "Only generates titfuck poses",
    thumbnail_url:
      "https://cdn.pornpen.ai/36b8e671-65ab-4cc0-a678-791967ce5517.jpg",
    prompt_system: "titfuck",
    sdModel: "sd15",
  },
];
