import { Card } from "flowbite-react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { logPage } from "../analytics/googleAnalytics";
import DatingIcon from "../images/dating_game.png";

export function GamesScreen() {
  useEffect(() => {
    logPage("games");
  }, []);

  return (
    <div className="text-white text-center">
      <div className="text-2xl">Games</div>
      <div className="mb-8">More games coming soon!</div>
      <div className="dark flex pr-2">
        <Link to="/games/instagram">
          <Card
            className="max-w-sm ml-2"
            imgAlt="Meaningful alt text for an image that is not purely decorative"
            imgSrc={
              "https://cdn.pornpen.ai/7cd3333d-5936-4ab9-91b2-c2d349a8917b.jpg"
            }
          >
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              IG Model Simulator
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Roleplay DMing an Instagram model. Maybe she does more than
              modeling?
            </p>
          </Card>
        </Link>
        <Link to="/games/dating">
          <Card
            className="max-w-sm ml-2"
            imgAlt="Meaningful alt text for an image that is not purely decorative"
            imgSrc={DatingIcon}
          >
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Dating App Simulator
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              A virtual dating app where can can swipe left/right and chat!
            </p>
          </Card>
        </Link>
      </div>
    </div>
  );
}
