import { Button, Modal } from "flowbite-react";
import React from "react";
import { useHistoryStore } from "../zustand/historyStore";
import { ModalContainer } from "./ModalContainer";

export const disclaimerKey = "didAcceptDisclaimer2";

const didAccept = localStorage.getItem(disclaimerKey) === "didAccept";
function accept() {
  localStorage.setItem(disclaimerKey, "didAccept");
}

export default function DisclaimerModal() {
  const [show, setShow] = React.useState(!didAccept);
  const setDidAccept = useHistoryStore((state) => state.setDidAccept);

  const handleClose = () => {
    accept();
    setShow(false);
    setDidAccept(true);
  };
  const handleAccept = () => {
    accept();
    setShow(false);
    setDidAccept(true);
  };
  const handleDecline = () => {
    // Redirect to somewhere else
    window.location.href = "https://www.google.com";
  };

  const footer = (
    <>
      <Button onClick={handleAccept}>I accept</Button>
      <Button color="gray" onClick={handleDecline}>
        Decline
      </Button>
    </>
  );

  return (
    <React.Fragment>
      {show && <div className="fixed inset-0 z-50 backdrop-blur-xl"></div>}
      <ModalContainer
        title="Welcome to pornpen.ai"
        showModal={show}
        setShowModal={setShow}
        footer={footer}
      >
        <div className="space-y-6">
          <div className="font-bold text-gray-300">
            Terms have been updated July 27, 2023
          </div>
          <p className="text-red-500">
            Warning, this site is for adults only. It contains AI-generated
            adult imagery.
          </p>
          <p className="text-base leading-relaxed dark:text-gray-100">
            By entering this website, I recognize that I am 18 years old or
            more. By using the site, you agree to our{" "}
            <a className="underline" href="/terms">
              Terms of Service
            </a>
            . Our{" "}
            <a className="underline" href="/privacy">
              privacy policy
            </a>{" "}
            details how we collect and use your data. We use cookies for basic
            analytics and spam detection.
          </p>
          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            Any generations of content on this website that resemble real people
            are purely coincidental.
          </p>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
}
