type WindowGTag = typeof window & {
  gtag: (
    event: "event" | "set",
    event_name: string,
    params?: any
  ) =>
    | void
    | ((
        event: "event",
        event_name: "page_view",
        params: { page_title: string }
      ) => void);
};

const windowGTag = window as WindowGTag;
type Events =
  | "click_more_feed"
  | "click_more_search"
  | "click_tag"
  | "click_generate"
  | "click_clear_filters"
  | "click_prev_feed"
  | "toggle_live_feed"
  | "click_random"
  | "click_generate_edit"
  | "submit_chat";

type Pages =
  | "make"
  | "search"
  | "feed"
  | "about"
  | "image_view"
  | "edit"
  | "profile"
  | "games"
  | "chat";

export function logEvent(eventName: Events) {
  windowGTag.gtag("event", eventName);
}

export function logPro(isPro: boolean) {
  const boolString = isPro ? "true" : "false";

  windowGTag.gtag("set", "user_properties", {
    is_pro: boolString,
  });
}

export function logSignedIn(isSignedIn: boolean) {
  const boolString = isSignedIn ? "true" : "false";

  windowGTag.gtag("set", "user_properties", {
    signed_in: boolString,
  });
}

export function logPage(pageTitle: Pages) {
  windowGTag.gtag("event", "page_view", { page_title: pageTitle });
}
