import { Select } from "flowbite-react";
import { TagStore, useTagStore } from "../zustand/tagStore";
import { useModelStore } from "../zustand/modelStore";
import { FIXED_MODEL_CATEGORIES } from "./GeneratorModal";

export function GeneratorSelect(props: {
  onChangeGenerator?: (generator: TagStore["generator"]) => void;
  onyList?: string[];
  value: TagStore["generator"];
  className?: string;
  hideList?: string[];
  addAllOption?: boolean;
  addGenerators?: string[];
  showPreview?: boolean;
}) {
  let models = useModelStore((state) => state.models);
  const setGenerator = useTagStore((state) => state.setGenerator);

  const onChange = (e: any) => {
    if (props.onChangeGenerator) {
      props.onChangeGenerator(e.target.value);
    } else {
      setGenerator(e.target.value);
    }
  };

  const options = models
    .filter((model) => {
      if (props.onyList) {
        return props.onyList?.indexOf(model.id) !== -1;
      }

      if (props.hideList) {
        // If the model is in the hide list, don't show it
        return props.hideList?.indexOf(model.id) === -1;
      }

      if (model.preview && !props.showPreview) {
        return false;
      }

      return true;
    })
    .map((model) => {
      let categoryPrefix = "";
      if (FIXED_MODEL_CATEGORIES.indexOf(model.prompt_system) !== -1) {
        // Upper case first letter
        categoryPrefix =
          model.prompt_system.charAt(0).toUpperCase() +
          model.prompt_system.slice(1);
        categoryPrefix += ": ";
      }

      return (
        <option key={model.id} value={model.id}>
          {categoryPrefix}
          {model.name}
        </option>
      );
    });

  if (props.addAllOption) {
    options.unshift(
      <option key="all" value="all">
        All
      </option>
    );
  }

  return (
    <div className={"w-48 mb-4 " + props.className || ""}>
      <Select value={props.value} onChange={onChange} required={true}>
        {options}
      </Select>
    </div>
  );
}
