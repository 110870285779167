import { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { InfiniteImageList } from "../components/InfiniteImageList";
import {
  deleteMultiplePrivateImages,
  getPrivateImages,
} from "../firebase/functions";
import { useUtilityStore } from "../zustand/utilityStores";
import { toast } from "react-hot-toast";
import ScrollToTopOverlay from "../components/ScrollToTopOverlay";
import { Datepicker } from "flowbite-react";

export function Private() {
  const uid = useUtilityStore((state) => state.uid);
  const [isSelectingMode, setIsSelectingMode] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [isWaitingDeleting, setIsWaitingDeleting] = useState(false);
  const isPro = useUtilityStore((state) => state.isPro);
  const startDate = useRef<Date | null>(null);
  const [listKey, setListKey] = useState(0);

  const onClickDelete = useCallback(() => {
    setIsSelectingMode(true);
  }, []);

  const onClickConfirmDelete = useCallback(async () => {
    setIsWaitingDeleting(true);
    await deleteMultiplePrivateImages(selected);
    setIsWaitingDeleting(false);

    setIsSelectingMode(false);
    setSelected([]);
    toast("Success! Deletion might take a minute to complete.", {
      duration: 5000,
    });
  }, [selected]);

  return (
    <div>
      <div className="text-center text-white text-2xl">Private Images</div>
      <div className="flex flex-row justify-center text-white text-sm mb-4 flex-wrap">
        <div className="text-center">Only you can see these</div>
        <div className="mx-2">•</div>
        <div>Unlimited storage</div>
        <div className="mx-2">•</div>
        <div className="flex justify-center text-sm">
          <Link to="/terms" className="text-center underline text-white">
            View Licensing
          </Link>
        </div>
      </div>
      <div className="flex justify-center mb-4">
        {isSelectingMode ? (
          <div className="text-white text-center">
            Choose images 👇 Press shift to select a range <br />
            <span className="font-bold text-red-700">
              Warning, THIS WILL DELETE SAVED IMAGES AND IMAGES IN FOLDERS!
            </span>
          </div>
        ) : (
          <button
            onClick={onClickDelete}
            className="bg-red-600 text-white py-2 px-4 rounded-lg mr-2"
          >
            Multi-Delete
          </button>
        )}
        {!isSelectingMode && (
          <Datepicker
            title="Search Date"
            onSelectedDateChanged={(date) => {
              startDate.current = date;
              setListKey((prev) => prev + 1);
            }}
          />
        )}
      </div>
      {!isPro && (
        <div className="text-white text-center">
          Subscribe to pro to generate private images
        </div>
      )}
      {uid && (
        <InfiniteImageList
          emptyEndMessage="Private images not found"
          fullEndMessage="End of list"
          getMore={(lastDoc) => {
            return getPrivateImages(uid, lastDoc, startDate.current);
          }}
          isPrivate
          uid={uid}
          isSelectingMode={isSelectingMode}
          onSelectionChange={(selected) => {
            setSelected(selected);
          }}
          key={listKey}
        />
      )}
      {isSelectingMode && (
        <div className="sticky bottom-0 p-4 z-50 flex justify-center items-center flex-col pointer-events-none">
          <div className="bg-gray-700/40 p-4 rounded-lg pointer-events-auto backdrop-blur">
            <div className="flex flex-row">
              <button
                onClick={onClickConfirmDelete}
                disabled={isWaitingDeleting}
                className="GradientButton text-white px-8 py-4 rounded-lg disabled:opacity-70 text-xl"
              >
                {isWaitingDeleting ? (
                  "Deleting..."
                ) : (
                  <span>Delete Selected ({selected.length})</span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      <ScrollToTopOverlay />
    </div>
  );
}
