import { query, collection, limit, orderBy, getDocs } from "firebase/firestore";
import TinderCard from "react-tinder-card";
import { db } from "../firebase/setupFirebase";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useGameStore } from "../zustand/gameStore";
import GirlNames from "../games/girl_names.json";
import { Link } from "react-router-dom";
import RejectIcon from "../images/reject.svg";
import AcceptIcon from "../images/accept.svg";
import toast from "react-hot-toast";

interface DatingCard {
  name: string;
  age: number;
  imageUrl: string;
  id: string;
}

export function DatingScreen() {
  const [datingCards, setDatingCards] = useState<DatingCard[]>([]);
  const topCardRef = useRef(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const datingMatches = useGameStore((state) => state.datingMatches);
  const addDatingMatch = useGameStore((state) => state.addDatingMatch);
  // Keep track of swiped card ids to prevent duplicates
  const swiped = useRef<string[]>([]);
  const [matchRate, setMatchRate] = useState(100);

  useEffect(() => {
    (async () => {
      let newCards = await getNewCards(10);
      setDatingCards(newCards);
    })();
  }, []);

  const onCardLeftScreen = async (dir: string, myIdentifier: any) => {
    setIsWaiting(true);

    // Remove the card with id from the array
    setDatingCards((prev) => {
      return prev.filter((c) => c.id !== myIdentifier);
    });

    setIsWaiting(false);

    // Rejected
    if (dir === "left") {
      return;
    }

    if (isWaiting) {
      return;
    }

    if (swiped.current.includes(myIdentifier)) {
      return;
    }

    swiped.current.push(myIdentifier);

    const match = datingCards.find((c) => c.id === myIdentifier);

    if (match && Math.random() * 100 < matchRate) {
      // Add to dating matches
      addDatingMatch({
        id: myIdentifier,
        image_url: match.imageUrl,
        name: match.name,
      });

      toast.success("You matched with " + match.name);
    }

    if (datingCards.length > 4) {
      return;
    }
  };

  useEffect(() => {
    if (datingCards.length > 5) {
      return;
    }

    async function getMoreCards() {
      setIsWaiting(true);
      let newCards = await getNewCards(20);

      // Add new cards to the beginning of the array
      setDatingCards((prev) => {
        let newCombined = [...prev];
        newCards.forEach((card) => {
          if (!newCombined.find((c) => c.id === card.id)) {
            // Append to beginning of array
            newCombined = [card, ...newCombined];
          }
        });

        return newCombined;
      });

      setIsWaiting(false);
    }

    getMoreCards();
  }, [datingCards]);

  const getNewCards = async (maxResults: number) => {
    let q = query(
      collection(db, "results"),
      limit(maxResults),
      orderBy("create_date", "desc")
    );

    let querySnapshot = await getDocs(q);
    let docs = querySnapshot.docs;

    // Filter out all docs where doc.data().generator includes "men"
    docs = docs.filter((doc) => {
      const data = doc.data();

      if (
        data.generator === "men_detailed" ||
        data.generator === "men" ||
        data.generator === "men_photography"
      ) {
        return false;
      }

      if (data.edited_from) {
        return false;
      }

      return true;
    });

    if (docs.length > 0) {
      return docs.map((doc) => {
        const data = doc.data();
        let age = 25;

        // Find key in data that starts with age_
        let ageKey = Object.keys(data).find((key) => key.startsWith("age_"));

        if (ageKey) {
          // Remove age_ from the key
          ageKey = ageKey.replace("age_", "");
          age = parseInt(ageKey);
        }

        return {
          name: GirlNames.names[
            Math.floor(Math.random() * GirlNames.names.length)
          ],
          age,
          imageUrl: data.image_url,
          id: doc.id,
        };
      });
    }

    return [];
  };

  const swipeTopCard = (dir: string) => {
    if (isWaiting) {
      return;
    }

    if (topCardRef.current) {
      // @ts-ignore
      topCardRef.current.swipe(dir);
    }
  };

  return (
    <div className="overflow-x-hidden w-screen max-w-[512px] min-h-screen">
      <div className="text-white flex justify-center mb-2">
        <div className="bg-pink-600 px-4 py-2 rounded-full font-bold">
          Dating App Simulator
        </div>
      </div>
      <div className="relative aspect-square">
        {datingCards.map((card, index) => (
          <TinderCard
            key={card.id}
            onCardLeftScreen={(dir) => {
              onCardLeftScreen(dir, card.id);
            }}
            preventSwipe={["up", "down"]}
            className={classNames("text-white absolute w-full top-0 left-0", {
              hidden: false,
            })}
            ref={index === datingCards.length - 1 ? topCardRef : null}
          >
            <div
              style={{
                backgroundImage: `url('${card.imageUrl}')`,
              }}
              className="aspect-square bg-contain flex items-end text-lg rounded-md cursor-pointer select-none"
            >
              <div className="font-bold px-2">{card.name}</div>
              <div>{card.age}</div>
            </div>
          </TinderCard>
        ))}
      </div>
      <div className="text-white flex justify-between px-16 mt-4">
        <button
          onClick={() => {
            swipeTopCard("left");
          }}
          className="w-8 h-8 rounded-full disabled:opacity-30"
          disabled={isWaiting}
        >
          <img src={RejectIcon} alt="Reject" />
        </button>
        <button
          onClick={() => {
            swipeTopCard("right");
          }}
          className="w-8 h-8 rounded-full disabled:opacity-30"
          disabled={isWaiting}
        >
          <img src={AcceptIcon} alt="Match" />
        </button>
      </div>
      <div className="text-white text-center mt-4">Match Rate</div>
      <input
        id="default-range"
        type="range"
        min="0"
        max="100"
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
        value={matchRate}
        onChange={(e) => {
          setMatchRate(parseInt(e.target.value));
        }}
      />
      <div className="text-white font-bold text-center mt-4">Matches</div>
      {datingMatches.map((match) => (
        <Link
          key={match.id}
          className="flex items-center text-white mb-4"
          to={`/chat/with/${match.id}?name=${match.name}&context=dating`}
        >
          <img
            src={match.image_url}
            alt="Match"
            className="w-16 h-16 rounded-full mr-4"
          />
          <div className="text-2xl">{match.name}</div>
        </Link>
      ))}
    </div>
  );
}
