import React, { useState, useEffect } from "react";

const ScrollToTopOverlay = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.scrollY > window.innerHeight) {
      setShowScroll(true);
    } else if (showScroll && window.scrollY <= window.innerHeight) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, []);

  if (!showScroll) {
    return null;
  }

  return (
    <button
      className="fixed right-2 bottom-2 rounded-md text-white bg-blue-500/30 px-4 py-2"
      onClick={scrollTop}
    >
      Scroll to top
    </button>
  );
};

export default ScrollToTopOverlay;
