import { useEffect, useRef, useState } from "react";
import { useUtilityStore } from "../zustand/utilityStores";
import {
  collection,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { SearchResult } from "../firebase/searchUtils";
import { db } from "../firebase/setupFirebase";

export function ImageOrLoader(props: {
  taskId: string;
  containerClassname?: string;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const uid = useUtilityStore((state) => state.uid);
  const [result, setResult] = useState<SearchResult | null>(null);
  const didFind = useRef(false);

  useEffect(() => {
    if (!props.taskId || !uid) {
      return;
    }

    const collectionRef = collection(db, "users", uid, "private");

    const q = query(
      collectionRef,
      where("task_id", "==", props.taskId),
      limit(10)
    );

    return onSnapshot(q, (querySnapshot) => {
      let imageResults: SearchResult[] = [];

      querySnapshot.forEach((doc) => {
        if (didFind.current) {
          return;
        }

        didFind.current = true;

        let data = doc.data();
        if (data.error) {
          return;
        }

        if (!data.image_url || !data.task_id) {
          return;
        }

        imageResults.push({
          imageUrl: data.image_url,
          imageId: doc.id,
        });
      });
      if (imageResults.length > 0) {
        setIsLoading(false);
        setResult(imageResults[0]);
      }
    });
  }, [props.taskId, uid]);

  let className = "m-auto max-w-sm sm:max-w-lg";

  if (props.containerClassname) {
    className = props.containerClassname;
  }

  if (isLoading) {
    return (
      <div className={className}>
        <div className="animate-pulse bg-gray-300 w-full aspect-square rounded-lg flex justify-center items-center">
          loading...
        </div>
      </div>
    );
  }

  if (result) {
    return (
      <div className={className}>
        <img className="rounded-lg" src={result.imageUrl} alt="Results" />
      </div>
    );
  }

  return null;
}
