import create from "zustand";
import produce from "immer";
import { Tag } from "../common/tagDefinitions";
import { GENERATOR_TO_STRING } from "../utils/strings";

export let tagIdToDataMap: { [key: string]: Tag } = {};

export interface TagStore {
  selectedTags: { [key: string]: boolean };
  toggleTag: (tag: string) => void;
  setTag: (tag: string, value: boolean) => void;
  clearTags: () => void;
  generator: keyof typeof GENERATOR_TO_STRING;
  setGenerator: (generator: string) => void;
  studioTags: Tag[];
  setStudioTags: (tags: Tag[]) => void;
  appendStudioTags: (tags: Tag[]) => void;
  liveTags: Tag[];
  setLiveTags: (tags: Tag[]) => void;
  tagIdToDataMap: { [key: string]: Tag };
  recentTags: Tag[];
  addRecentTag: (tag: Tag) => void;
}

export const useTagStore = create<TagStore>((set) => ({
  selectedTags: {},
  toggleTag: (tag) => {
    set(
      produce((draft) => {
        draft.selectedTags[tag] = !draft.selectedTags[tag];
      })
    );
  },
  setTag: (tag: string, value: boolean) => {
    set(
      produce((draft) => {
        draft.selectedTags[tag] = value;
      })
    );
  },
  clearTags: () => {
    set(
      produce((draft) => {
        Object.keys(draft.selectedTags).forEach((key) => {
          draft.selectedTags[key] = false;
        });
      })
    );
  },
  generator: "women_photography",
  setGenerator: (generator: string) => {
    set(
      produce((draft) => {
        draft.generator = generator;
      })
    );
  },
  studioTags: [],
  setStudioTags: (tags: Tag[]) => {
    set(
      produce((draft) => {
        draft.studioTags = tags;

        // Update map
        tagIdToDataMap = {};
        tags.forEach((tag) => {
          tagIdToDataMap[tag.id] = tag;
        });
      })
    );
  },
  appendStudioTags: (tags: Tag[]) => {
    set(
      produce((draft) => {
        draft.studioTags = [...draft.studioTags, ...tags];

        // Update map
        tags.forEach((tag) => {
          tagIdToDataMap[tag.id] = tag;
        });
      })
    );
  },
  liveTags: [],
  setLiveTags: (tags: Tag[]) => {
    set(
      produce((draft) => {
        draft.liveTags = tags;

        // Update map
        for (const tag of tags) {
          draft.tagIdToDataMap[tag.id] = tag;
        }
      })
    );
  },
  tagIdToDataMap: {},
  recentTags: [],
  addRecentTag: (tag: Tag) => {
    set(
      produce((draft) => {
        // Filter out duplicates
        draft.recentTags = draft.recentTags.filter((t: Tag) => t.id !== tag.id);

        // Add to front
        draft.recentTags = [tag, ...draft.recentTags];
      })
    );
  },
}));

export function getNumSelected(selectedTags: { [key: string]: boolean }) {
  return Object.keys(selectedTags)
    .filter((key) => key.indexOf("_default") === -1)
    .filter((key) => selectedTags[key]).length;
}

export function getNumSelectedText(numSelected: number) {
  if (numSelected === 0) {
    return "";
  }

  return `${numSelected}`;
}
