import { Html, OrbitControls, useProgress } from "@react-three/drei";
import { Canvas, useThree } from "@react-three/fiber";
import React, { Suspense, useState } from "react";
import { useEffect, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useAnimations } from "@react-three/drei";
import { Select } from "flowbite-react";
import { HexColorPicker } from "react-colorful";
export let isTouchDevice = require("is-touch-device");

export let getScreenshotDirector: () => string;

const poses = ["arms_on_hip", "tpose", "squat", "bend_over", "standing_shy"];

export function DirectorScreen() {
  const [pose, setPose] = useState<string>(poses[0]);
  const [color, setColor] = useState("#ffffff");
  const [isColorPicking, setIsColorPicking] = useState(false);

  const options = poses.map((pose) => {
    return (
      <option value={pose} key={pose}>
        {pose}
      </option>
    );
  });

  return (
    <div className="rounded-2xl visible w-full md:max-w-lg md:justify-center md:items-center mb-4">
      <Canvas className="border mb-2 aspect-square">
        <color attach="background" args={[color]} />
        <Suspense fallback={<Loader />}>
          <Renderer pose={pose} backgroundColor={color} />
        </Suspense>
      </Canvas>
      <div className="text-white text-center my-2">
        {isTouchDevice() ? (
          <div>Drag to rotate, pinch to zoom, two fingers to move.</div>
        ) : (
          <div>Drag to rotate, scroll to zoom, shift + click to move.</div>
        )}
      </div>
      <div className="flex justify-center items-center">
        <Select
          value={pose as any} // @ts-ignore
          onChange={(e) => {
            setPose(e.target.value);
          }}
          required={true}
        >
          {options}
        </Select>
        <div
          className="ml-4 rounded-md w-8 aspect-square cursor-pointer"
          style={{ backgroundColor: color }}
          onClick={() => {
            setIsColorPicking(!isColorPicking);
          }}
        ></div>
      </div>
      {isColorPicking && (
        <div className="flex justify-center my-4">
          <HexColorPicker color={color} onChange={setColor} />
        </div>
      )}
    </div>
  );
}

export function Renderer(props: { pose?: string; backgroundColor?: string }) {
  // const fbx = useFBX("/models/model.fbx");
  // const fbxRef = useRef<Group>();
  const { camera, controls, gl, scene } = useThree();
  // const gltf = useLoader(GLTFLoader, "/models/model.gltf");

  const getScreenshot = () => {
    gl.render(scene, camera);
    return gl.domElement.toDataURL();
  };

  getScreenshotDirector = getScreenshot;

  // Assign the fbx model to the ref after it has been loaded
  // useEffect(() => {
  //   gltf.scene.traverse((child: any) => {
  //     console.log(child);
  //   });

  //   console.log(camera);
  // }, [gltf, camera]);

  // useFrame(() => {
  //   if (gltf.scene) {
  //     gltf.scene.traverse((child: any) => {
  //       if (child.name === "CC_Base_R_Thigh") {
  //         child.rotation.x += 0.01;
  //       }
  //     });
  //   }
  // });

  return (
    <React.Fragment>
      <ambientLight intensity={1} color={props.backgroundColor || "#ffffff"} />
      <pointLight
        position={[10, 10, 10]}
        color={props.backgroundColor || "#ffffff"}
      />
      <pointLight position={[10, -10, -10]} />
      <group>
        <Suspense fallback={<Loader></Loader>}>
          <Model pose={props.pose} />
        </Suspense>
      </group>
      <OrbitControls target={[0, 1, 0]} />
      {/* <mesh position={[0, 1, 0]} scale={[1, 1, 1]}>
        <boxGeometry />
        <meshStandardMaterial />
      </mesh> */}
    </React.Fragment>
  );
}

function Loader() {
  const { progress } = useProgress();
  return (
    <Html center className="text-black">
      {progress} % loaded
    </Html>
  );
}

export function Model(props: { pose?: string }) {
  const group = useRef<any>();
  // @ts-ignore
  const { nodes, materials, animations } = useGLTF("/models/model4.gltf");
  const { actions } = useAnimations(animations, group);

  // useEffect(() => {
  //   console.log(actions);
  //   actions["standing_shy"]?.play();
  // }, [actions]);

  // When pose changes, play the animation
  useEffect(() => {
    // actions[props.pose as string]?.play();
    // Reset all animations and then play

    // Reset all actions
    Object.keys(actions).forEach((key) => {
      actions[key]?.stop();
      actions[key]?.reset();
    });

    actions[props.pose as string]?.play();
  }, [props.pose, actions]);

  return (
    <group ref={group} dispose={null}>
      <group name="Scene">
        <group name="Armature" scale={0.01}>
          <primitive object={nodes.CC_Base_BoneRoot} />
          <group name="CC_Base_Eye">
            <skinnedMesh
              name="CC_Base_Eye_1"
              geometry={nodes.CC_Base_Eye_1.geometry}
              material={materials.Std_Eye_R}
              skeleton={nodes.CC_Base_Eye_1.skeleton}
            />
            <skinnedMesh
              name="CC_Base_Eye_2"
              geometry={nodes.CC_Base_Eye_2.geometry}
              material={materials.Std_Cornea_R}
              skeleton={nodes.CC_Base_Eye_2.skeleton}
            />
            <skinnedMesh
              name="CC_Base_Eye_3"
              geometry={nodes.CC_Base_Eye_3.geometry}
              material={materials.Std_Eye_L}
              skeleton={nodes.CC_Base_Eye_3.skeleton}
            />
            <skinnedMesh
              name="CC_Base_Eye_4"
              geometry={nodes.CC_Base_Eye_4.geometry}
              material={materials.Std_Cornea_L}
              skeleton={nodes.CC_Base_Eye_4.skeleton}
            />
          </group>
          <group name="CC_Base_Teeth">
            <skinnedMesh
              name="CC_Base_Teeth_1"
              geometry={nodes.CC_Base_Teeth_1.geometry}
              material={materials.Std_Upper_Teeth}
              skeleton={nodes.CC_Base_Teeth_1.skeleton}
            />
            <skinnedMesh
              name="CC_Base_Teeth_2"
              geometry={nodes.CC_Base_Teeth_2.geometry}
              material={materials.Std_Lower_Teeth}
              skeleton={nodes.CC_Base_Teeth_2.skeleton}
            />
          </group>
          <group name="CC_Base_EyeOcclusion">
            <skinnedMesh
              name="CC_Base_EyeOcclusion_1"
              geometry={nodes.CC_Base_EyeOcclusion_1.geometry}
              material={materials.Std_Eye_Occlusion_R}
              skeleton={nodes.CC_Base_EyeOcclusion_1.skeleton}
              morphTargetDictionary={
                nodes.CC_Base_EyeOcclusion_1.morphTargetDictionary
              }
              morphTargetInfluences={
                nodes.CC_Base_EyeOcclusion_1.morphTargetInfluences
              }
            />
            <skinnedMesh
              name="CC_Base_EyeOcclusion_2"
              geometry={nodes.CC_Base_EyeOcclusion_2.geometry}
              material={materials.Std_Eye_Occlusion_L}
              skeleton={nodes.CC_Base_EyeOcclusion_2.skeleton}
              morphTargetDictionary={
                nodes.CC_Base_EyeOcclusion_2.morphTargetDictionary
              }
              morphTargetInfluences={
                nodes.CC_Base_EyeOcclusion_2.morphTargetInfluences
              }
            />
          </group>
          <group name="CC_Base_TearLine">
            <skinnedMesh
              name="CC_Base_TearLine_1"
              geometry={nodes.CC_Base_TearLine_1.geometry}
              material={materials.Std_Tearline_R}
              skeleton={nodes.CC_Base_TearLine_1.skeleton}
              morphTargetDictionary={
                nodes.CC_Base_TearLine_1.morphTargetDictionary
              }
              morphTargetInfluences={
                nodes.CC_Base_TearLine_1.morphTargetInfluences
              }
            />
            <skinnedMesh
              name="CC_Base_TearLine_2"
              geometry={nodes.CC_Base_TearLine_2.geometry}
              material={materials.Std_Tearline_L}
              skeleton={nodes.CC_Base_TearLine_2.skeleton}
              morphTargetDictionary={
                nodes.CC_Base_TearLine_2.morphTargetDictionary
              }
              morphTargetInfluences={
                nodes.CC_Base_TearLine_2.morphTargetInfluences
              }
            />
          </group>
          <skinnedMesh
            name="CC_Base_Tongue"
            geometry={nodes.CC_Base_Tongue.geometry}
            material={materials.Std_Tongue}
            skeleton={nodes.CC_Base_Tongue.skeleton}
            morphTargetDictionary={nodes.CC_Base_Tongue.morphTargetDictionary}
            morphTargetInfluences={nodes.CC_Base_Tongue.morphTargetInfluences}
          />
          <group name="CC_Base_Body">
            <skinnedMesh
              name="CC_Base_Body_1"
              geometry={nodes.CC_Base_Body_1.geometry}
              material={materials.Std_Skin_Head}
              skeleton={nodes.CC_Base_Body_1.skeleton}
              morphTargetDictionary={nodes.CC_Base_Body_1.morphTargetDictionary}
              morphTargetInfluences={nodes.CC_Base_Body_1.morphTargetInfluences}
            />
            <skinnedMesh
              name="CC_Base_Body_2"
              geometry={nodes.CC_Base_Body_2.geometry}
              material={materials.Std_Skin_Body}
              skeleton={nodes.CC_Base_Body_2.skeleton}
              morphTargetDictionary={nodes.CC_Base_Body_2.morphTargetDictionary}
              morphTargetInfluences={nodes.CC_Base_Body_2.morphTargetInfluences}
            />
            <skinnedMesh
              name="CC_Base_Body_3"
              geometry={nodes.CC_Base_Body_3.geometry}
              material={materials.Std_Skin_Arm}
              skeleton={nodes.CC_Base_Body_3.skeleton}
              morphTargetDictionary={nodes.CC_Base_Body_3.morphTargetDictionary}
              morphTargetInfluences={nodes.CC_Base_Body_3.morphTargetInfluences}
            />
            <skinnedMesh
              name="CC_Base_Body_4"
              geometry={nodes.CC_Base_Body_4.geometry}
              material={materials.Std_Skin_Leg}
              skeleton={nodes.CC_Base_Body_4.skeleton}
              morphTargetDictionary={nodes.CC_Base_Body_4.morphTargetDictionary}
              morphTargetInfluences={nodes.CC_Base_Body_4.morphTargetInfluences}
            />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/model4.gltf");
