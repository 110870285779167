import { Modal } from "flowbite-react";
import React, { useCallback } from "react";
import { deleteAccount } from "../firebase/functions";
import { useUtilityStore } from "../zustand/utilityStores";
import { Link } from "react-router-dom";

export function DeleteAccountModal(props: {
  showModal?: boolean;
  setShowModal: (disableModal: boolean) => void;
}) {
  const [isWaiting, setIsWaiting] = React.useState(false);
  // Stage 0 is first confirmation, stage 1 is second confirmation
  const [stage, setStage] = React.useState(0);
  const isPro = useUtilityStore((state) => state.isPro);

  const handleClose = () => {
    props.setShowModal(false);
  };

  const onClickDelete = useCallback(async () => {
    if (stage === 0) {
      setStage(1);
      return;
    }

    setIsWaiting(true);
    try {
      await deleteAccount();
    } catch (e) {
      console.error(e);
    }
    setIsWaiting(false);

    // Reload to /
    window.location.href = "/";
  }, [stage]);

  return (
    <React.Fragment>
      <Modal show={props.showModal} onClose={handleClose} dismissible>
        <Modal.Header>Delete Account</Modal.Header>
        <Modal.Body>
          {isPro && (
            <div className="text-red-600 font-bold text-2xl">
              Warning: You still have an active membership. We are unable to
              cancel your membership automatically. Please{" "}
              <Link className="underline" to="/subscriber">
                cancel your membership
              </Link>{" "}
              first before deleting your account.
            </div>
          )}
          <div>
            Warning, you are about to delete your account! Are you sure you want
            to proceed? (This action cannot be undone)
          </div>
          <br />
          <div className="font-bold">Data that will be deleted:</div>
          <div>
            <ul className="list-disc list-inside">
              <li>Saved Images</li>
              <li>Private Images</li>
              <li>Folders</li>
              <li>Saved tags</li>
              <li>Your email</li>
              <li>Account metadata</li>
            </ul>
          </div>
          <br />
          <div>
            Note: Public images that you created will not be deleted, since they
            are not associated/linked to your account. There is no way of
            determining who made a public image.
          </div>
          <div className="flex justify-center mt-4">
            <button
              onClick={onClickDelete}
              disabled={isWaiting}
              className="px-4 py-2 text-white bg-red-700 rounded-lg hover:opacity-70 disabled:opacity-30 disabled:animate-pulse"
            >
              {stage === 0
                ? "Delete Account"
                : "Are you sure? Click again to delete."}
            </button>
          </div>
          <div className="text-sm text-center mt-4">
            This action might take a few minutes if you have thousands of
            images. You can close this page after clicking Delete Account and
            the account deletion will continue.
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
