import { useEffect, useMemo, useState } from "react";
import { InfiniteImageList } from "../components/InfiniteImageList";
import { getImagesInFolder } from "../firebase/functions";
import { SearchResult } from "../firebase/searchUtils";
import { useMatch } from "react-router-dom";
import { useUtilityStore } from "../zustand/utilityStores";
import { UpdateFolderModal } from "../components/UpdateFolderModal";
import ScrollToTopOverlay from "../components/ScrollToTopOverlay";

export function FolderViewScreen() {
  let match = useMatch("/folder/:folderId");
  let folderId = match?.params.folderId;
  const folders = useUtilityStore((state) => state.folders);
  const [showModal, setShowModal] = useState(false);

  const name = useMemo(() => {
    let folder = folders.find((folder) => folder.id === folderId);
    return folder?.name || "Folder";
  }, [folderId, folders]);

  const thumbUrl = useMemo(() => {
    let folder = folders.find((folder) => folder.id === folderId);
    return folder?.thumbnail_url || "";
  }, [folderId, folders]);

  const getMore = async (lastDoc: any) => {
    if (!folderId) {
      return [[], {}, true];
    }

    let results: SearchResult[] = [];
    let images = await getImagesInFolder(folderId, lastDoc);

    images.forEach((image: any) => {
      results.push({
        imageUrl: image.image_url,
        imageId: image.image_id,
        isPrivate: image.is_private,
      });
    });

    const isEnd = images.length < 20;
    let lastDate: any = null;

    if (images.length > 0) {
      lastDate = images[images.length - 1].create_date || null;
    }

    return [results, lastDate, isEnd];
  };

  return (
    <div className="text-white w-full">
      <div className="text-2xl text-center">{name}</div>
      <div
        className="text-center underline mb-4 cursor-pointer"
        onClick={() => setShowModal(true)}
      >
        Settings
      </div>
      <InfiniteImageList
        emptyEndMessage="This folder is empty"
        fullEndMessage="End of list"
        getMore={getMore as any}
        skipCheck
      />
      <UpdateFolderModal
        showModal={showModal}
        setShowModal={setShowModal}
        name={name}
        folderId={folderId || ""}
        thumbUrl={thumbUrl}
      />
      <ScrollToTopOverlay />
    </div>
  );
}
