import { useState, useRef, FormEvent, useEffect } from "react";

import "./ChatScreen.css";
import { sendPrompt, submitChatMetadata } from "../firebase/functions";
import classNames from "classnames";
import React from "react";
import { useUtilityStore } from "../zustand/utilityStores";
import { Link, useMatch, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { logEvent, logPage } from "../analytics/googleAnalytics";
import { ImageOrLoader } from "../components/ImageOrLoader";
import { useQuery } from "react-query";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/setupFirebase";

const FLAGGED_WORDS = [
  "age",
  "old",
  "child",
  "children",
  "kid",
  "young",
  "minor",
  "17",
  "16",
  "15",
  "14",
  "13",
  "12",
  "11",
  "years",
  "teen",
  "juvenile",
  "baby",
];

interface ChatMessage {
  message: string;
  time: string;
  isBot: boolean;
  imageTaskId?: string;
}

function getDate() {
  const now = new Date();

  const options: any = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const timeString = now.toLocaleTimeString("en-US", options);
  return timeString;
}

export function ChatScreen() {
  const [isWaiting, setIsWaiting] = useState(false);
  const [input, setInput] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messageListRef = useRef<HTMLDivElement>(null);
  const isPro = useUtilityStore((state) => state.isPro);
  const didFetchAuth = useUtilityStore((state) => state.didFetchSubscriberInfo);
  const signInFetched = useUtilityStore((state) => state.signInFetched);
  const isSignedIn = useUtilityStore((state) => state.isSignedIn);
  const navigate = useNavigate();
  let match = useMatch("/chat/with/:chatId");
  let chatId = match?.params.chatId;
  // Look for query parameter called ?name
  const name = new URLSearchParams(window.location.search).get("name");
  // Look for query parameter called ?context
  const context = new URLSearchParams(window.location.search).get("context");
  const imageUrlQuery = useQuery(
    ["profileImage", chatId || ""],
    ({ queryKey }) => {
      return getDoc(doc(db, "results", queryKey[1])).then((doc) => {
        if (doc.exists()) {
          return doc.data().image_url;
        }
      });
    },
    {
      enabled: !!chatId,
    }
  );

  useEffect(() => {
    logPage("chat");
  }, []);

  useEffect(() => {
    if (signInFetched && !isSignedIn) {
      navigate("/profile");
      toast.error("You must be signed in to chat.");
    }
  }, [signInFetched, isSignedIn]);

  const [messages, setMessages] = useState<ChatMessage[]>(
    chatId
      ? []
      : [
          {
            message: "hiii, thanks for DMing me 😘",
            time: getDate(),
            isBot: true,
          },
        ]
  );

  const isInputFlagged = () => {
    if (input === "") {
      return false;
    }

    if (FLAGGED_WORDS.some((word) => input.includes(word))) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const newMessage = {
      message: input,
      time: getDate,
      isBot: false,
    };
    const newMessages = [...messages, newMessage];

    // Add user message to chat
    setMessages((messages) => [
      ...messages,
      {
        message: input,
        time: getDate(),
        isBot: false,
      },
    ]);

    setInput("");
    setIsWaiting(true);
    const apiMessages = newMessages.map((message) => {
      return {
        content: message.message,
        role: message.isBot ? "assistant" : "user",
      };
    });

    try {
      let options = {};

      if (context === "dating") {
        options = {
          disableImage: true,
          resultId: chatId,
          context: "dating",
          forceName: name,
        };
      }

      const res = await submitChatMetadata(apiMessages, options);
      let taskId: string;

      if (res.isImage) {
        let clothingTags: string[] = [];

        if (res.tags && res.tags.length > 0) {
          clothingTags = res.tags;
        }

        let data: any = {
          prompt: {
            CHARACTER: ["character_cindy"],
            TAGS: ["tags_huge_boobs"],
            CLOTHING: clothingTags,
          },
          generator: "women_photography",
          isPrivate: true,
          aspectRatio: "1:1",
          limitGens: true,
        };

        let result = await sendPrompt(data);
        if (result.id) {
          taskId = result.id;
        }
      }

      // Add bot message to chat
      setMessages((messages) => [
        ...messages,
        {
          message: res.output,
          time: new Date().toLocaleTimeString(),
          isBot: true,
          imageTaskId: taskId,
        },
      ]);
    } catch (e) {
      console.error(e);
      toast.error("Error sending message. Please try again.");
    }

    setIsWaiting(false);
    logEvent("submit_chat");
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [messages]);

  let renderMessages = messages.map((message, idx) => {
    if (message.isBot) {
      return (
        <BotMessage
          message={message.message}
          time={message.time}
          key={idx}
          imageTaskId={message.imageTaskId}
          name={name ? name : "Cindy"}
        />
      );
    } else {
      return (
        <UserMessage
          message={message.message}
          time={message.time}
          key={idx}
          name="You"
        />
      );
    }
  });

  if (!didFetchAuth) {
    return null;
  }

  if (!isPro) {
    return (
      <div className="flex flex-col items-center justify-center p-4">
        <div className="text-white text-center">
          <h1>Subscribe to Pro to Chat and play Games!</h1>
          <Link
            to="/getpro"
            className="underline text-yellow-400 font-bold text-3xl"
          >
            Get Pro
          </Link>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="bg-slate-700 px-4 py-2 rounded-lg mb-2 text-sm text-center">
        Chats are completely private. Chat history is NOT stored on our servers.
      </div>
      <div className="w-full flex flex-col items-center justify-center h-[550px] overflow-scroll">
        <section className="msger h-full">
          <header className="msger-header">
            <Link
              className="msger-header-title flex flex-row items-center text-xl"
              to={chatId ? `/view/${chatId}` : "./"}
            >
              <img
                src={
                  chatId
                    ? imageUrlQuery.data
                    : "https://cdn.pornpen.ai/b28fd4c4-c2cc-47ed-9e36-498168fc411b.jpg"
                }
                className={"msger-header-pic mr-4 w-16"}
                alt=""
              />
              {name ? name : "Cindy"}
            </Link>
          </header>

          <main className="msger-chat" ref={messageListRef}>
            {renderMessages}
            {isWaiting && <div className="dot-flashing ml-4 mb-4"></div>}
            <div key="end" ref={messagesEndRef}></div>
          </main>

          <form className="msger-inputarea" onSubmit={handleSubmit}>
            <input
              type="text"
              className="msger-input"
              placeholder="Enter your message..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <button
              type="submit"
              className="msger-send-btn disabled:opacity-30"
              disabled={isWaiting || isInputFlagged()}
            >
              Send
            </button>
          </form>
        </section>
      </div>
      <div
        className={classNames("text-red-500 text-center mt-2", {
          "opacity-0": !isInputFlagged(),
        })}
      >
        <p>
          <strong>Warning:</strong> Your message contains flagged words.
          <div className="mb-8">
            Words:{" "}
            {FLAGGED_WORDS.filter((word) => input.includes(word)).join(", ")}
          </div>
          <div className="font-bold">
            Abuse of Chat will result in a permanent Chat ban.
          </div>
        </p>
      </div>
    </React.Fragment>
  );
}

interface MessageProps {
  message: string;
  time?: string;
  imageTaskId?: string;
  name: string;
}

function BotMessage(props: MessageProps) {
  return (
    <div className="msg left-msg">
      <div className="msg-bubble">
        <div className="msg-info">
          <div className="msg-info-name">{props.name}</div>
          <div className="msg-info-time">{props.time}</div>
        </div>

        <div className="msg-text">{props.message}</div>
        {props.imageTaskId && (
          <ImageOrLoader
            taskId={props.imageTaskId}
            containerClassname="rounded-lg w-64 mt-2"
          />
        )}
      </div>
    </div>
  );
}

function UserMessage(props: MessageProps) {
  return (
    <div className="msg right-msg">
      <div className="msg-bubble">
        <div className="msg-info">
          <div className="msg-info-name">{props.name}</div>
          <div className="msg-info-time">{props.time}</div>
        </div>

        <div className="msg-text">{props.message}</div>
      </div>
    </div>
  );
}
