const lz = require("lz-string");

export function generateTagURL(
  generator: string,
  tags: string[],
  isRelative?: boolean,
  animated?: boolean
) {
  let data = `generator=${generator}&tags=${tags.join(",")}`;
  const compressed = lz.compressToEncodedURIComponent(data);
  let relative = `/make?tags=${compressed}`;

  if (animated) {
    relative += "&animated=true";
  }

  if (isRelative) {
    return relative;
  }

  const url = `${window.location.origin}${relative}`;
  return url;
}
