import React from "react";
import { Link } from "react-router-dom";
import { CATEGORY_ID_TO_STRING } from "../common/categoryDefinitions";
import { addTag } from "../firebase/functions";
import { ModalContainer } from "./ModalContainer";

export function TagModal(props: {
  showModal?: boolean;
  setShowModal: (disableModal: boolean) => void;
  prompt: string;
  categoryId: string;
}) {
  const [tagName, setTagName] = React.useState<string>("");
  const [isWaiting, setIsWaiting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [saved, setSaved] = React.useState<boolean>(false);

  const handleClose = () => {
    setError(null);
    setSaved(false);
    props.setShowModal(false);
  };

  const onClickSave = async () => {
    if (!tagName) {
      setError("Please enter a name");
      return;
    }

    if (!props.prompt) {
      setError("Please go back and enter a prompt");
      return;
    }

    setIsWaiting(true);

    try {
      await addTag(props.prompt, props.categoryId, tagName);
      setSaved(true);
    } catch (e) {
      setError((e as any).message);
    }

    setIsWaiting(false);
  };

  return (
    <ModalContainer
      title="Save Tag"
      showModal={props.showModal}
      onClose={handleClose}
      setShowModal={() => {}}
    >
      <div className="flex flex-col justify-center items-center">
        <div className="font-bold">Prompt</div>
        <div className="text-center">{props.prompt}</div>
        <div className="font-bold mt-2">Category</div>
        <div>
          {
            CATEGORY_ID_TO_STRING[
              props.categoryId as keyof typeof CATEGORY_ID_TO_STRING
            ]
          }
        </div>
        <div className="font-bold mt-8">Choose a name:</div>
        <div>
          {" "}
          <input
            className="rounded-lg text-black"
            type="text"
            value={tagName}
            onChange={(e) => setTagName(e.target.value)}
            maxLength={30}
          />
        </div>
        <div>
          <button
            onClick={onClickSave}
            disabled={isWaiting || saved}
            className="bg-blue-600 text-white px-4 py-2 rounded-lg disabled:opacity-70 mt-2"
          >
            {saved ? "Saved!" : "Save"}
          </button>
        </div>
        {error && <div className="text-red-500">{error}</div>}
        {saved && (
          <Link className="underline mt-4" to="/tags/manage">
            View My Tags
          </Link>
        )}
      </div>
    </ModalContainer>
  );
}
