import { useEffect, useState } from "react";
import { logPage } from "../analytics/googleAnalytics";
import { DeleteAccountModal } from "../components/DeleteAccountModal";
import { useUtilityStore } from "../zustand/utilityStores";
import { Link } from "react-router-dom";

export default function About() {
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const isSignedIn = useUtilityStore((state) => state.isSignedIn);

  useEffect(() => {
    logPage("about");
  }, []);

  return (
    <div className="text-white p-6 pt-8 max-w-xl bg-slate-800 rounded-lg">
      <div>
        <b>pornpen.ai</b> generates adult imagery with custom AI algorithms.
        Just choose your tags and click generate to get started!
      </div>
      <br />
      <div>
        For comments, suggestions, or questions, please visit our{" "}
        <a
          className="underline"
          target="_blank"
          rel="noreferrer"
          href="https://discord.gg/uZfjga6SUq"
        >
          Discord
        </a>
        .
      </div>
      <br />
      <div>
        Any generations of content on this website that resemble real people are
        purely coincidental. All people generated are 18+ years of age. This AI
        mirrors biases and misconceptions that are present in its training data.
      </div>
      <br />
      <div>
        For all customer support issues, business or media inquiries, and complaints or content takedown requests, please contact support@pornpen.ai.
      </div>
      <br />
      <div className="font-bold">Complaints</div>
      <div>
        <ul className="list-disc list-inside">
          <li>Images may be flagged with the "flag" icon.</li>
          <li>Images will be automatically and manually reviewed for safety</li>
          <li>Manual reviews will be processed within 7 days</li>
          <li>
            Review process: We will manually review images to see if they meet
            our content guidelines
          </li>
          <li>
            Outcomes: Images found in violation will be immediately deleted.
          </li>
        </ul>
      </div>
      <br />
      <div className="font-bold">Epoch Billing</div>
      <div>
        For support with Epoch billing, please go to{" "}
        <a className="underline" href="https://epoch.com/">
          https://epoch.com/
        </a>
      </div>
      <br />
      {isSignedIn && (
        <div
          className="font-bold underline cursor-pointer"
          onClick={() => {
            setShowDeleteAccountModal(true);
          }}
        >
          To delete your account, click here
        </div>
      )}
      <br />
      <div>
        <Link className="underline" to="/contact">
          Contact Us
        </Link>
      </div>
      <div>
        <a className="underline" href="/terms">
          Terms of Service
        </a>
      </div>
      <div>
        <a className="underline" href="/privacy">
          Privacy Policy
        </a>
      </div>
      <DeleteAccountModal
        showModal={showDeleteAccountModal}
        setShowModal={setShowDeleteAccountModal}
      ></DeleteAccountModal>
    </div>
  );
}
