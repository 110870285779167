import create from "zustand";
import { immer } from "zustand/middleware/immer";

interface DatingMatch {
  id: string;
  image_url: string;
  name: string;
}

interface GameStore {
  datingMatches: DatingMatch[];
  addDatingMatch: (match: DatingMatch) => void;
}

export const useGameStore = create<GameStore>()(
  immer((set) => ({
    datingMatches: [],
    addDatingMatch: (match: DatingMatch) => {
      set((state) => {
        // Add to beginning of array
        state.datingMatches = [match, ...state.datingMatches];
      });
    },
  }))
);
