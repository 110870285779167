import { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import { Tag } from "../common/tagDefinitions";
import { getLiveTags, getTagStats, submitSearch } from "../firebase/functions";
import { TagCard } from "./BrowseTags";
import { useTagStore } from "../zustand/tagStore";
import { InfiniteImageList } from "../components/InfiniteImageList";
import { SearchResult } from "../firebase/searchUtils";
import { useModelStore } from "../zustand/modelStore";

export function ViewTag() {
  let match = useMatch("/tags/view/:tagId");
  const [tag, setTag] = useState<Tag | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const liveTags = useTagStore((state) => state.liveTags);
  const models = useModelStore((state) => state.models);
  const [numImagesLastSevenDays, setNumImagesLastSevenDays] = useState(0);
  const [isLoadingStats, setIsLoadingStats] = useState(false);

  useEffect(() => {
    getLiveTags();
  }, []);

  // TODO: Just get single tag data
  useEffect(() => {
    const foundTag = liveTags.find((tag) => tag.id === match?.params.tagId);
    if (foundTag) {
      setTag(foundTag);
      setLoaded(true);
    } else {
      if (liveTags.length > 0) {
        setNotFound(true);
      }
    }
  }, [match?.params.tagId, liveTags]);

  useEffect(() => {
    if (match?.params.tagId) {
      getTagStats(match.params.tagId)
        .then((result) => {
          console.log(result);
          setNumImagesLastSevenDays(result.numImagesLastSevenDays);
          setIsLoadingStats(false);
        })
        .catch((e) => {
          console.log(e);
          setIsLoadingStats(false);
        });
    }
  }, [match?.params.tagId]);

  if (notFound) {
    return (
      <div className="text-white text-2xl animate-pulse">Tag not found!</div>
    );
  }

  if (!loaded || !tag) {
    return <div className="text-white text-2xl animate-pulse">Loading...</div>;
  }

  const fetchMore = async (cursor: any) => {
    let filteredKeys = [tag.id];

    try {
      const results = await submitSearch(
        filteredKeys,
        "",
        "search",
        cursor,
        true,
        true
      );
      let feedResults: SearchResult[] = [];
      results.forEach((result) => {
        feedResults.push({
          imageUrl: result.imageUrl,
          imageId: result.imageId,
          isPrivate: false,
        });
      });

      return [results, null, true];
    } catch (e) {
      return [[], null, true];
    }
  };

  return (
    <div>
      <div className="pt-4 pb-8 px-4 w-full max-w-lg m-auto">
        <TagCard
          name={tag.name}
          description={tag.description || ""}
          id={tag.id}
          category={tag.category}
          imageUrl={tag.imageUrl || ""}
          numSaves={tag.numSaves || 0}
          showFullDescription
          onlyGenerators={tag.onlyGenerators || []}
        />
      </div>
      {tag.onlyGenerators && tag.onlyGenerators.length > 0 && (
        <>
          <div className="text-white text-center text-xl mb-2 font-bold">
            Limited to Generators
          </div>
          <div className="flex flex-row justify-center flex-wrap">
            {tag.onlyGenerators.map((generator) => {
              const model = models.find((model) => model.id === generator);

              return (
                <div
                  key={model?.id}
                  className="text-white text-center mb-4 px-2 py-1 bg-blue-600 mr-2 rounded-md"
                >
                  {model?.prompt_system}:{model?.name}
                </div>
              );
            })}
          </div>
        </>
      )}
      <div className="text-white text-center text-xl font-bold">Stats</div>
      <div className="text-center text-white">
        {isLoadingStats ? (
          <div className="animate-pulse">Loading</div>
        ) : (
          <div>Last 7 Days: {numImagesLastSevenDays} gens</div>
        )}
      </div>
      <div className="text-white text-center text-xl mb-4 mt-4 font-bold">
        Examples
      </div>
      <InfiniteImageList
        emptyEndMessage="No results"
        fullEndMessage={""}
        getMore={fetchMore as any}
        imageClassName="sm:w-56 w-36 relative aspect-square"
        skipCheck
        scrollableTarget="feed-parent"
        showSave
      />
    </div>
  );
}
