import { Modal } from "flowbite-react";
import React, { useCallback, useEffect } from "react";
import {
  addImageToFolder,
  createFolder,
  deleteImagesFromFolder,
  getFolders,
  getFoldersForImage,
} from "../firebase/functions";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useUtilityStore } from "../zustand/utilityStores";
import { AddFolderModal } from "./AddFolderModal";

export function ImageFolderModal(props: {
  showModal?: boolean;
  setShowModal: (disableModal: boolean) => void;
  imageId: string;
  isPrivate: boolean;
}) {
  const folders = useUtilityStore((state) => state.folders);
  const setFolders = useUtilityStore((state) => state.setFolders);

  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedFolders, setSelectedFolders] = React.useState<
    Record<string, boolean>
  >({});

  const [isAdding, setIsAdding] = React.useState(false);
  const [lastFolderId, setLastFolderId] = React.useState("");
  const [lastFolderName, setLastFolderName] = React.useState("");

  useEffect(() => {
    getFoldersForImage(props.imageId).then((folders) => {
      let newSelectedFolders: Record<string, boolean> = {};
      folders.forEach((folder) => {
        newSelectedFolders[folder.folder_id] = true;
      });
      setSelectedFolders(newSelectedFolders);
      setIsLoading(false);
    });
  }, [props.imageId]);

  const handleClose = () => {
    props.setShowModal(false);
  };

  const onClickFolder = useCallback(
    (id: string) => {
      // If the folder is already selected, remove it, otherwise add it
      if (selectedFolders[id]) {
        deleteImagesFromFolder([props.imageId], id);
      } else {
        addImageToFolder(props.imageId, id, props.isPrivate);

        setLastFolderId(id);
        const name = folders.find((folder) => folder.id === id)?.name;
        if (name) {
          setLastFolderName(name);
        }
      }

      setSelectedFolders((selectedFolders) => {
        let newSelectedFolders = { ...selectedFolders };
        newSelectedFolders[id] = !newSelectedFolders[id];
        return newSelectedFolders;
      });
    },
    [props.imageId, props.isPrivate, selectedFolders]
  );

  if (isAdding) {
    return (
      <AddFolderModal
        showModal={isAdding}
        setShowModal={setIsAdding}
        addFolder={createFolder}
        refreshFolders={() => {
          setIsAdding(false);

          getFolders().then((folders) => {
            setFolders(folders);
          });
        }}
      ></AddFolderModal>
    );
  }

  return (
    <React.Fragment>
      <Modal
        className="dark"
        show={props.showModal}
        onClose={handleClose}
        dismissible
      >
        <Modal.Header>Add to Folder</Modal.Header>
        <Modal.Body>
          {!isLoading && folders.length === 0 && (
            <div className="text-center text-white">
              You have no folders,{" "}
              <Link className="underline" to="/profile">
                create one
              </Link>{" "}
              to get started!
            </div>
          )}
          <div className="flex flex-row flex-wrap">
            <button
              className="mb-2 mr-2 block text-white border px-4 py-2 rounded-lg cursor-pointer select-none bg-blue-500"
              onClick={() => {
                setIsAdding(true);
              }}
            >
              + New
            </button>
            {folders.map((folder) => {
              return (
                <div key={folder.id} onClick={() => onClickFolder(folder.id)}>
                  <div
                    className={classNames(
                      "mb-2 mr-2 block text-white border px-4 py-2 rounded-lg cursor-pointer select-none",
                      {
                        "bg-green-500 border-green-500":
                          selectedFolders[folder.id],
                      }
                    )}
                  >
                    <div className="">{folder.name}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col items-center">
            <Link className="text-white underline" to="/profile">
              Go to folders
            </Link>
            {lastFolderId && (
              <Link
                className="text-white underline"
                to={`/folder/${lastFolderId}`}
              >
                Go to {lastFolderName}
              </Link>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
