export function ReportContentScreen() {
    return (
        <div className="text-white m-auto max-w-lg ml-4 mr-4">
            <p>pornpen.ai abides by the following procedures to ensure compliance:</p>
            <ul className="list-disc list-inside">
                <li>User uploaded content is not allowed.</li>
                <li>All prompts go through a review process.</li>
                <li>Users can flag AI generated images, which will be reviewed within 7 days.</li>
            </ul>
            <br/>
            <p>For further assistance and/or information with any content on the site, please contact us at <a href="mailto:support@pornpen.ai" className="underline text-blue-500">support@pornpen.ai</a></p>
            <br/>
            <p>pornpen.ai allows content to be flagged as inappropriate. Should any content be flagged as illegal, unlawful, harassing, harmful, offensive, or for various other reasons, pornpen.ai shall remove it from the site without delay.</p>
        </div>
    );
}
