import { Link } from "react-router-dom";

export function AdminScreen() {
  return (
    <div className="text-white text-center">
      <div className="text-2xl">Admin</div>
      <div className="flex flex-col">
        <Link className="underline" to="/admin/tagReview">
          Tag Review
        </Link>
        <Link className="underline" to="/admin/flagReview">
          Flag Review
        </Link>
        <Link className="underline" to="/admin/tagLookup">
          Tag Lookup
        </Link>
        <Link className="underline" to="/admin/users">
          User Lookup
        </Link>
        <Link className="underline" to="/admin/tagArtists">
          Tag Artists
        </Link>
      </div>
    </div>
  );
}
