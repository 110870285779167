import { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import { Tag } from "../common/tagDefinitions";
import { getLiveTags } from "../firebase/functions";
import { TagCard, TagListContainer } from "./BrowseTags";
import { useTagStore } from "../zustand/tagStore";

export function TagArtistScreen() {
  let match = useMatch("/tags/artist/:artistId");
  const [tags, setTags] = useState<Tag[]>([]);
  const [totalSaves, setTotalSaves] = useState(0);
  const [numTags, setNumTags] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const liveTags = useTagStore((state) => state.liveTags);

  useEffect(() => {
    getLiveTags();
  }, []);

  useEffect(() => {
    if (!match?.params.artistId) return;
    const foundTags = liveTags.filter(
      (tag) => tag.ownerUsername === match?.params.artistId
    );
    let saves = 0;
    let numTags = 0;
    foundTags.forEach((tag) => {
      saves += tag.numSaves || 0;
      numTags++;
    });

    // Sort by saves
    foundTags.sort((a, b) => {
      if (a.numSaves && b.numSaves) {
        return b.numSaves - a.numSaves;
      } else if (a.numSaves) {
        return -1;
      } else if (b.numSaves) {
        return 1;
      } else {
        return 0;
      }
    });

    setNumTags(numTags);
    setTotalSaves(saves);
    setTags(foundTags);
    setLoaded(true);
  }, [match?.params.artistId, liveTags]);

  let tagCards = tags.map((tag) => (
    <TagCard
      key={tag.id}
      name={tag.name}
      description={tag.description || ""}
      category={tag.category}
      id={tag.id}
      imageUrl={tag.imageUrl || ""}
      numSaves={tag.numSaves || 0}
      ownerUsername={tag.ownerUsername}
    />
  ));

  if (!loaded) {
    return <div className="text-white text-2xl animate-pulse">Loading...</div>;
  }

  if (loaded && tags.length === 0) {
    return <div className="text-white">Artist not found</div>;
  }

  return (
    <div className="text-white w-full md:w-9/12">
      <div className="text-2xl text-center">{match?.params.artistId}</div>
      <div className="text-lg text-center mb-2">
        {numTags} tags | {totalSaves} saves
      </div>
      <div className="flex justify-center">
        <TagListContainer>{tagCards}</TagListContainer>
      </div>
    </div>
  );
}
