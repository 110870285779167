export const BASE = [
  "base_woman_default",
  "base_model",
  "base_miss_universe",
  "base_milf",
  "base_celebrity",
  "base_lingerie_model",
  "base_athlete",
  "base_bodybuilder",
  "base_cyborg",
  "base_sorority",
  "base_bimbo",
  "base_man_woman",
];

export const CHARACTER = [
  "character_angela",
  "character_cindy",
  "character_alice",
];

export const ETHNICITY = [
  "tags_asian",
  "tags_chinese",
  "tags_white",
  "tags_russian",
  "tags_indian",
  "tags_thai",
  "tags_middle_eastern",
  "tags_african",
  "tags_egyptian",
  "tags_ethiopian",
  "tags_nigerian",
  "tags_black",
  "tags_mexican",
  "tags_brazilian",
  "tags_japanese",
  "tags_korean",
  "tags_spanish",
  "tags_british",
  "tags_french",
  "tags_german",
  "tags_arabic",
  "tags_persian",
  "tags_native_american",
  "tags_greek",
  "tags_scandinavian",
  "tags_jewish",
  "tags_polynesian",
  "tags_italian",
  "tags_irish",
  "tags_mongolian",
  "tags_turkish",
  "tags_vietnamese",
  "tags_hungarian",
  "tags_czech",
  "tags_dutch",
  "tags_swedish",
  "tags_portuguese",
  "tags_malaysian",
  "tags_indonesian",
  "tags_filipina",
  "tags_nilotic",
];

export const HAIR_COLOR = [
  "tags_blonde",
  "tags_brunette",
  "tags_ginger",
  "tags_white_hair",
  "tags_black_hair",
  "tags_blue_hair",
  "tags_green_hair",
  "tags_purple_hair",
  "tags_pink_hair",
];

export const HAIR_STYLE = [
  "tags_bob_haircut",
  "tags_pigtails_haircut",
  "tags_hair_bun",
  "tags_pixie_haircut",
  "tags_ponytail_haircut",
  "tags_messy_hair",
  "tags_bangs_hair",
  "tags_braided_hair",
  "tags_slick_haircut",
  "tags_straight_hair",
];

export const BASE_TAGS = [
  "tags_busty",
  "tags_huge_boobs",
  "tags_perfect_boobs",
  "tags_small_tits",
  "tags_beautiful",
  "tags_glasses",
  "tags_sunglasses",
  "tags_tattoo",
  "tags_lipstick",
  "tags_muscular",
  "tags_big_ass",
  "tags_small_ass",
  "tags_skinny",
  "tags_abs",
  "tags_thick",
  "tags_chubby",
  "tags_fat",
  "tags_big_hips",
  "tags_long_legs",
  "tags_short",
  "tags_tall",
  "tags_perfect_body",
  "tags_pubic_hair",
  "tags_short_hair",
  "tags_long_hair",
  "tags_curly_hair",
  "tags_pregnant",
  "tags_dark_skin",
  "tags_fair_skin",
  "tags_darker_skin",
  "tags_oiled_body",
];

export const ACCESSORIES = [
  "tags_jewelry",
  "tags_gold_jewelry",
  "tags_pearl_jewelry",
  "tags_diamond_jewelry",
  "tags_beer",
  "tags_wine",
];

export const MODIFIERS = ["tags_dark_lighting", "tags_bright_lighting"];

export const NEGATIVE = [
  "negative_simple",
  "negative_quality",
  "negative_detailed",
];

// Ethnicity should be at the end so its the first word before the base
// TODO fix this server side
export const TAGS = [
  ...BASE_TAGS,
  ...HAIR_COLOR,
  ...HAIR_STYLE,
  ...ETHNICITY,
  ...ACCESSORIES,
  ...MODIFIERS,
];

export const ENVIRONMENT = [
  "env_bedroom",
  "env_shower",
  "env_onsen",
  "env_forest",
  "env_beach",
  "env_space",
  "env_car",
  "env_yacht",
  "env_underwater",
  "env_changing_room",
  "env_strip_club",
  "env_party",
  "env_club",
  "env_hot_tub",
  "env_stage",
  "env_office",
  "env_bus",
  "env_train",
  "env_street",
  "env_bathroom",
  "env_jungle",
  "env_desert",
  "env_mountains",
  "env_oasis",
  "env_snow",
  "env_locker_room",
  "env_tent",
  "env_couch",
  "env_church",
  "env_kitchen",
  "env_casino",
  "env_gym",
  "env_wedding",
  "env_sauna",
  "env_prison",
  "env_cave",
  "env_pool",
  "env_lake",
  "env_meadow",
  "env_bar",
  "env_cafe",
  "env_mall",
  "env_restaurant",
  "env_hospital",
  "env_grocery",
];

export const STYLE = [
  "style_mirror_selfie",
  "style_artistic",
  "style_black_and_white",
  "style_vintage",
  "style_film",
  "style_anime",
  "style_crisp_anime",
  "style_soft_warm",
  "style_illustration",
  "style_fantasy",
  "style_warm_anime",
  "style_cyberpunk",
  "style_skin_detail",
  "style_charcoal",
  "style_3d",
  "style_watercolor",
  "style_comic",
];

export const VIEW = ["view_front", "view_side", "view_back", "view_close"];

export const CLOTHING = [
  "clothes_nude_default",
  "clothes_bra",
  "clothes_sports_bra",
  "clothes_lingerie",
  "clothes_push_up_bra",
  "clothes_bikini",
  "clothes_shirt",
  "clothes_sweater",
  "clothes_yoga_pants",
  "clothes_dress",
  "clothes_mini_skirt",
  "clothes_jeans",
  "clothes_nightgown",
  "clothes_leather",
  "clothes_halloween_outfit",
  "clothes_nurse",
  "clothes_teacher",
  "clothes_police",
  "clothes_maid",
  "clothes_cheerleader",
  "clothes_santa",
  "clothes_bdsm",
  "clothes_sundress",
  "clothes_mesh",
  "clothes_corset",
  "clothes_tank_top",
  "clothes_pajamas",
  "clothes_stylish",
  "clothes_bodypaint",
  "clothes_stockings",
  "clothes_high_heels",
  "clothes_cosplay",
  "clothes_flight_attendant",
  "clothes_wedding_dress",
  "clothes_fantasy_armor",
  "clothes_apron",
  "clothes_spandex",
  "clothes_tribal",
  "clothes_goth",
  "clothes_military",
  "clothes_professor",
  "clothes_dominatrix",
  "clothes_superhero",
  "clothes_latex",
  "clothes_sailor",
  "clothes_casual",
  "clothes_sports",
  "clothes_gloves",
  "clothes_clown",
  "clothes_harlequin",
  "clothes_medieval",
  "clothes_onesie",
  "clothes_jumpsuit",
  "clothes_viking",
  "clothes_hijab",
  "clothes_waitress",
  "clothes_geisha",
  "clothes_steampunk",
  "clothes_roman",
  "clothes_devil",
  "clothes_vampire",
  "clothes_angel",
  "clothes_micromini",
  "clothes_long_skirt",
  "clothes_thong",
  "clothes_tie",
  "clothes_bow_tie",
  "clothes_high_socks",
  "clothes_sari",
  "clothes_suit",
  "clothes_dirndl",
  "clothes_bathrobe",
  "clothes_fur",
  "clothes_one_piece_swimsuit",
  "clothes_niqab",
  "clothes_pirate",
  "clothes_ninja",
  "clothes_hat",
  "clothes_witch",
  "clothes_towel",
  "clothes_60s",
  "clothes_70s",
  "clothes_80s",
  "clothes_90s",
  "clothes_thigh_socks",
  "clothes_salwar",
  "clothes_doctor",
  "clothes_suspender_belt",
  "clothes_chemise",
  "clothes_short_shorts",
  "clothes_daisy_dukes",
  "clothes_choker",
  "clothes_victorian",
  "clothes_hip_hop",
  "clothes_secretary",
  "clothes_boots",
  "clothes_blouse",
  "clothes_traditional",
  "clothes_fishnet",
  "clothes_tunic",
  "clothes_face_mask",
  "clothes_soccer",
  "clothes_beach_volleyball",
  "clothes_golf",
  "clothes_tennis",
  "clothes_basketball",
  "clothes_race_driver",
  "clothes_firefighter",
  "clothes_lumberjack",
  "clothes_construction_worker",
  "clothes_jacket",
  "clothes_nun",
  "clothes_pilot",
  "clothes_panties",
  "clothes_underwear",
  "clothes_polo",
  "clothes_crop_top",
  "clothes_pantyhose",
  "clothes_kimono",
  "clothes_western",
  "clothes_lab_coat",
  "clothes_parka",
  "clothes_bomber",
  "clothes_trench_coat",
  "clothes_tailcoat",
  "clothes_scarf",
  "clothes_harem_pants",
  "clothes_martial_arts",
  "clothes_microkini",
  "clothes_kilt",
  "clothes_space_suit",
  "clothes_mech_suit",
  "clothes_sci_fi",
  "clothes_satin",
];

export const NUMBER = ["number_one_default", "number_two", "number_group"];

export const AGE = [
  "age_18",
  "age_20s",
  "age_30s",
  "age_40s",
  "age_50s",
  "age_60s",
  "age_70s",
  "age_80s",
];

export const FACE = [
  "face_happy",
  "face_sad",
  "face_serious",
  "face_laughing",
  "face_orgasm",
  "face_seductive",
  "face_pouting",
  "face_shocked",
  "face_angry",
  "face_ahegao",
];

export const ACTION = [
  "action_yoga",
  "action_sleeping",
  "action_squatting",
  "action_cooking",
  "action_eating",
  "action_jumping",
  "action_exercise",
  "action_t_pose",
  "action_bathing",
  "action_video_games",
  "action_plank",
  "action_massage",
  "action_bending_over",
  "action_spreading_legs",
  "action_cumshot",
  "action_on_back",
  "action_straddling",
];

// Titfuck tags
export const TITFUCK_VIEW = [
  "titfuck_view_from_above",
  "titfuck_view_from_side",
  "titfuck_view_pov",
  "titfuck_view_close",
];

//lying down | standing | kneeling | bending over
export const TITFUCK_POSE = [
  "titfuck_pose_lying_down",
  "titfuck_pose_standing",
  "titfuck_pose_kneeling",
  "titfuck_pose_bending_over",
];

// cum, looking at viewer, smiling, open mouth
export const TITFUCK_TAGS = [
  "titfuck_tags_cum",
  "titfuck_tags_looking_at_viewer",
  "titfuck_tags_smiling",
  "titfuck_tags_open_mouth",
];

// Anime tags
export const ANIME_SUBJECT = [
  "anime_subject_woman",
  // "anime_subject_man",
  "anime_subject_man_woman",
  "anime_subject_woman_woman",
  "anime_subject_elf",
  "anime_subject_alien",
  "anime_subject_demon",
  // "anime_subject_man_man",
];

export const ANIME_ACTION = [
  "anime_action_blowjob",
  "anime_action_doggy",
  // "anime_action_titjob",
  "anime_action_missionary",
  "anime_action_cowgirl",
];

export const ANIME_TAGS = [
  "anime_tags_cum_on_face",
  "anime_tags_cum_on_body",
  "anime_tags_futanari",
  "anime_tags_penis",
  "anime_tags_erect",
  "anime_tags_furry",
  "anime_tags_animal_ears",
  "anime_tags_tentacles",
  "anime_tags_pov",
  "anime_tags_open_mouth",
];

export const ANIME_STYLE = [
  "anime_style_90s",
  "anime_style_80s",
  "anime_style_line_art",
  "anime_style_hyperrealistic",
  "anime_style_flat_color",
  "anime_style_ukiyo",
  "anime_style_ink_wash",
  "anime_style_3d",
  "anime_style_intricate",
];

// Furry tags
export const FURRY_BASE = [
  "furry_base_dog",
  "furry_base_wolf",
  "furry_base_bird",
  "furry_base_cow",
  "furry_base_horse",
  "furry_base_cat",
  "furry_base_rabbit",
  "furry_base_bear",
  "furry_base_fox",
  "furry_base_deer",
  "furry_base_tiger",
  "furry_base_lion",
  "furry_base_badger",
  "furry_base_mouse",
  "furry_base_squirrel",
  "furry_base_dragon",
  "furry_base_lizard",
  "furry_base_pig",
  "furry_base_panda",
];

export const FURRY_GENDER = [
  "furry_gender_female",
  "furry_gender_male",
  "furry_gender_andromorph",
  "furry_gender_hermaphrodite",
  "furry_gender_maleherm",
  "furry_gender_femboy",
  "furry_gender_pair_male_female",
  "furry_gender_pair_female_female",
  "furry_gender_pair_male_male",
];

export const FURRY_STYLE = [
  "furry_style_3d",
  "furry_style_hand_drawn",
  "furry_style_toon",
  "furry_style_pencil_drawn",
  "furry_style_photorealistic",
  "furry_style_pixel_art",
];

export const FURRY_ACTION = [
  "furry_action_sex",
  "furry_action_anal",
  "furry_action_blowjob",
  "furry_action_dancing",
  "furry_action_showering",
  "furry_action_anal_fisting",
  "furry_action_fingering",
  "furry_action_tentacle_sex",
  "furry_action_cuddling",
  "furry_action_hugging",
  "furry_action_kissing",
  "furry_action_titfuck",
  "furry_action_presenting_ass",
];

export const FURRY_TAGS = [
  "furry_tag_taur",
  "furry_tag_hyper_breasts",
  "furry_tag_hyper_penis",
  "furry_tag_anus",
  "furry_tag_hyper_butt",
  "furry_tag_equine_penis",
  "furry_tag_canine_penis",
  "furry_tag_hyper_balls",
  "furry_tag_knot",
  "furry_tag_small_penis",
  "furry_tag_sheath",
  "furry_tag_multi_breasts",
  "furry_tag_pussy_juice",
  "furry_tag_gaping_pussy",
  "furry_tag_facial",
  "furry_tag_fur",
  "furry_tag_skin",
  "furry_tag_hyper_preg",
  "furry_tag_huge_penis",
  "furry_tag_huge_ass",
  "furry_tag_huge_breasts",
  "furry_tag_small_breasts",
];

// Men
export const MEN_BASE = [
  "men_base_man",
  "men_base_twink",
  "men_base_bodybuilder",
  "men_base_model",
];

export const MEN_TAGS = [
  "men_tags_big_dick",
  "men_tags_small_dick",
  "men_tags_erect",
  "men_tags_muscular",
  "tags_oiled_body",
  "tags_glasses",
  "tags_sunglasses",
  "tags_tattoo",
  "tags_lipstick",
  "tags_big_ass",
  "tags_small_ass",
  "tags_skinny",
  "tags_thick",
  "tags_chubby",
  "tags_fat",
  "tags_big_hips",
  "tags_long_legs",
  "tags_short",
  "tags_tall",
  "tags_perfect_body",
  "tags_short_hair",
  "tags_long_hair",
  "tags_curly_hair",
  "tags_dark_skin",
  "tags_fair_skin",
];

export const CLOTHING_MODIFIERS = [
  "clothes_partially_nude",
  "clothes_cleavage",
  "clothes_transparent",
  "clothes_topless",
];

// TODO check that all variables are in here somehow
export const TOP_LEVEL = {
  BASE,
  TAGS,
  ENVIRONMENT,
  STYLE,
  VIEW,
  CLOTHING: [...CLOTHING, ...CLOTHING_MODIFIERS],
  NUMBER,
  AGE,
  FACE,
  ACTION,
  // Titfuck specific
  TITFUCK_VIEW,
  TITFUCK_TAGS,
  TITFUCK_POSE,
  // Anime
  ANIME_ACTION,
  ANIME_SUBJECT,
  ANIME_TAGS,
  ANIME_STYLE,
  // Men
  MEN_TAGS,
  MEN_BASE,
  NEGATIVE,
  CHARACTER,
  // Furry
  FURRY_BASE,
  FURRY_TAGS,
  FURRY_STYLE,
  FURRY_ACTION,
  FURRY_GENDER,
};
