import { useCallback, useEffect } from "react";
import { tagIdToDataMap, useTagStore } from "../zustand/tagStore";
import TagStrings from "../zustand/strings.json";
import BounceInComponent from "./animations/BounceInComponent";
import classNames from "classnames";
import ClearIcon from "../images/clear.svg";

export function SelectedTags() {
  const selectedTags = useTagStore((state) => state.selectedTags);
  const toggleTag = useTagStore((state) => state.toggleTag);
  const clearTags = useTagStore((state) => state.clearTags);
  const generator = useTagStore((state) => state.generator);
  const liveTags = useTagStore((state) => state.liveTags);

  // Disable tags that are not meant to be used with the current generator
  useEffect(() => {
    Object.keys(selectedTags).forEach((tagId) => {
      const isSelected = selectedTags[tagId];

      // Strip studio_ from tagId
      const plainId = tagId.replace("studio_", "");

      // Find tag in live tags
      const liveTag = liveTags.find((tag) => tag.id === plainId);

      if (
        liveTag?.onlyGenerators &&
        liveTag.onlyGenerators.length > 0 &&
        !liveTag.onlyGenerators.includes(generator)
      ) {
        if (isSelected) {
          toggleTag(tagId);
        }
      }
    });
  }, [selectedTags, generator, liveTags, toggleTag]);

  const getDisplayName = useCallback((tag: string) => {
    let displayName = TagStrings[tag as keyof typeof TagStrings];

    // If the tag has "studio_" in it, we need to grab the display name from the studio tags
    // which is loaded in global state.
    if (tag.indexOf("studio_") !== -1) {
      const plainId = tag.replace("studio_", "");
      const tagInfo = tagIdToDataMap[plainId];
      if (tagInfo) {
        displayName = tagInfo.name;
      }
    }

    return displayName || tag;
  }, []);

  // Unselect when clicked
  const onClickTag = (tagId: string) => {
    toggleTag(tagId);
  };

  // TODO migrate to chip component
  const tagList = Object.keys(selectedTags)
    .filter((tagId) => {
      if (tagId.includes("_default")) {
        return false;
      }

      // Filter out tags that still have studio_ in them (meaning unresolved)
      let displayName = getDisplayName(tagId);
      if (displayName.indexOf("studio_") !== -1) {
        return false;
      }

      return selectedTags[tagId];
    })
    .map((tagId) => {
      return (
        <BounceInComponent key={tagId}>
          <div
            className={classNames(
              "relative flex justify-center items-center px-4 py-2 rounded-md bg-green-500 hover:bg-red-700 mr-2 mb-2 select-none cursor-pointer hover:opacity-30 animate__animated animate__bounceIn animate__faster text-white hover:text-transparent",
              {
                "bg-purple-500": tagId.includes("studio_"),
              }
            )}
            onClick={() => {
              onClickTag(tagId);
            }}
          >
            <div>{getDisplayName(tagId).toLocaleLowerCase()}</div>
            <div className="absolute opacity-0 hover:opacity-100 w-full h-full flex justify-center items-center">
              <img src={ClearIcon} alt="Clear Tag" className="w-4" />
            </div>
          </div>
        </BounceInComponent>
      );
    });

  if (tagList.length === 0) {
    return null;
  }

  return (
    <div className="bg-slate-800 pt-2 pl-2 rounded-lg ml-2 mb-2 ">
      <div className="text-sm mb-2 text-white flex flex-row">
        <div>Selected Tags</div>
        <div>
          <button onClick={clearTags}>
            <img
              src={ClearIcon}
              alt="Clear Tags"
              className="inline-block ml-2 w-4 hover:scale-110 transition duration-200 ease-in-out"
            />
          </button>
        </div>
      </div>
      <div className="flex flex-wrap">{tagList}</div>
    </div>
  );
}
