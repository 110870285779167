import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { SearchResult } from "../firebase/searchUtils";
import { resolveImageUrl } from "../utils/strings";
import { SavingHeart } from "./SavingHeart";
import { isAnimatedUrl } from "../utils/gifs";

export function InfiniteImageList(props: {
  getMore: (
    cursor: any
  ) => Promise<[results: SearchResult[], cursor: any, reachedEnd?: boolean]>;
  emptyEndMessage: string;
  fullEndMessage: React.ReactNode;
  isPrivate?: boolean;
  uid?: string;
  isSelectingMode?: boolean;
  onSelectionChange?: (
    selected: string[],
    selectedResults: SearchResult[]
  ) => void;
  skipCheck?: boolean;
  imageClassName?: string;
  scrollableTarget?: string;
  showSave?: boolean;
}) {
  const [data, setData] = useState<SearchResult[]>([]);
  const lastDoc = useRef<any>(null);
  const [hasMore, setHasMore] = useState(true);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedResults, setSelectedResults] = useState<SearchResult[]>([]);
  const [hiddenImages, setHiddenImages] = useState<Set<string>>(new Set());
  const didInitial = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      // Race condition where this is called with the initial fetch, with the same query.
      if (didInitial.current && !lastDoc.current) {
        return;
      }
      didInitial.current = true;

      let initialData = await props.getMore(lastDoc.current);
      lastDoc.current = initialData[1];
      let reachedEnd = initialData[2];
      let initResults = initialData[0].filter((result) => result.imageUrl);

      let limitBreak = 0;
      let isLimited = false;
      while (initResults.length === 0 && !props.skipCheck) {
        initialData = await props.getMore(lastDoc.current);
        initResults = initialData[0].filter((result) => result.imageUrl);
        lastDoc.current = initialData[1];
        reachedEnd = initialData[2];
        limitBreak++;

        if (limitBreak > 100) {
          isLimited = true;
          break;
        }
      }

      setData(initResults);

      if (reachedEnd || isLimited) {
        setHasMore(false);
      }
    };

    fetchData();
  }, [lastDoc, props.uid]);

  useEffect(() => {
    if (props.onSelectionChange) {
      props.onSelectionChange(selected, selectedResults);
    }
  }, [selected, props.onSelectionChange]);

  const fetchMore = useCallback(async () => {
    // Race condition where this is called with the initial fetch, with the same query.
    if (didInitial.current && !lastDoc.current) {
      return;
    }

    didInitial.current = true;

    let newData = await props.getMore(lastDoc.current);
    let newResults: SearchResult[] = newData[0].filter(
      (result) => result.imageUrl
    );
    lastDoc.current = newData[1];
    let reachedEnd = newData[2];

    let limitBreak = 0;
    let isLimited = false;
    while (newResults.length === 0 && !props.skipCheck) {
      newData = await props.getMore(lastDoc.current);
      newResults = newData[0].filter((result) => result.imageUrl);
      lastDoc.current = newData[1];
      reachedEnd = newData[2];

      limitBreak++;
      if (limitBreak > 100) {
        isLimited = true;
        break;
      }
    }

    setData((prevData) => [...prevData, ...newResults]);

    if (reachedEnd || isLimited) {
      setHasMore(false);
    }
  }, [props, lastDoc]);

  const filteredData = data.filter(
    (result) => !hiddenImages.has(result.imageId)
  );

  // max image width is min of 512px and window width
  const [maxImageWidth, setMaxImageWidth] = useState(
    Math.min(512, window.innerWidth)
  );

  useEffect(() => {
    const handleResize = () => {
      setMaxImageWidth(Math.min(512, window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMore}
      hasMore={hasMore}
      scrollableTarget={props.scrollableTarget}
      loader={
        <div className="text-center py-4 animate-bounce text-white">
          Loading...
        </div>
      }
      endMessage={
        <div className="text-white text-center w-full text-2xl pb-8">
          <br />
          <div className="align-middle">
            {data.length === 0 ? (
              <div>{props.emptyEndMessage}</div>
            ) : (
              <div>{props.fullEndMessage}</div>
            )}
          </div>
        </div>
      }
    >
      <div className="flex flex-wrap justify-center">
        {filteredData.map((result, idx) => (
          <Link
            key={idx}
            className={props.imageClassName || "relative"}
            onClick={(e) => {
              if (props.isSelectingMode) {
                e.preventDefault();

                if (selected.includes(result.imageId)) {
                  // Remove from selected
                  setSelected(selected.filter((id) => id !== result.imageId));

                  // Remove from selected results
                  setSelectedResults(
                    selectedResults.filter((r) => r.imageId !== result.imageId)
                  );
                } else {
                  // Add to selected
                  setSelected([...selected, result.imageId]);

                  // Add to selected results
                  setSelectedResults([...selectedResults, result]);
                }

                // If shift is held, select all between the last selected and this one
                if (e.shiftKey && selected.length > 0) {
                  let lastSelected = selected[selected.length - 1];
                  let lastSelectedIdx = data.findIndex(
                    (aResult) => aResult.imageId === lastSelected
                  );
                  let thisIdx = data.findIndex(
                    (aResult) => aResult.imageId === result.imageId
                  );

                  let start = Math.min(lastSelectedIdx, thisIdx);
                  let end = Math.max(lastSelectedIdx, thisIdx);

                  let newSelected = data
                    .slice(start, end + 1)
                    .map((result) => result.imageId);

                  // Append to the existing selected, filter out duplicates
                  setSelected(
                    [...selected, ...newSelected].filter(
                      (id, idx, arr) => arr.indexOf(id) === idx
                    )
                  );

                  // Append to the existing selected results, filter out duplicates
                  setSelectedResults(
                    [...selectedResults, ...data.slice(start, end + 1)].filter(
                      (r, idx, arr) =>
                        arr.findIndex((r2) => r2.imageId === r.imageId) === idx
                    )
                  );
                }
              }
            }}
            to={
              props.isPrivate || result.isPrivate
                ? `/private/${result.imageId}`
                : `/view/${result.imageId}`
            }
          >
            {isAnimatedUrl(result.imageUrl) ? (
              <video
                autoPlay
                loop
                muted
                className={classNames("lazyload", {
                  "border-4 border-red-700 opacity-70": selected.includes(
                    result.imageId
                  ),
                })}
                src={result.imageUrl}
              />
            ) : (
              <img
                data-src={resolveImageUrl(result.imageUrl)}
                alt="Variation of original"
                width={maxImageWidth}
                className={classNames("lazyload", {
                  "border-4 border-red-700 opacity-70": selected.includes(
                    result.imageId
                  ),
                })}
                onError={() => {
                  console.log("hiding image", result.imageId);
                  setHiddenImages((prev) => new Set(prev.add(result.imageId)));
                }}
              />
            )}
            {result.isUpscaled && (
              <span className="absolute bottom-2 left-2 bg-slate-900/70 text-white rounded-lg p-2">
                Upscale
              </span>
            )}
            {props.showSave && (
              <SavingHeart imageId={result.imageId} show={true} />
            )}
          </Link>
        ))}
      </div>
    </InfiniteScroll>
  );
}
