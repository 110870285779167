import { useEffect } from "react";
import { useUtilityStore } from "../zustand/utilityStores";
import { updateSubscriberInfo } from "../firebase/auth";
import { Link, useNavigate } from "react-router-dom";

export function CCSuccessScreen() {
  const isSignedIn = useUtilityStore((state) => state.isSignedIn);
  const isPro = useUtilityStore((state) => state.isPro);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isPro) {
      // updateSubscriberInfo
      // Every 5 seconds, call updateSubscriberInfo
      const interval = setInterval(() => {
        updateSubscriberInfo();
      }, 5000);
      return () => clearInterval(interval);
    } else {
      navigate("/subscriber");
    }
  }, [isPro, navigate]);

  if (!isSignedIn) {
    return (
      <div className="text-white text-center">
        <div className="text-xl mb-2">Thank you for your purchase!</div>
        <Link to="/profile">Please sign in to continue</Link>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center w-full text-white flex-col">
      <div className="text-xl mb-2">Thank you for your purchase!</div>
      <div className="text-yellow-600 animate-bounce">
        Upgrading to Pro Mode
      </div>
    </div>
  );
}
