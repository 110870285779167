import { Modal } from "flowbite-react";
import React from "react";
import { submitFlag } from "../firebase/functions";
import { resolveImageUrl } from "../utils/strings";
import { isAnimatedUrl } from "../utils/gifs";

export function FlagModal(props: {
  showModal?: boolean;
  setShowModal: (disableModal: boolean) => void;
  imageUrl: string;
  imageId: string;
}) {
  const [didSubmit, setDidSubmit] = React.useState(false);
  const [isWaiting, setIsWaiting] = React.useState(false);

  const handleClose = () => {
    props.setShowModal(false);
  };

  const onClickFlag = async () => {
    setIsWaiting(true);
    try {
      await submitFlag(props.imageId);
    } catch (e) {
      console.error(e);
    }
    setDidSubmit(true);
    setIsWaiting(false);
  };

  return (
    <React.Fragment>
      <Modal show={props.showModal} onClose={handleClose} dismissible>
        <Modal.Header>Report content</Modal.Header>
        <Modal.Body>
          <div>
            <b>Help keep pornpen.ai safe by reporting unsafe images!</b>
          </div>
          <div className="mt-4">
            Although we filter all content, sometimes the AI messes up anyway.
          </div>
          <div className="mt-4">
            If you see content that violates our TOS we will provide a response
            within 7 business days or sooner with a resolution to your report.
          </div>
          <div className="mt-4">
            Thank you for helping us keep pornpen.ai safe!
          </div>
          <div className="flex flex-row justify-center mt-4">
            <div className=" max-w-sm">
              {isAnimatedUrl(props.imageUrl) ? (
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  src={resolveImageUrl(props.imageUrl)}
                />
              ) : (
                <img src={resolveImageUrl(props.imageUrl)} alt="reporting" />
              )}
            </div>
          </div>
          {!didSubmit && (
            <div className="flex flex-row justify-center mt-4">
              <button
                disabled={isWaiting}
                className="text-white py-2 px-4 rounded-lg
                disabled:opacity-50 mx-2"
                style={{ backgroundColor: "#af9106" }}
                onClick={onClickFlag}
              >
                Report
              </button>
              <button
                onClick={() => {
                  handleClose();
                }}
                className="text-white py-2 px-4 rounded-lg bg-red-600 mx-2"
              >
                Cancel
              </button>
            </div>
          )}
          {isWaiting && (
            <div className="flex flex-row justify-center">
              <div className="text-green-500 mt-4 animate-pulse">
                Submitting...
              </div>
            </div>
          )}
          {didSubmit && (
            <div className="flex flex-row justify-center">
              <div className="text-green-500 mt-4">
                Thank you for reporting this content!
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
