import classNames from "classnames";
import { deleteDoc, doc, Timestamp } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CATEGORY_ID_TO_STRING } from "../common/categoryDefinitions";
import { Tag } from "../common/tagDefinitions";
import { getLiveTags, saveTagToProfile } from "../firebase/functions";
import { db } from "../firebase/setupFirebase";
import { useTagStore } from "../zustand/tagStore";
import { useUtilityStore } from "../zustand/utilityStores";
import "lazysizes";
import { resolveImageUrl } from "../utils/strings";
import ScrollToTopOverlay from "../components/ScrollToTopOverlay";
import { useModelMap } from "../zustand/modelStore";
import { Dropdown } from "flowbite-react";

type CATEGORY = "All" | "Top" | "New" | "My Tags" | "Featured";
const SORT_MODE_MAP = {
  alphabet: "Name A-Z",
  top_saves: "Top Saved",
  newest: "Newest",
};

export function BrowseTags() {
  const tags = useTagStore((state) => state.liveTags);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryFilter, setCategoryFilter] = useState<CATEGORY>("New");
  const studioTags = useTagStore((state) => state.studioTags);
  const [searchQuery, setSearchQuery] = useState("");
  const [finalTags, setFinalTags] = useState<Tag[]>([]);
  const [sortMode, setSortMode] = useState<"alphabet" | "top_saves" | "newest">(
    "newest"
  );

  useEffect(() => {
    // Load query param called ?category
    const urlParams = new URLSearchParams(window.location.search);
    const category = urlParams.get("category");
    if (category) {
      setCategoryFilter(category as CATEGORY);
    }
  }, []);

  useEffect(() => {
    getLiveTags();
  }, []);

  useEffect(() => {
    if (tags.length > 0) {
      setIsLoading(false);
    }
  }, [tags]);

  // If categoryFilter is New, then set sortMode to newest
  useEffect(() => {
    if (categoryFilter === "New") {
      setSortMode("newest");
    }
  }, [categoryFilter]);

  useEffect(() => {
    let filteredTags = tags.filter((tag) => {
      if (
        categoryFilter === "All" ||
        categoryFilter === "Top" ||
        categoryFilter === "New" ||
        categoryFilter === "My Tags" ||
        categoryFilter === "Featured"
      ) {
        return true;
      }
      return tag.category === categoryFilter;
    });

    // Don't enable dropdown for this
    const isSpecialCategory =
      categoryFilter === "All" || categoryFilter === "Top";

    if (categoryFilter === "Top") {
      // Sort by num saves and take the top 20
      filteredTags.sort((a, b) => {
        return (b.numSaves || 0) - (a.numSaves || 0);
      });
      filteredTags.splice(30);
    }

    if (categoryFilter === "New") {
      //First filter out tags that don't have a live date
      filteredTags = filteredTags.filter((tag) => {
        return tag.liveDate !== undefined && tag.liveDate !== null;
      });

      // Sort by tag.liveDate and take the top 20
      filteredTags.sort((a, b) => {
        let aDate = new Timestamp(a.liveDate._seconds, a.liveDate._nanoseconds);
        let bDate = new Timestamp(b.liveDate._seconds, b.liveDate._nanoseconds);

        return bDate.toMillis() - aDate.toMillis();
      });
      filteredTags.splice(80);
    }

    if (categoryFilter === "My Tags") {
      // Filter out tags that aren't saved
      filteredTags = filteredTags.filter((tag) => {
        return studioTags.some((studioTag) => {
          return studioTag.id === tag.id && studioTag.isSaved;
        });
      });
    }

    if (categoryFilter === "Featured") {
      // Filter out tags that aren't featured
      filteredTags = filteredTags.filter((tag) => {
        return tag.featured;
      });
    }

    if (!isSpecialCategory) {
      if (sortMode === "top_saves") {
        filteredTags.sort((a, b) => {
          return (b.numSaves || 0) - (a.numSaves || 0);
        });
      } else if (sortMode === "newest") {
        filteredTags = filteredTags.filter((tag) => {
          return tag.liveDate !== undefined && tag.liveDate !== null;
        });

        filteredTags.sort((a, b) => {
          let aDate = new Timestamp(
            a.liveDate._seconds,
            a.liveDate._nanoseconds
          );
          let bDate = new Timestamp(
            b.liveDate._seconds,
            b.liveDate._nanoseconds
          );

          return bDate.toMillis() - aDate.toMillis();
        });
      } else if (sortMode === "alphabet") {
        filteredTags.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      }
    }

    // Apply search query
    if (searchQuery !== "") {
      filteredTags = filteredTags.filter((tag) => {
        return (
          tag.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (tag.description &&
            tag.description.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      });

      // Sort, prioritize results with the search query in the name
      filteredTags.sort((a, b) => {
        const aNameHasQuery = a.name.toLowerCase().includes(searchQuery);
        const bNameHasQuery = b.name.toLowerCase().includes(searchQuery);

        if (aNameHasQuery && !bNameHasQuery) {
          return -1;
        } else if (!aNameHasQuery && bNameHasQuery) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    setFinalTags(filteredTags);
  }, [tags, categoryFilter, searchQuery, studioTags, sortMode]);

  const onClickCategoryFilter = useCallback((category: CATEGORY) => {
    setCategoryFilter(category);
  }, []);

  let categoryKeys = Object.keys(CATEGORY_ID_TO_STRING);
  categoryKeys.unshift("My Tags");
  categoryKeys.unshift("New");
  categoryKeys.unshift("Top");
  categoryKeys.unshift("All");
  // categoryKeys.unshift("Featured");

  let categories = categoryKeys.map((key) => {
    const category =
      CATEGORY_ID_TO_STRING[key as keyof typeof CATEGORY_ID_TO_STRING] || key;

    return (
      <button
        key={key}
        className={classNames("whitespace-nowrap mx-2", {
          "text-purple-500 font-bold": categoryFilter === key,
        })}
        onClick={() => onClickCategoryFilter(key as CATEGORY)}
      >
        {category}
      </button>
    );
  });

  let tagCards =
    categoryFilter === "All"
      ? finalTags.map((tag) => {
          return (
            <div>
              <Link className="underline" to={`/tags/view/${tag.id}`}>
                {tag.name}
              </Link>
            </div>
          );
        })
      : finalTags.map((tag) => (
          <TagCard
            key={tag.id}
            name={tag.name}
            description={tag.description || ""}
            category={tag.category}
            id={tag.id}
            imageUrl={tag.imageUrl || ""}
            numSaves={tag.numSaves || 0}
            ownerUsername={tag.ownerUsername}
            onlyGenerators={tag.onlyGenerators || []}
          />
        ));

  // Adds a separator between the category buttons
  categories = insert(
    categories,
    4,
    <span key="seperator" className="select-none font-bold">
      {" "}
      |{" "}
    </span>
  );

  return (
    <div className="text-white w-full md:w-9/12">
      <div className="mb-2 flex flex-row justify-between">
        <div className="w-8"></div>
        <div className="text-2xl text-center">Browse Tags</div>
        <div className="w-8"></div>
      </div>

      <div className="flex flex-row justify-start w-full overflow-x-scroll px-2 pb-4 text-lg">
        {categories}
      </div>
      <div className="flex flex-row justify-end mb-4 text-black">
        <input
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="rounded-lg mr-4"
          type="text"
          placeholder="🔍 search"
        ></input>
        <Dropdown
          label={SORT_MODE_MAP[sortMode]}
          dismissOnClick={true}
          disabled={categoryFilter === "All" || categoryFilter === "Top"}
        >
          <Dropdown.Item onClick={() => setSortMode("alphabet")}>
            Name A-Z
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setSortMode("top_saves")}>
            Top Saved
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setSortMode("newest")}>
            Newest
          </Dropdown.Item>
        </Dropdown>
      </div>
      {isLoading && <div className="text-center animate-pulse">Loading...</div>}
      <TagListContainer>{tagCards}</TagListContainer>
    </div>
  );
}

export function TagCard(props: {
  name: string;
  description: string;
  id: string;
  category: string;
  imageUrl: string;
  numSaves: number;
  ownerUsername?: string;
  hideSaveButton?: boolean;
  onlyGenerators?: string[];
  showFullDescription?: boolean;
}) {
  const [saved, setSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const studioTags = useTagStore((state) => state.studioTags);
  const setStudioTags = useTagStore((state) => state.setStudioTags);
  const uid = useUtilityStore((state) => state.uid);
  const setTag = useTagStore((state) => state.setTag);
  const setSignInRequired = useUtilityStore((state) => state.setSignInRequired);
  const isSignedIn = useUtilityStore((state) => state.isSignedIn);
  const addRecentTag = useTagStore((state) => state.addRecentTag);
  const modelMap = useModelMap();

  useEffect(() => {
    const didPreviouslySave = studioTags.some((tag) => {
      return tag.id === props.id && tag.isSaved;
    });
    setSaved(didPreviouslySave);
  }, [studioTags, props.id]);

  const onClickSave = useCallback(async () => {
    if (!isSignedIn) {
      setSignInRequired(true);
      return;
    }

    setIsSaving(true);
    await saveTagToProfile(props.id);
    // Add to studio tags
    let newStudioTags = [...studioTags];
    const localTag = {
      name: props.name,
      description: props.description,
      id: props.id,
      isSaved: true,
      category: props.category,
      onlyGenerators: props.onlyGenerators,
    };
    newStudioTags.push(localTag as Tag);
    addRecentTag(localTag as Tag);

    setStudioTags(newStudioTags);
    setIsSaving(false);
    setSaved(true);
  }, [
    props.category,
    props.description,
    props.id,
    props.name,
    setStudioTags,
    studioTags,
    setSignInRequired,
    isSignedIn,
    addRecentTag,
    props.onlyGenerators,
  ]);

  const isPro = useUtilityStore((state) => state.isPro);

  const onClickDelete = useCallback(async () => {
    const docRef = doc(db, "users", uid, "tags", props.id);
    setIsSaving(true);
    await deleteDoc(docRef);
    setIsSaving(false);
    // Remove from studio tags
    const newStudioTags = studioTags.filter((tag) => tag.id !== props.id);
    setStudioTags(newStudioTags);
    setTag("studio_" + props.id, false);
  }, [props.id, studioTags, setStudioTags, uid, setTag]);

  let isProTag = true;

  if (props.onlyGenerators && props.onlyGenerators.length > 0) {
    props.onlyGenerators.forEach((generatorId) => {
      const model = modelMap[generatorId];
      if (model && model.sdModel !== "sdxl") {
        isProTag = false;
      }
    });
  }

  let description = "";

  const maxLength = 150;
  if (!props.showFullDescription && props.description.length > maxLength) {
    description = props.description.slice(0, maxLength) + "...";
  } else {
    description = props.description;
  }

  return (
    <div className="mb-2 break-inside-avoid h-full">
      <div className="flex rounded-lg border border-gray-400 bg-slate-800 shadow-md flex-col h-full">
        {props.imageUrl && (
          <Link
            className="rounded-t-lg aspect-square"
            to={"/tags/view/" + props.id}
          >
            <img
              alt=""
              className="rounded-t-lg aspect-square lazyload"
              data-src={resolveImageUrl(props.imageUrl)}
            />
          </Link>
        )}
        <div className="flex h-full flex-col justify-start p-6">
          <div className="text-2xl font-bold tracking-tight text-gray-50 dark:text-white">
            <Link className="break-words" to={"/tags/view/" + props.id}>
              {props.name.toLocaleLowerCase()}
            </Link>
          </div>
          <div className="">
            <span className="text-gray-300">
              {
                CATEGORY_ID_TO_STRING[
                  props.category as keyof typeof CATEGORY_ID_TO_STRING
                ]
              }
            </span>
            {props.onlyGenerators && props.onlyGenerators.length > 0 && (
              <Link
                to={isProTag && !isPro ? "/getpro" : "/tags/view/" + props.id}
                className={classNames("", {
                  "text-yellow-400 opacity-100 font-bold no-underline":
                    isProTag,
                  "opacity-30 text-slate-50": !isProTag,
                })}
              >
                {" "}
                {isProTag ? "Pro Tag" : "Limited Generators"}
              </Link>
            )}
          </div>
          {props.ownerUsername && (
            <div className="text-gray-300 font-bold">
              <Link
                className="underline"
                to={"/tags/artist/" + props.ownerUsername}
              >
                by {props.ownerUsername}
              </Link>
            </div>
          )}
          <p
            className={
              "font-normal text-white dark:text-gray-400 mt-4 break-words flex-grow text-sm"
            }
          >
            {description}
          </p>
          <div
            className={classNames("flex justify-between mt-4", {
              hidden: props.hideSaveButton,
            })}
          >
            <div className="text-gray-50 relative w-full">
              <span className="absolute bottom-0 left-0">
                Saves: {props.numSaves}
              </span>
            </div>
            <button
              onClick={saved ? onClickDelete : onClickSave}
              disabled={isSaving}
              className={classNames(
                "px-4 py-2 rounded-md disabled:opacity-70",
                {
                  "bg-red-500": saved,
                  "bg-purple-600": !saved,
                }
              )}
            >
              {saved ? "Remove" : "Save"}
            </button>
          </div>
        </div>
      </div>
      <ScrollToTopOverlay />
    </div>
  );
}

const insert = (arr: Array<any>, index: number, newItem: any) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

export function TagListContainer(props: { children: React.ReactNode }) {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 pb-32">
      {props.children}
    </div>
  );
}
