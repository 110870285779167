import classNames from "classnames";
import { Table } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CATEGORY_ID_TO_STRING } from "../common/categoryDefinitions";
import { Tag } from "../common/tagDefinitions";
import {
  getMyUsername,
  getTagsForUser,
  saveUsername,
} from "../firebase/functions";

export function MyTags() {
  const [tags, setTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [username, setUsername] = useState<string>("");
  const [usernameError, setUsernameError] = useState<string | null>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getTagsForUser().then((tags) => {
      setTags(tags);
      setIsLoading(false);
    });
    getMyUsername().then((username) => {
      setUsername(username);
    });
  }, []);

  const rows = tags
    .sort((a, b) => {
      // Sort by lowercase
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    })
    .map((tag) => {
      return (
        <Table.Row
          key={tag.id}
          className="bg-white dark:border-gray-700 dark:bg-gray-800"
        >
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white underline cursor-pointer">
            <Link to={"/tags/edit/" + tag.id}>{tag.name}</Link>
          </Table.Cell>
          <Table.Cell>
            {
              CATEGORY_ID_TO_STRING[
                tag.category as keyof typeof CATEGORY_ID_TO_STRING
              ]
            }
          </Table.Cell>
          <Table.Cell>{tag.prompt}</Table.Cell>
          <Table.Cell>
            <span
              className={classNames({
                "text-green-500 font-bold": tag.status === "live",
                "text-yellow-500": tag.status === "draft",
                "text-red-500": tag.status === "rejected",
              })}
            >
              {tag.status}
              {tag.rejectReason &&
                tag.status === "rejected" &&
                `: ${tag.rejectReason}`}
            </span>
          </Table.Cell>
        </Table.Row>
      );
    });

  const onClickSave = useCallback(async () => {
    try {
      setIsSaving(true);
      setUsernameError(null);
      await saveUsername(username);
    } catch (e: any) {
      setUsernameError(e.message);
    }

    setIsSaving(false);
  }, [username]);

  return (
    <div className="text-white w-full max-w-3xl overflow-x-scroll p-4">
      <div className="text-2xl mb-2 text-center">My Tags</div>
      <div>Username</div>
      <div>
        <input
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="rounded-lg text-black disabled:opacity-70"
          disabled={isSaving}
          type="text"
        />
      </div>
      <div className="mb-4">
        <button
          onClick={onClickSave}
          className="underline disabled:opacity-70"
          disabled={isSaving}
        >
          {isSaving ? "Saving..." : "Save"}
        </button>
        <Link to="/tags/studio" className="underline ml-2">
          Tag Studio
        </Link>
      </div>
      {usernameError && <div className="text-red-500">{usernameError}</div>}
      <div>
        <Table>
          <Table.Head>
            <Table.HeadCell>Tag Name</Table.HeadCell>
            <Table.HeadCell>Category</Table.HeadCell>
            <Table.HeadCell>Prompt</Table.HeadCell>
            <Table.HeadCell>Draft</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">{rows}</Table.Body>
        </Table>
      </div>
      {isLoading && (
        <div className="text-center animate-pulse mt-2">Loading...</div>
      )}
    </div>
  );
}
