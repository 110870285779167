import { useCallback, useState } from "react";
import { deleteSavedImage, saveImage } from "../firebase/functions";

import Heart from "../images/heart.svg";
import HeartFilled from "../images/heart_filled.svg";
import { useUtilityStore } from "../zustand/utilityStores";

export function SavingHeart(props: { imageId: string; show: boolean }) {
  const [isSaved, setIsSaved] = useState(false);
  const isSignedIn = useUtilityStore((state) => state.isSignedIn);
  const setSignInRequired = useUtilityStore((state) => state.setSignInRequired);
  const uid = useUtilityStore((state) => state.uid);

  const onClickSave = useCallback(
    (imageId: string) => {
      if (!imageId) {
        return;
      }

      if (!isSignedIn) {
        setSignInRequired(true);
        return;
      }

      if (isSaved) {
        deleteSavedImage(imageId, uid);
        setIsSaved(false);
      } else {
        saveImage(imageId || "", uid, false);
        setIsSaved(true);
      }
    },
    [isSaved, isSignedIn, setSignInRequired, uid]
  );

  if (!props.show && !isSaved) {
    return null;
  }

  return isSaved ? (
    <img
      src={HeartFilled}
      alt="unsave"
      className="absolute bottom-2 left-2 w-10 cursor-pointer"
      onClick={(e) => {
        e.preventDefault();
        onClickSave(props.imageId);
      }}
    />
  ) : (
    <img
      src={Heart}
      alt="save"
      className="absolute bottom-2 left-2 w-10 cursor-pointer"
      onClick={(e) => {
        e.preventDefault();
        onClickSave(props.imageId);
      }}
    />
  );
}
