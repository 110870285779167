import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSavedImages } from "../firebase/functions";
import { useUtilityStore } from "../zustand/utilityStores";
import { getAuth, sendSignInLinkToEmail, signOut } from "firebase/auth";
import { ListGroup } from "flowbite-react";
import SignInModal, { actionCodeSettings } from "../components/SignInModal";
import { InfiniteImageList } from "../components/InfiniteImageList";
import { DeleteAccountModal } from "../components/DeleteAccountModal";
import { FolderList } from "../components/FolderList";
import EditIcon from "../images/edit.svg";
import { changeEmail } from "../firebase/auth";
import { toast } from "react-hot-toast";
import { ModalContainer } from "../components/ModalContainer";
import { ReactSortable } from "react-sortablejs";
import SortFolderIcon from "../images/sort_folder.svg";

export default function Profile() {
  const isSignedIn = useUtilityStore((state) => state.isSignedIn);
  const uid = useUtilityStore((state) => state.uid);
  const isPro = useUtilityStore((state) => state.isPro);
  const email = useUtilityStore((state) => state.email);
  const emailVerified = useUtilityStore((state) => state.emailVerified);
  const navigate = useNavigate();
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [didVerify, setDidVerify] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [showSortFoldersModal, setShowSortFoldersModal] = useState(false);
  const folders = useUtilityStore((state) => state.folders);
  const setFolderOrderMap = useUtilityStore((state) => state.setFolderOrderMap);
  const didLoadSettings = useUtilityStore((state) => state.didLoadSettings);

  useEffect(() => {
    if (!isSignedIn) {
      return;
    }
  }, [isSignedIn, uid]);

  const onSignOut = useCallback(() => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getMore = useCallback(
    (lastDoc: any) => {
      return getSavedImages(uid, lastDoc);
    },
    [uid]
  );

  const onClickVerify = () => {
    const auth = getAuth();
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
        toast.error(errorMessage);
      });

    setDidVerify(true);
  };

  const onClickUpdateEmail = async () => {
    setIsEditingEmail(false);

    await changeEmail(newEmail);
  };

  if (!isSignedIn) {
    return <SignInModal disableModal></SignInModal>;
  }

  return (
    <div className="text-white w-full pb-16">
      <div className="my-8 flex justify-center flex-col items-center">
        <div className="w-48 dark">
          <ListGroup>
            {/* <ListGroup.Item
              active={true}
            >
              Saved Images
            </ListGroup.Item> */}
            <ListGroup.Item
              onClick={() => {
                navigate("/subscriber");
              }}
            >
              Subscription
            </ListGroup.Item>
            <ListGroup.Item
              onClick={() => {
                navigate("/private");
              }}
            >
              Private
            </ListGroup.Item>
            <ListGroup.Item
              onClick={() => {
                navigate("/saved");
              }}
            >
              Saved
            </ListGroup.Item>
            <ListGroup.Item
              onClick={() => {
                setShowDeleteAccountModal(true);
              }}
            >
              <span className="text-red-400">Delete Account</span>
            </ListGroup.Item>
            <ListGroup.Item onClick={onSignOut}>Sign Out</ListGroup.Item>
          </ListGroup>
        </div>
        {email && (
          <div className="text-center text-sm mt-2 flex flex-row justify-center items-center">
            <div className="mr-2">{email}</div>
            <button>
              <img
                src={EditIcon}
                className="w-4 aspect-square"
                alt="edit"
                onClick={() => {
                  setIsEditingEmail(!isEditingEmail);
                }}
              />
            </button>
          </div>
        )}
        {isEditingEmail && (
          <div>
            <input
              type="text"
              className="text-black mt-2 rounded-sm mr-2"
              placeholder="New Email"
              value={newEmail}
              onChange={(e) => {
                setNewEmail(e.target.value);
              }}
            />
            <button
              className="underline text-white"
              onClick={onClickUpdateEmail}
            >
              Save
            </button>
          </div>
        )}
        {email && !emailVerified && (
          <div className="text-center text-red-600">
            Your email is not verified!{" "}
            <button
              disabled={didVerify}
              onClick={onClickVerify}
              className="underline disabled:opacity-25"
            >
              {didVerify ? "Sent! Check your email" : "Send Verification Email"}
            </button>
          </div>
        )}
      </div>
      <div className="text-center mb-2 text-2xl flex justify-center items-center">
        <div className="mr-2">Folders</div>
        <button onClick={() => setShowSortFoldersModal(true)}>
          <img src={SortFolderIcon} alt="Sort Folders"></img>
        </button>
      </div>
      <div className="px-4">
        <FolderList />
      </div>
      <DeleteAccountModal
        showModal={showDeleteAccountModal}
        setShowModal={setShowDeleteAccountModal}
      ></DeleteAccountModal>
      <ModalContainer
        showModal={showSortFoldersModal}
        setShowModal={setShowSortFoldersModal}
        title="Sort Folders"
      >
        <div className="font-bold mb-4">
          Click and drag to sort your folders!
        </div>

        <ReactSortable
          list={folders.map((item) => ({ ...item }))}
          setList={(newFolders) => {
            if (newFolders.length === 0 || !didLoadSettings) {
              return;
            }

            // Folder order map
            let folderOrderMap: { [key: string]: number } = {};
            newFolders.forEach((folder, index) => {
              folderOrderMap[folder.id] = index;
            });

            console.log(folderOrderMap);
            console.log(newFolders);
            setFolderOrderMap(folderOrderMap);
          }}
          tag={"div"}
        >
          {folders.map((item) => (
            <div
              key={item.id}
              className="bg-slate-800 text-center mb-2 rounded-lg py-2 flex items-center select-none cursor-pointer"
            >
              {item.thumbnail_url && (
                <img
                  src={item.thumbnail_url}
                  alt={item.name}
                  className="w-16 rounded-lg ml-2"
                />
              )}
              <div className="text-center flex-grow text-lg">{item.name}</div>
            </div>
          ))}
        </ReactSortable>
      </ModalContainer>
    </div>
  );
}
