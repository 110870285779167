import classnames from "classnames";
import { doc, getDoc } from "firebase/firestore";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import AllTagSelector from "../components/AllTagSelector";
import { logEvent, logPage } from "../analytics/googleAnalytics";
import { db } from "../firebase/setupFirebase";
import TagStrings from "../zustand/strings.json";
import { useTagStore } from "../zustand/tagStore";
import {
  generatePayload,
  generatePayloadGeneric,
} from "../generator/tagGenerator";
import {
  deleteSavedImage,
  getImageSaveStatus,
  getPublicTagDataFromIds,
  saveImage,
  sendPrompt,
  submitEnhance,
  submitPainting,
  submitUncrop,
} from "../firebase/functions";
import Helmet from "react-helmet";
import { getEdits } from "../firebase/searchUtils";
import { useUtilityStore } from "../zustand/utilityStores";
import classNames from "classnames";
import {
  drawToWhiteCanvas,
  invertSelection,
  lastPaintingInfo,
  lastSmartEditInfo,
  lastUncropInfo,
  loadDrawing,
  redo,
  rgbToHex,
  undo,
} from "../utils/painting";
import { SketchPicker } from "react-color";
import { INPAINT_MODES, useHistoryStore } from "../zustand/historyStore";
import {
  capitalizeFirstLetter,
  GENERATOR_TO_STRING,
  resolveImageUrl,
} from "../utils/strings";
import { generateTagURL } from "../utils/navigation";
import { GeneratorSelect } from "../components/GeneratorSelector";
import Draggable from "react-draggable";
import { FlagModal } from "../components/FlagModal";
import FlagSvg from "../../src/images/flag.svg";
import FolderAddSvg from "../../src/images/folder_add.svg";
import { DeleteModal } from "../components/DeleteModal";
import { ProError } from "../components/ProError";
import { ImageRating } from "../components/ImageRating";
import { Tag } from "../common/tagDefinitions";
import { ScaleModal } from "../components/ScaleModal";
import { ImageFolderModal } from "../components/ImageFolderModal";
import { Button, Select } from "flowbite-react";
import { SelectedTags } from "../components/SelectedTags";
import ScrollToTopOverlay from "../components/ScrollToTopOverlay";
import toast from "react-hot-toast";
import { InfiniteImageList } from "../components/InfiniteImageList";
import { useModelMap, useModelStore } from "../zustand/modelStore";
import InfoIcon from "../images/info.svg";
import DownloadIcon from "../images/download.svg";
import PaintIcon from "../images/edit.svg";
import EraseIcon from "../images/eraser.svg";
import InvertIcon from "../images/invert.svg";

const BRUSHES = ["paint", "erase"] as const;
const MASK_MODE_TO_NAME = {
  keep: "Keep selected",
  change: "Change selected",
  morph: "Morph selected",
  fix: "Fix selected",
};

const MASK_MODE_TO_DESCRIPTION = {
  keep: "Keep the selection and change everything else",
  change: "Change the selection, keep everything else",
  morph: "Change the selection, preserving the existing colors/structure",
  fix: "Applies 'Fix Details' to the selected parts only.",
};

// Input is between 1 and 10
// Output is between 1 and 0.1
// From inputs 1 to 5, output is 1 to 0.5
// From inputs 5 to 10, output is 0.5 to 0.1
function ease(t: number) {
  return t;
  // if (t <= 5) {
  //   return (-1 / 8) * t + 9 / 8;
  // } else {
  //   return Math.max(-0.08 * t + 0.9, 0.1);
  // }
}

export default function ImageViewScreen() {
  const [imageUrl, setImageUrl] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  let match = useMatch("/view/:imageId");
  let privateMatch = useMatch("/private/:imageId");

  let imageId = match?.params.imageId || privateMatch?.params.imageId;
  const isPrivate = !!privateMatch?.params.imageId;

  const selectedTags = useTagStore((state) => state.selectedTags);
  const setTag = useTagStore((state) => state.setTag);
  const [isOverload, setIsOverload] = useState(false);
  const [seed, setSeed] = useState(0);
  const [seedOffset, setSeedOffset] = useState(0);
  const [isImageSaved, setIsImageSaved] = useState(false);
  const isSignedIn = useUtilityStore((state) => state.isSignedIn);
  const setSignInRequired = useUtilityStore((state) => state.setSignInRequired);
  const uid = useUtilityStore((state) => state.uid);
  const [version, setVersion] = useState<"" | "v2">("");
  const generator = useTagStore((state) => state.generator);
  const setGenerator = useTagStore((state) => state.setGenerator);
  const [isWaiting, setIsWaiting] = useState(false);
  const navigate = useNavigate();
  const isPro = useUtilityStore((state) => state.isPro);
  const [showFlagModal, setShowFlagModal] = useState(false);
  const [isColorPicking, setIsColorPicking] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);
  const imageCanvasRef = useRef<HTMLCanvasElement>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const clearTags = useTagStore((state) => state.clearTags);
  const [showScaleModal, setShowScaleModal] = useState(false);
  const [isScaled, setIsScaled] = useState(false);
  const [imageNotFound, setImageNotFound] = useState(false);
  const [showImageFolderModal, setShowImageFolderModal] = useState(false);
  const imageEditType = useUtilityStore((state) => state.imageEditType);
  const setImageEditType = useUtilityStore((state) => state.setImageEditType);
  const [model, setModel] = useState("");
  const modelMap = useModelMap();
  const models = useModelStore((state) => state.models);
  const setIsPrivateMode = useUtilityStore((state) => state.setIsPrivateMode);

  // This is used to display the chips for the tags
  const [resolvedStudioTags, setResolvedStudioTags] = useState<
    Record<string, Tag>
  >({});
  const studioTags = useTagStore((state) => state.studioTags);
  const setStudioTags = useTagStore((state) => state.setStudioTags);

  // Painting stuff
  const [imageWidth, setImageWidth] = useState(512);
  const [imageHeight, setImageHeight] = useState(512);

  const [isPainting, setIsPainting] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const detailCanvasRef = useRef<HTMLCanvasElement>(null);
  const [editedFromId, setEditedFromId] = useState("");
  const clearDetailCanvasTimeout = useRef<any>(null);
  const colorHint = useHistoryStore((state) => state.colorHint);
  const setColorHint = useHistoryStore((state) => state.setColorHint);
  const [showColorHint, setShowColorHint] = useState(false);
  const [usingColor, setUsingColor] = useState(false);
  const brushSize = useHistoryStore((state) => state.brushSize);
  const setBrushSize = useHistoryStore((state) => state.setBrushSize);
  const shouldInvertPaintingMask = useHistoryStore(
    (state) => state.shouldInvertPaintingMask
  );
  const setShouldInvertPaintingMask = useHistoryStore(
    (state) => state.setShouldInvertPaintingMask
  );
  const [brushMode, setBrushMode] = useState(0);
  const [paintingGenerator, setPaintingGenerator] = useState("women_crisp");

  // Uncropping
  const [isUncropping, setIsUncropping] = useState(false);
  const [uncropScale, setUncropScale] = useState(1);
  const [waitingUncrop, setWaitingUncrop] = useState(false);
  const [uncropParentId, setUncropParentId] = useState("");
  const [dragX, setDragX] = useState(0);
  const [dragY, setDragY] = useState(0);

  // Enhancing
  const [isWaitingEnhance, setIsWaitingEnhance] = useState(false);
  const isBaseImage = !editedFromId && !uncropParentId;

  // Edits
  const [childrenEditType, setChildrenEditType] = useState<
    "seed_edit" | "other_edits"
  >("seed_edit");

  // Look for url query param called editing
  const urlParams = new URLSearchParams(window.location.search);
  const startEditingType = urlParams.get("editing");

  // Animations
  const [isLoadingGif, setIsLoadingGif] = useState(false);

  // Reference image
  const setReferenceImageUrl = useUtilityStore(
    (state) => state.setReferenceImageUrl
  );

  useEffect(() => {
    if (startEditingType === "painting") {
      setIsPainting(true);
      setIsEditing(true);
    }
  }, [startEditingType]);

  useEffect(() => {
    if (!isBaseImage) {
      setChildrenEditType("other_edits");
    }
  }, [isBaseImage]);

  const startEditing = () => {
    if (!isBaseImage && !isPro) {
      navigate("/getpro");
      toast.error("Smart Edit requires pro mode");
      return;
    }

    setIsEditing(true);
    setIsPainting(false);

    setLocalTags();
  };

  // Sets the tag selector with the correct selected tags
  // If there are unresolved studio tags, we fetch them
  // and temporarily add them to the studio tags for this session.
  const setLocalTags = () => {
    Object.keys(selectedTags).forEach((tag) => {
      setTag(tag, false);
    });

    let unresolvedTags: string[] = [];

    // Set all tags
    tags.forEach((tag) => {
      if (tag.startsWith("studio_")) {
        if (!studioTags.some((t) => t.id === tag)) {
          unresolvedTags.push(tag);
        }
      }

      setTag(tag, true);
    });

    getPublicTagDataFromIds(unresolvedTags).then((data) => {
      // Convert object to array, but add new property "id" which is the key
      const values = Object.keys(data).map((key) => ({
        ...data[key],
        id: key,
        isSaved: true,
      }));

      // Append to studio tags
      setStudioTags([...studioTags, ...values]);
    });
  };

  const stopEditing = () => {
    setIsEditing(false);
    setIsPainting(false);
  };

  const onClickInvertSelection = () => {
    invertSelection(canvasRef.current);
  };

  const onClickGenerate = useCallback(async () => {
    logEvent("click_generate_edit");

    setIsWaiting(true);
    setIsOverload(false);

    // TODO SDXL: Change to use model
    if (!isPro && modelMap[generator].sdModel === "sdxl") {
      navigate("/getpro");
      toast.error("Pro mode required for SDXL");
      return;
    }

    // Free mode cannot use fix details
    if (shouldInvertPaintingMask === "fix") {
      if (!isPro) {
        navigate("/getpro");
      }
    }

    try {
      let result: any;
      let maskData: string = "";
      let promptData: any = null;

      if (!isPainting) {
        // Edits
        let data: any = {
          prompt: generatePayload(),
          generator,
          isPrivate,
        };

        if ((imageEditType === "seed_edit" || !isPro) && isBaseImage) {
          data["editResultId"] = imageId;
        } else {
          data["smartEditId"] = imageId;
          lastSmartEditInfo.lastPromptData = data.prompt;
        }

        result = await sendPrompt(data);
      } else {
        if (!imageId || !canvasRef || !canvasRef.current) {
          setIsWaiting(false);
          return;
        }

        maskData = drawToWhiteCanvas(canvasRef.current);
        promptData = generatePayload();
        result = await submitPainting(
          imageId,
          promptData,
          maskData,
          paintingGenerator,
          shouldInvertPaintingMask,
          usingColor ? colorHint : null,
          isPrivate,
          shouldInvertPaintingMask === "fix"
        );
      }

      if (result.error) {
        if (result.existing_task_id) {
          let url = `/edits/${imageId}/${result.existing_task_id}?type=inpaint`;
          if (isPrivate) {
            url += "&private=true";
          }
          navigate(url);
        } else {
          throw new Error("No task id");
        }
      } else if (result.id) {
        // Store mask locally

        // TODO: Store mask online for persistence?
        if (maskData && canvasRef && canvasRef.current) {
          lastPaintingInfo.lastMask = maskData;
          lastPaintingInfo.lastDidInvert = shouldInvertPaintingMask;
          lastPaintingInfo.lastPromptData = promptData;
          lastPaintingInfo.lastTaskId = result.id;
          lastPaintingInfo.lastMaskAlpha = canvasRef.current.toDataURL();
          lastPaintingInfo.lastGenerator = paintingGenerator;
          lastPaintingInfo.lastPrivate = isPrivate;
          lastPaintingInfo.lastEnhancePaint =
            shouldInvertPaintingMask === "fix";

          // Only store color hint if we're using it
          if (usingColor) {
            // @ts-ignore
            lastPaintingInfo.lastColorHint = colorHint;
          } else {
            lastPaintingInfo.lastColorHint = null;
          }
        }

        let type = "inpaint";

        if (shouldInvertPaintingMask === "fix") {
          type = "enhance";
        }

        if (isEditing && !isPainting) {
          if (imageEditType === "seed_edit") {
            type = "seed_edit";
          } else if (imageEditType === "smart_edit") {
            type = "smart_edit";

            if (imageId) {
              lastSmartEditInfo.smartEditId = imageId;
              lastSmartEditInfo.lastGenerator = generator;
              lastSmartEditInfo.lastPrivate = isPrivate;
              lastSmartEditInfo.lastTaskId = result.id;
            }
          }
        }

        setIsEditing(false);

        let url = `/edits/${imageId}/${result.id}?type=${type}`;
        if (isPrivate) {
          url += "&private=true";
        }
        navigate(url);
      } else {
        setIsOverload(true);
      }
    } catch (e: any) {
      if (e.code === "functions/resource-exhausted") {
        // Silent error for now.
      } else {
        setIsOverload(true);
      }
    }
    setIsWaiting(false);
  }, [
    imageId,
    generator,
    isPainting,
    shouldInvertPaintingMask,
    colorHint,
    usingColor,
    paintingGenerator,
    isPrivate,
    navigate,
    isPro,
    imageEditType,
    isEditing,
  ]);

  useEffect(() => {
    logPage("image_view");
  }, []);

  // Load the inpainting features into canvas
  useEffect(() => {
    if (canvasRef && canvasRef.current) {
      loadDrawing(canvasRef.current, lastPaintingInfo.lastMaskAlpha);
    }
  }, [canvasRef, imageUrl, isPainting, imageWidth, imageHeight]);

  // Change the brush size on the canvas
  useEffect(() => {
    let origCtx = canvasRef.current?.getContext("2d");

    if (origCtx) {
      origCtx.lineWidth = brushSize * 2;
    }
  }, [isPainting, brushSize]);

  // Load image saved status
  useEffect(() => {
    async function fetchImage() {
      if (!imageId) {
        return;
      }

      let saveStatus = await getImageSaveStatus(imageId, uid);
      setIsImageSaved(saveStatus);
    }

    fetchImage();
  }, [imageId, uid]);

  // Fetch image and variations
  useEffect(() => {
    if (!imageId) {
      return;
    }

    if (isPrivate && !uid) {
      return;
    }

    const docRef = isPrivate
      ? doc(db, "users", uid, "private", imageId)
      : doc(db, "results", imageId);

    const fetchData = async () => {
      const docSnap = await getDoc(docRef);
      let data = docSnap.data();

      if (docSnap.exists() && data) {
        setImageUrl(data.image_url);
        if (data.generator) {
          setPaintingGenerator(data.generator);
          setGenerator(data.generator);
        } else {
          setGenerator("women");
        }

        if (data.edited_from) {
          setEditedFromId(data.edited_from);
        }

        // This is a legacy field which indicated that the parent image was uncropped
        if (data.uncrop_parent_image_id) {
          setUncropParentId(data.uncrop_parent_image_id);
        }

        // There are 2 types of upscale, upscale2 and upscale
        if (data.edit_type === "upscale2" || data.edit_type === "upscale") {
          setIsScaled(true);
        } else {
          setIsScaled(false);
        }

        if (data.model) {
          setModel(data.model);
        } else {
          setModel("sd15");
        }
      } else {
        setImageNotFound(true);
        return;
      }

      // Legacy field
      setVersion(data?.version || "");
      setEditedFromId(data?.edited_from || "");
      // Legacy field
      setUncropParentId(data?.uncrop_parent_image_id || "");

      let studioTags: string[] = [];
      if (data) {
        let newTags: string[] = [];

        // Add all tags
        Object.keys(data).forEach((key) => {
          if (TagStrings[key as keyof typeof TagStrings]) {
            newTags.push(key);
          } else if (key.indexOf("studio_") === 0) {
            newTags.push(key);
            studioTags.push(key);
          }
        });
        setTags(newTags);
        setSeed(data.seed);
        setSeedOffset(data.seed_offset);
      }

      // Resolve studio tags
      if (studioTags.length > 0) {
        getPublicTagDataFromIds(studioTags).then((data) => {
          // Make a new object, for each value, add the id
          let resolvedTags: Record<string, Tag> = {};
          Object.keys(data).forEach((key) => {
            resolvedTags[key] = {
              ...data[key],
              id: key,
            };
          });

          setResolvedStudioTags(resolvedTags);
        });
      }
    };
    fetchData();
  }, [imageId, setGenerator, uid, isPrivate]);

  // Tag chips, first sort so all non studio tags are first
  // And then, sort non studio tags alphabetically
  // And sort studio tags alphabetically as well
  const sortedTags = tags.sort((a, b) => {
    if (a.indexOf("studio_") === 0 && b.indexOf("studio_") === 0) {
      // Sort by the resolved tag name, case insensitive
      const resolvedA = resolvedStudioTags[a.replace("studio_", "")];
      const resolvedB = resolvedStudioTags[b.replace("studio_", "")];

      if (!resolvedA || !resolvedB) {
        return 0;
      }

      return resolvedA.name
        .toLocaleLowerCase()
        .localeCompare(resolvedB.name.toLocaleLowerCase(), undefined, {
          sensitivity: "base",
        });
    } else if (a.indexOf("studio_") === 0) {
      return 1;
    } else if (b.indexOf("studio_") === 0) {
      return -1;
    } else {
      // Sort by the resolved tag name, case insensitive
      const resolvedA = TagStrings[a as keyof typeof TagStrings];
      const resolvedB = TagStrings[b as keyof typeof TagStrings];

      if (!resolvedA || !resolvedB) {
        return 0;
      }

      return resolvedA
        .toLocaleLowerCase()
        .localeCompare(resolvedB.toLocaleLowerCase(), undefined, {
          sensitivity: "base",
        });
    }
  });

  const tagChips = sortedTags.map((tag) => {
    if (tag.indexOf("studio_") === 0) {
      const key = tag.replace("studio_", "");

      if (!resolvedStudioTags[key]) {
        return null;
      }

      return (
        <button
          className="text-base px-4 py-2 m-2 border rounded-lg text-white underline bg-purple-700 border-transparent"
          key={tag}
          onClick={() => {
            navigate(`/tags/view/${resolvedStudioTags[key].id}`);
          }}
        >
          {resolvedStudioTags[key].name.toLocaleLowerCase()}
        </button>
      );
    } else {
      return <Chip key={tag} tag={tag} />;
    }
  });

  // Prepend a chip which just says "Private Image"
  if (isPrivate) {
    tagChips.unshift(
      <div className="text-base px-4 py-2 m-2 border rounded-lg text-white select-none bg-blue-700 border-transparent">
        Private Image
      </div>
    );
  }

  let chips = (
    <div className="flex col flex-wrap max-w-lg m-auto">{tagChips}</div>
  );

  const onClickSave = useCallback(() => {
    if (!imageId) {
      return;
    }

    if (!isSignedIn) {
      setSignInRequired(true);
      return;
    }

    if (isImageSaved) {
      deleteSavedImage(imageId, uid);
      setIsImageSaved(false);
    } else {
      saveImage(imageId || "", uid, isPrivate);
      setIsImageSaved(true);
    }
  }, [imageId, uid, isImageSaved, isSignedIn, setSignInRequired, isPrivate]);

  const onClickClearCanvas = useCallback(() => {
    if (canvasRef.current) {
      let ctx = canvasRef.current.getContext("2d");
      ctx &&
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  }, [canvasRef]);

  const onClickChangeBrush = useCallback(() => {
    const newBrush = (brushMode + 1) % BRUSHES.length;
    setBrushMode((prev) => (prev + 1) % BRUSHES.length);

    // If we're switching to eraser, then get the canvas ctx set composite to "destination-out"
    // and change the ctx fill color
    // else set it to "source-over" and set the ctx fill color to black
    if (newBrush === 0) {
      let ctx = canvasRef.current?.getContext("2d");
      ctx && (ctx.globalCompositeOperation = "source-over");
    } else {
      let ctx = canvasRef.current?.getContext("2d");
      ctx && (ctx.globalCompositeOperation = "destination-out");
    }
  }, [brushMode]);

  const onClickCopyTags = useCallback(() => {
    const url = generateTagURL(generator, tags, true, model === "animate");
    navigate(url);
  }, [generator, navigate, tags, model]);

  const onClickUncrop = useCallback(() => {
    if (!isPro) {
      navigate("/getpro");
      toast.error("Pro mode required");
      return;
    }

    setIsUncropping(true);
  }, [isPro, navigate]);

  const onClickCancelUncrop = useCallback(() => {
    setIsUncropping(false);
    setUncropScale(1);
    setDragX(0);
    setDragY(0);
  }, []);

  const onFinishUncrop = useCallback(async () => {
    setIsOverload(false);

    if (!imageId) {
      return;
    }

    let promptData = generatePayloadGeneric(tags, resolvedStudioTags);
    setWaitingUncrop(true);
    try {
      // Scale must be a value between 1 and 0.1
      const scale = ease(uncropScale);
      const offsetX = (dragX / ((512 * 1) / scale / 2)) * 256;
      const offsetY = (dragY / ((512 * 1) / scale / 2)) * 256;

      const result = await submitUncrop(
        imageId,
        scale,
        promptData,
        generator,
        offsetX,
        offsetY,
        isPrivate,
        model
      );

      lastUncropInfo.lastTaskId = result.id;
      lastUncropInfo.scale = scale;
      lastUncropInfo.lastPromptData = promptData as any;
      lastUncropInfo.lastGenerator = generator;
      lastUncropInfo.lastOffsetX = offsetX;
      lastUncropInfo.lastOffsetY = offsetY;
      lastUncropInfo.lastPrivate = isPrivate;
      lastUncropInfo.model = model;

      let url = `/edits/${imageId}/${result.id}?type=uncrop`;
      if (isPrivate) {
        url += "&private=true";
      }
      navigate(url);
    } catch (e) {
      setIsOverload(true);
      console.error(e);
    }
    setWaitingUncrop(false);
  }, [
    imageId,
    uncropScale,
    navigate,
    tags,
    generator,
    dragX,
    dragY,
    isPrivate,
    resolvedStudioTags,
  ]);

  const onClickUpscale = useCallback(async () => {
    if (!isPro) {
      navigate("/getpro");
      toast.error("Pro mode required");
      return;
    }

    if (!imageId) {
      return;
    }

    setShowScaleModal(true);
  }, [isPro, navigate, imageId]);

  const onClickEnhance = useCallback(
    async (isUpscale?: boolean) => {
      if (!isPro) {
        navigate("/getpro");
        toast.error("Pro mode required");
        return;
      }

      if (!imageId) {
        return;
      }

      setIsWaitingEnhance(true);
      let promptData = generatePayloadGeneric(tags, resolvedStudioTags);

      try {
        const result = await submitEnhance(
          imageId,
          generator,
          promptData,
          isPrivate,
          !!isUpscale
        );

        const type = isUpscale ? "upscale" : "enhance";
        let url = `/edits/${imageId}/${result.id}?type=${type}`;
        if (isPrivate) {
          url += "&private=true";
        }
        navigate(url);
      } catch (e) {
        console.error(e);
        toast.error("Please wait 15s between Fix Details");
      }
      setIsWaitingEnhance(false);
    },
    [imageId, navigate, tags, generator, isPro, isPrivate, resolvedStudioTags]
  );

  const onDragUncropImage = useCallback((e: any, data: any) => {
    setDragX(data.x);
    setDragY(data.y);
  }, []);

  const onClickFlag = useCallback(() => {
    setShowFlagModal(!showFlagModal);
  }, [showFlagModal, setShowFlagModal]);

  const onClickDelete = useCallback(() => {
    if (!imageId) {
      return;
    }

    setShowDeleteModal(true);
  }, [imageId]);

  const onClickColorPick = useCallback(() => {
    if (!canvasRef.current) {
      return;
    }

    setIsColorPicking(!isColorPicking);
  }, [isColorPicking]);

  const onSelectColor = useCallback(
    (event: any) => {
      if (!isColorPicking) {
        return;
      }
      let x = event.clientX;
      let y = event.clientY;

      const el = canvasRef.current;
      const ctx = imageCanvasRef.current?.getContext("2d");

      if (!el || !ctx) {
        return;
      }
      // Calculate true canvas point
      const rect = el.getBoundingClientRect();
      let scaleX = el.width / rect.width;
      let scaleY = el.height / rect.height;
      let trueX = Math.floor((x - rect.left) * scaleX);
      let trueY = Math.floor((y - rect.top) * scaleY);

      ctx.getImageData(trueX, trueY, 1, 1);

      // Get color at point
      let pixel = ctx.getImageData(trueX, trueY, 1, 1);
      let data = pixel.data;

      // Convert to hex color
      let hex =
        "#" + ("000000" + rgbToHex(data[0], data[1], data[2])).slice(-6);

      // Set color
      setColorHint(hex);
      setIsColorPicking(false);
    },
    [isColorPicking, setColorHint, canvasRef, imageCanvasRef]
  );

  const getGifUrl = () => {
    // Encode current full imageurl

    const encoded = encodeURIComponent(imageUrl);
    const downloadUrl = `https://us-central1-dreampen-2273f.cloudfunctions.net/convertVideoToGif?videoUrl=${encoded}`;

    return downloadUrl;
  };

  if (imageNotFound) {
    return (
      <div className="text-white text-lg">Image not found or deleted!</div>
    );
  }

  // Edit mode UI
  if (isEditing) {
    chips = (
      <div className="max-w-2xl p-2 m-auto">
        {isPainting && (
          <React.Fragment>
            <div>
              <label htmlFor="default-range" className="block mt-4 text-white">
                Brush size
              </label>
              <input
                id="default-range"
                type="range"
                min="2"
                max="100"
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                value={brushSize}
                onChange={(e) => {
                  setBrushSize(parseInt(e.target.value));

                  // Draw a white circle in the center of the detail canvas
                  let ctx = detailCanvasRef.current?.getContext("2d");
                  if (!ctx || !detailCanvasRef.current) {
                    return;
                  }

                  ctx.clearRect(
                    0,
                    0,
                    detailCanvasRef.current.width,
                    detailCanvasRef.current.height
                  );
                  ctx.beginPath();
                  ctx.arc(
                    detailCanvasRef.current.width / 2,
                    detailCanvasRef.current.height / 2,
                    brushSize,
                    0,
                    2 * Math.PI
                  );
                  ctx.fillStyle = "white";
                  ctx.fill();

                  // Set a timeout to clear the detail canvas
                  // If it already exists, clear it
                  if (clearDetailCanvasTimeout.current) {
                    clearTimeout(clearDetailCanvasTimeout.current);
                  }
                  clearDetailCanvasTimeout.current = setTimeout(() => {
                    if (!detailCanvasRef.current || !ctx) {
                      return;
                    }

                    ctx.clearRect(
                      0,
                      0,
                      detailCanvasRef.current.width,
                      detailCanvasRef.current.height
                    );
                  }, 400);
                }}
              />
            </div>
            <button
              onClick={() => {
                const currentIndex = INPAINT_MODES.indexOf(
                  shouldInvertPaintingMask
                );

                let newIndex = (currentIndex + 1) % INPAINT_MODES.length;

                // SDXL does not support fix details
                if (newIndex === 2 && model === "sdxl") {
                  newIndex = 0;
                }

                setShouldInvertPaintingMask(INPAINT_MODES[newIndex]);
              }}
              className="bg-blue-700 py-2 px-4 rounded-md mt-4 mr-4"
            >
              <span className="font-bold">Mode:</span>{" "}
              {MASK_MODE_TO_NAME[shouldInvertPaintingMask]}
            </button>
            {shouldInvertPaintingMask === "change" && model !== "sdxl" && (
              <>
                <button
                  onClick={() => {
                    setUsingColor(true);
                    setShowColorHint(!showColorHint);
                  }}
                  className="border border-white py-2 px-4 rounded-md mt-4"
                  style={{
                    backgroundColor: usingColor ? colorHint : "transparent",
                  }}
                >
                  Color Hint
                </button>
                <Link
                  to="/tutorials/colorHint"
                  className="inline-block align-middle ml-2"
                >
                  <img alt="" src={InfoIcon} className="h-full aspect-square" />
                </Link>
              </>
            )}
            <div className="flex gap-2 mt-2 decoration-sky-300">
              <div className="flex gap-2">
                <div className="relative flex flex-col items-center group">
                  <div className="select-none overflow-hidden w-auto h-10 flex rounded-lg text-sm font-medium border-2 bg-primary-100 text-primary-700 border-blue-700">
                    <button
                      type="button"
                      className={classNames(
                        "transition-all gap-1.5 h-full flex items-center justify-center py-3 px-5",
                        {
                          "bg-blue-700": brushMode === 0,
                        }
                      )}
                      style={{ width: "auto" }}
                      onClick={() => {
                        onClickChangeBrush();
                      }}
                    >
                      <img
                        src={PaintIcon}
                        alt="Paint"
                        style={{ width: 22, height: 22 }}
                      />
                    </button>
                    <button
                      type="button"
                      className={classNames(
                        "transition-all gap-1.5 h-full flex items-center justify-center py-3 px-5",
                        {
                          "bg-blue-700": brushMode === 1,
                        }
                      )}
                      style={{ width: "auto" }}
                      onClick={() => {
                        onClickChangeBrush();
                      }}
                    >
                      <img
                        src={EraseIcon}
                        alt="Erase"
                        style={{ width: 22, height: 22 }}
                        className="scale-110"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex gap-2">
                <div className="relative flex flex-col items-center group">
                  <button
                    className="transition-all select-none h-10 flex items-center justify-center rounded-lg text-sm font-medium gap-1.5 disabled:opacity-35 disabled:pointer-events-none py-3 px-2 bg-blue-700"
                    onClick={onClickInvertSelection}
                  >
                    <img src={InvertIcon} alt="Invert" />
                  </button>
                </div>
                <div
                  className="relative flex flex-col items-center group"
                  onClick={onClickClearCanvas}
                >
                  <button className="transition-all select-none h-10 flex items-center justify-center rounded-lg text-sm font-medium gap-1.5 disabled:opacity-35 disabled:pointer-events-none py-3 px-5 bg-blue-700">
                    Clear
                  </button>
                </div>
                <div
                  className="relative flex flex-col items-center group"
                  onClick={() => undo(canvasRef.current)}
                >
                  <button className="transition-all select-none h-10 flex items-center justify-center rounded-lg text-sm font-medium gap-1.5 disabled:opacity-35 disabled:pointer-events-none p-0 w-10 bg-blue-700">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.82843 6.99955L8.36396 9.5351L6.94975 10.9493L2 5.99955L6.94975 1.0498L8.36396 2.46402L5.82843 4.99955L13 4.99955C17.4183 4.99955 21 8.58127 21 12.9996C21 17.4178 17.4183 20.9996 13 20.9996H4L4 18.9996H13C16.3137 18.9996 19 16.3133 19 12.9996C19 9.68585 16.3137 6.99955 13 6.99955L5.82843 6.99955Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div
                  className="relative flex flex-col items-center group"
                  onClick={() => redo(canvasRef.current)}
                >
                  <button className="transition-all select-none h-10 flex items-center justify-center rounded-lg text-sm font-medium gap-1.5 disabled:opacity-35 disabled:pointer-events-none p-0 w-10 bg-blue-700">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.1716 6.99955L11 6.99955C7.68629 6.99955 5 9.68585 5 12.9996C5 16.3133 7.68629 18.9996 11 18.9996L20 18.9996V20.9996L11 20.9996C6.58172 20.9996 3 17.4178 3 12.9996C3 8.58127 6.58172 4.99955 11 4.99955H18.1716L15.636 2.46402L17.0503 1.0498L22 5.99955L17.0503 10.9493L15.636 9.5351L18.1716 6.99955Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {showColorHint && (
              <div className="flex justify-center flex-col mt-4 items-center">
                <div className="mb-2">
                  Influence the inpainting selection with a color
                </div>
                <SketchPicker
                  className="text-black"
                  disableAlpha
                  color={colorHint}
                  onChange={(color) => setColorHint(color.hex)}
                />
                <div>
                  <button
                    className="underline text-lg mt-2 mr-4"
                    onClick={() => setShowColorHint(false)}
                  >
                    Done
                  </button>
                  <button
                    onClick={() => {
                      setUsingColor(false);
                      setShowColorHint(false);
                    }}
                    className="underline text-lg mt-2 mr-4"
                  >
                    Clear
                  </button>
                  <button
                    onClick={onClickColorPick}
                    className={classnames(" text-lg mt-2", {
                      underline: !isColorPicking,
                    })}
                  >
                    {isColorPicking ? "(tap on image)" : "Pick Color"}
                  </button>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        <div className="my-8 text-center">
          <div className="text-xl">
            {isPainting ? (
              "Inpainting: Paint on the image above, and optionally choose new tags"
            ) : (
              <div>
                <div>
                  {isBaseImage
                    ? "Editing: Choose new tags"
                    : "Smart Edit: Choose new tags/generator"}
                </div>
                {isBaseImage && isPro && (
                  <div className="flex justify-center flex-col items-center">
                    <div className="text-sm mb-2">
                      {imageEditType === "smart_edit"
                        ? "Edit using image details. Subtle + repeatable"
                        : "Edit using the same seed. Creative + inconsistent"}
                    </div>
                    <Select
                      value={imageEditType}
                      onChange={(e) => {
                        setImageEditType(e.target.value as any);
                      }}
                    >
                      <option value="smart_edit">Smart Edit</option>
                      <option value="seed_edit">Seed Edit</option>
                    </Select>
                  </div>
                )}
                {imageEditType === "smart_edit" && isPro && (
                  <div className="flex justify-center mt-4">
                    <GeneratorSelect
                      onChangeGenerator={(generator) => {
                        setGenerator(generator);
                      }}
                      value={generator as any}
                      onyList={
                        model === "sdxl"
                          ? ["women_hd", "women_intricate", "anime_hd"]
                          : undefined
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {isPainting && (
            <div className="text-md">
              <b>{capitalizeFirstLetter(shouldInvertPaintingMask)}: </b>
              {MASK_MODE_TO_DESCRIPTION[shouldInvertPaintingMask]}
            </div>
          )}
        </div>
        {isPainting && (
          <div>
            {/* Generators available are based on current model */}
            <GeneratorSelect
              onyList={
                model === "sdxl"
                  ? models.filter((m) => m.sdModel === "sdxl").map((m) => m.id)
                  : [
                      "women",
                      "women_crisp",
                      "women_real",
                      "women_photography",
                      "anime",
                      "men",
                      "men_detailed",
                    ]
              }
              onChangeGenerator={(generator) => {
                setGenerator(generator);
                setPaintingGenerator(generator);
              }}
              value={paintingGenerator as any}
              className="ml-2"
            />
          </div>
        )}
        <SelectedTags />
        <AllTagSelector showDefault showSavedTags />
        <div className="bg-gray-700/80 sticky bottom-2 p-4 z-50 backdrop-blur rounded-lg w-fit m-auto">
          <div className="flex justify-center">
            <button
              disabled={isWaiting}
              className="GradientButton mr-4 bg-blue-600 text-white rounded-lg py-4 px-8 disabled:opacity-50"
              onClick={onClickGenerate}
            >
              Generate
            </button>
            <button
              onClick={stopEditing}
              className=" bg-red-500 text-white rounded-lg py-4 px-8"
            >
              Cancel
            </button>
          </div>
          {isOverload && (
            <div className="text-red-500 mt-4">
              {!isPro && (
                <div>
                  <div>Server is overloaded, please try again later.</div>
                  <a
                    className="underline text-yellow-400 font-bold"
                    href="/getpro"
                  >
                    Tired of overloads? Try Pro Mode!
                  </a>
                </div>
              )}
              {isPro && <ProError />}
            </div>
          )}
        </div>
      </div>
    );
  }

  const canvasWidth = Math.min(imageWidth, window.innerWidth);
  return (
    <div className="text-white pb-32">
      {imageId && (
        <FlagModal
          showModal={showFlagModal}
          setShowModal={setShowFlagModal}
          imageUrl={imageUrl}
          imageId={imageId}
        />
      )}
      <Helmet>
        <meta property="og:image" content={imageUrl} />
      </Helmet>
      <div className="flex justify-center flex-col overflow-hidden">
        {imageUrl ? (
          <div
            className={classnames("relative m-auto", {
              "border border-white": isUncropping,
            })}
          >
            {/* Paint canvas */}
            {isPainting && (
              <canvas
                ref={canvasRef}
                width={model === "sdxl" ? 1024 : 512}
                height={model === "sdxl" ? 1024 : 512}
                key={imageWidth + ":" + imageHeight + "paint" + model}
                style={{
                  width: canvasWidth,
                  height: canvasWidth,
                }}
                className="opacity-70 absolute left-0 top-0 z-20 select-none"
                onClick={onSelectColor}
              ></canvas>
            )}
            {/* Detail canvas, for things like the brush indicator */}
            {isPainting && (
              <canvas
                ref={detailCanvasRef}
                width={model === "sdxl" ? 1024 : 512}
                height={model === "sdxl" ? 1024 : 512}
                key={imageWidth + ":" + imageHeight + "canvas" + model}
                style={{
                  width: canvasWidth,
                  height: canvasWidth,
                }}
                className="opacity-70 absolute left-0 top-0 select-none z-10"
              ></canvas>
            )}
            {/* Actual image */}
            <div
              style={{
                // maxWidth: isScaled ? "100%" : canvasWidth,
                transform: `scale(${ease(uncropScale)})`, // must be between 1 and 0.1
              }}
            >
              {/* Set bounds for drag based on a 512x512 window, the scaled image should not leave the bounds */}
              <Draggable
                disabled={!isUncropping}
                scale={ease(uncropScale)}
                position={{ x: dragX, y: dragY }}
                onDrag={onDragUncropImage}
              >
                {model === "animate" ? (
                  <video src={imageUrl} autoPlay loop muted />
                ) : (
                  <img
                    draggable={false}
                    className="max-w-screen"
                    src={resolveImageUrl(imageUrl)}
                    alt="result"
                    ref={imageRef}
                    onLoad={(e) => {
                      const image = e.target as HTMLImageElement;

                      // If not on localhost, set crossorigin
                      if (window.location.hostname !== "localhost") {
                        image.crossOrigin = "anonymous";
                      }

                      setImageWidth(image.width);
                      setImageHeight(image.height);

                      // Draw to imageCanvasRef
                      // TODO fix for SDXL
                      if (!imageCanvasRef.current) return;

                      const ctx = imageCanvasRef.current.getContext("2d");
                      if (!ctx) return;

                      ctx.drawImage(image, 0, 0, 512, 512);
                    }}
                  />
                )}
              </Draggable>
            </div>
          </div>
        ) : (
          <div className="animate-pulse max-w-sm sm:max-w-lg max-h-[512px] w-screen aspect-square bg-zinc-500"></div>
        )}
      </div>
      {isUncropping && (
        <div>
          <div className="mt-4 text-center text-lg">
            Uncrop: Choose a new size and drag to reposition the image.
          </div>
          <div className="text-center">
            {Math.floor(ease(uncropScale) * 100)}%
          </div>
          <div className="flex justify-center">
            <input
              id="default-range"
              type="range"
              min="0.1"
              max="3"
              step="0.01"
              className="w-4/5 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 my-8 max-w-lg"
              value={uncropScale}
              onChange={(e) => {
                let value = parseFloat(e.target.value);
                setUncropScale(value);

                setDragX(0);
                setDragY(0);
              }}
            />
          </div>
          <div className="flex flex-row justify-center mb-8">
            <button
              onClick={onFinishUncrop}
              className="GradientButton text-white rounded-lg py-2 px-4 mr-4 disabled:opacity-30 disabled:animate-pulse"
              disabled={waitingUncrop}
            >
              Done
            </button>
            <button
              onClick={onClickCancelUncrop}
              className="bg-red-500 text-white rounded-lg py-2 px-4 mr-4 disabled:opacity-30"
              disabled={waitingUncrop}
            >
              Cancel
            </button>
          </div>
          {waitingUncrop && (
            <div className="text-center text-lg mb-4">
              <div className="animate-pulse">Loading...</div>
            </div>
          )}
        </div>
      )}
      {!isEditing && !isUncropping && (
        <div className="w-full flex flex-row justify-center">
          <div className="flex justify-center flex-wrap mt-2 mb-8 max-w-lg">
            <button
              onClick={onClickSave}
              className={classNames(
                "text-white py-2 px-4 rounded-lg mr-2 mb-2 border border-transparent w-20 text-center",
                {
                  "border-white": !isImageSaved,
                  "bg-blue-800": isImageSaved,
                }
              )}
            >
              {isImageSaved ? "Saved" : "Save"}
            </button>
            <button
              onClick={() => {
                if (!uid) {
                  setSignInRequired(true);
                  return;
                }

                setShowImageFolderModal(true);
              }}
              className="text-white py-2 px-4 rounded-lg mr-2 mb-2"
              style={{ backgroundColor: "#585858" }}
            >
              <img
                style={{ transform: "scale(1.8)" }}
                className="w-4"
                src={FolderAddSvg}
                alt="flag"
              />
            </button>
            <button
              onClick={startEditing}
              className="bg-blue-600 text-white py-2 px-4 rounded-lg mr-2 mb-2 disabled:hidden"
              disabled={
                isScaled || model === "animate" || generator === "furry"
              }
            >
              Edit Tags
            </button>
            <button
              onClick={() => {
                if (!uid) {
                  setSignInRequired(true);
                  return;
                }

                setIsEditing(true);
                setIsPainting(true);

                // If this is coming back from the edit page, then don't reset tags.
                if (startEditingType !== "painting") {
                  setLocalTags();
                }
              }}
              className="bg-pink-600 py-2 px-4 rounded-lg mr-2 mb-2 disabled:hidden"
              disabled={
                isScaled || model === "animate" || generator === "furry"
              }
            >
              Inpaint
            </button>
            <button
              className="bg-purple-800 text-white py-2 px-4 rounded-lg mr-2 mb-2 disabled:hidden"
              onClick={onClickUpscale}
              disabled={isScaled || model === "animate"}
            >
              Upscale
            </button>
            <button
              onClick={onClickUncrop}
              className="text-white py-2 px-4 rounded-lg mr-2 mb-2 disabled:hidden"
              style={{ backgroundColor: "#cb793a" }}
              disabled={
                isScaled || model === "animate" || generator === "furry"
              }
            >
              Uncrop
            </button>
            <button
              onClick={() => onClickEnhance()}
              className="text-white py-2 px-4 rounded-lg mr-2 mb-2 disabled:animate-pulse"
              style={{
                backgroundColor: "#34781c",
                display: isScaled || model === "animate" ? "none" : "block",
              }}
              disabled={isWaitingEnhance}
            >
              Fix Details
            </button>
            <button
              onClick={onClickCopyTags}
              className="text-white py-2 px-4 rounded-lg mr-2 mb-2"
              style={{ backgroundColor: "#2CA58D" }}
            >
              Use Tags
            </button>
            <button
              onClick={() => {
                if (!uid) {
                  setSignInRequired(true);
                  return;
                }

                if (!isPro) {
                  navigate("/getpro");
                  toast.error("Pro mode required");
                  return;
                }

                setLocalTags();

                // Keep characters private if the image is private
                if (isPrivate) {
                  setIsPrivateMode(true);
                }

                setReferenceImageUrl(imageUrl);
                navigate("/make");
              }}
              className="text-white py-2 px-4 rounded-lg mr-2 mb-2 bg-blue-600 disabled:hidden"
              disabled={generator === "furry"}
            >
              Use Character
            </button>
            {model === "animate" && (
              <a
                className={classNames(
                  "text-white py-2 px-4 rounded-lg mr-2 mb-2 bg-blue-500 flex",
                  {
                    "animate-pulse": isLoadingGif,
                  }
                )}
                href={getGifUrl()}
                onClick={() => {
                  setIsLoadingGif(true);
                }}
              >
                <img src={DownloadIcon} className="w-5 mr-1" alt="" />
                GIF
              </a>
            )}
            {!isPrivate && (
              <button
                onClick={onClickFlag}
                className="text-white py-2 px-4 rounded-lg mr-2 mb-2 flex items-center"
                style={{ backgroundColor: "#585858" }}
              >
                <img
                  style={{ transform: "scale(1.6)" }}
                  className="w-4"
                  src={FlagSvg}
                  alt="flag"
                />
                <span className="ml-2">Report Content</span>
              </button>
            )}
            {isPrivate && (
              <button
                onClick={onClickDelete}
                className="text-white py-2 px-4 rounded-lg mr-2 mb-2"
                style={{ backgroundColor: "#585858" }}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      )}
      {!isPainting && (
        <>
          <ImageRating
            imageId={imageId || ""}
            className="mb-4"
            isPrivate={isPrivate}
          />
          <div className="text-center max-w-lg m-auto">
            Generator: {GENERATOR_TO_STRING[generator]}{" "}
            {model === "animate" && (
              <div className="text-green-500">Animated</div>
            )}
          </div>
        </>
      )}
      {chips}
      {editedFromId && (
        <div className="text-center underline my-4">
          <Link
            to={
              isPrivate ? `/private/${editedFromId}` : `/view/${editedFromId}`
            }
          >
            View original
          </Link>
        </div>
      )}
      {uncropParentId && (
        <div className="text-center underline my-4">
          <Link
            to={
              isPrivate
                ? `/private/${uncropParentId}`
                : `/view/${uncropParentId}`
            }
          >
            View original crop
          </Link>
        </div>
      )}
      <React.Fragment>
        <div className="text-2xl text-center mt-16 mb-4 dark">
          <Button.Group>
            <Button
              className="disabled:opacity-50"
              color={childrenEditType === "seed_edit" ? "blue" : "gray"}
              onClick={() => {
                setChildrenEditType("seed_edit");
              }}
              disabled={!isBaseImage}
            >
              Seed Edits
            </Button>
            <Button
              color={childrenEditType === "other_edits" ? "blue" : "gray"}
              onClick={() => {
                setChildrenEditType("other_edits");
              }}
            >
              Other Edits
            </Button>
          </Button.Group>
        </div>
        <InfiniteImageList
          emptyEndMessage="No edits"
          fullEndMessage="End of list"
          uid={uid}
          key={imageId + childrenEditType + seed}
          getMore={(lastDoc) => {
            let result = getEdits(
              seed,
              seedOffset,
              version,
              lastDoc,
              childrenEditType === "seed_edit" ? "" : imageId || "",
              isPrivate,
              uid,
              imageId || ""
            );
            return result;
          }}
          isPrivate={isPrivate}
          showSave
          skipCheck
        />
      </React.Fragment>
      {/* Image drawn to canvas to grab pixel values */}
      <canvas
        className="hidden"
        width="512"
        height="512"
        ref={imageCanvasRef}
      />
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        imageId={imageId || ""}
        imageUrl={imageUrl}
      />
      <ScaleModal
        showModal={showScaleModal}
        setShowModal={setShowScaleModal}
        imageId={imageId || ""}
        isPrivate={isPrivate}
        onClickEnhance={onClickEnhance}
        promptData={generatePayloadGeneric(tags, resolvedStudioTags)}
        model={model}
        generator={generator}
      />
      <ImageFolderModal
        showModal={showImageFolderModal}
        setShowModal={setShowImageFolderModal}
        imageId={imageId || ""}
        isPrivate={isPrivate}
      />
      <ScrollToTopOverlay />
    </div>
  );
}

function Chip(props: { tag: string }) {
  return (
    <div
      className={classnames(
        "text-base px-4 py-2 m-2 border rounded-lg text-white select-none bg-green-700 border-transparent"
      )}
    >
      {/* @ts-ignore */}
      {TagStrings[props.tag] || props.tag}
    </div>
  );
}
