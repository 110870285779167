import classNames from "classnames";
import { BatchImage, batchMoveImagesToFolder } from "../firebase/functions";
import { useUtilityStore } from "../zustand/utilityStores";
import { ModalContainer } from "./ModalContainer";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";

export function MultiMoveModal(props: {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  images: BatchImage[];
  refreshList: () => void;
}) {
  const folders = useUtilityStore((state) => state.folders);
  const [selectedFolders, setSelectedFolders] = useState<
    Record<string, boolean>
  >({});
  const [isLoading, setIsLoading] = useState(false);

  const onClickFolder = useCallback((id: string) => {
    // Toggle
    setSelectedFolders((selectedFolders) => {
      let newSelectedFolders = { ...selectedFolders };
      if (newSelectedFolders[id]) {
        delete newSelectedFolders[id];
      } else {
        newSelectedFolders[id] = true;
      }
      return newSelectedFolders;
    });
  }, []);

  const onClickConfirm = async () => {
    setIsLoading(true);

    try {
      await batchMoveImagesToFolder(
        props.images,
        Object.keys(selectedFolders),
        {
          removeFromSaved: true,
        }
      );
    } catch (e) {
      console.error(e);
      toast.error("Error moving images");
    }

    toast.success("Moved complete!");
    setIsLoading(false);
    setSelectedFolders({});
    props.refreshList();
    props.setShowModal(false);
  };

  return (
    <ModalContainer
      title="Choose folders"
      showModal={props.showModal}
      setShowModal={props.setShowModal}
    >
      <div className="text-white mb-4">
        Moving {props.images.length} images to the selected folders and remove
        from saved...
      </div>
      <div className="flex flex-row flex-wrap mb-4">
        {folders.map((folder) => {
          return (
            <div key={folder.id} onClick={() => onClickFolder(folder.id)}>
              <div
                className={classNames(
                  "mb-2 mr-2 block text-white border px-4 py-2 rounded-lg cursor-pointer select-none",
                  {
                    "bg-green-500 border-green-500": selectedFolders[folder.id],
                  }
                )}
              >
                <div className="">{folder.name}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center">
        <button
          onClick={onClickConfirm}
          disabled={isLoading || Object.keys(selectedFolders).length === 0}
          className={classNames(
            "bg-blue-600 text-white py-2 px-4 rounded-lg disabled:opacity-30"
          )}
        >
          {isLoading ? "Moving..." : "Confirm"}
        </button>
      </div>
      {isLoading && (
        <div className="text-center text-white mt-4 animate-pulse">
          Moving images, this might take longer for large sets of images.
        </div>
      )}
    </ModalContainer>
  );
}
