import TagSelector from "./TagSelector";
import { TagStore, useTagStore } from "../zustand/tagStore";
import { useEffect } from "react";
import {
  ACCESSORIES,
  ACTION,
  AGE,
  ANIME_ACTION,
  ANIME_STYLE,
  ANIME_SUBJECT,
  ANIME_TAGS,
  BASE,
  BASE_TAGS,
  CHARACTER,
  CLOTHING,
  CLOTHING_MODIFIERS,
  ENVIRONMENT,
  ETHNICITY,
  FACE,
  FURRY_ACTION,
  FURRY_BASE,
  FURRY_GENDER,
  FURRY_STYLE,
  FURRY_TAGS,
  HAIR_COLOR,
  HAIR_STYLE,
  MEN_BASE,
  MEN_TAGS,
  MODIFIERS,
  NEGATIVE,
  NUMBER,
  STYLE,
  TITFUCK_POSE,
  TITFUCK_TAGS,
  TITFUCK_VIEW,
  TOP_LEVEL,
  VIEW,
} from "../zustand/data";
import { CATEGORY_ID_TO_OUTPUT } from "../common/categoryDefinitions";
import { TagSelectorContainer } from "./TagSelectorContainer";
import { useModelStore } from "../zustand/modelStore";

interface Props {
  showDefault?: boolean;
  forceGenerator?: TagStore["generator"];
  showCommunityTags?: boolean;
  showSavedTags?: boolean;
}

export default function AllTagSelector(props: Props) {
  const selectedTags = useTagStore((state) => state.selectedTags);
  const setTag = useTagStore((state) => state.setTag);
  let generator = useTagStore((state) => state.generator);
  const studioTags = useTagStore((state) => state.studioTags);
  const currentModel = useModelStore((state) =>
    state.models.find((m) => m.id === generator)
  );

  // Removing global state.
  if (props.forceGenerator) {
    generator = props.forceGenerator;
  }

  // When the generator changes, carry over as many tags as possible
  useEffect(() => {
    // If url query param called ?tags exists, leave this screen
    if (window.location.search.indexOf("?tags") !== -1) {
      return;
    }

    let filteredKeys = Object.keys(selectedTags)
      .filter((tag) => tag.indexOf("_default") === -1)
      .filter((tag) => selectedTags[tag]);

    // Disable all tags first
    filteredKeys.forEach((tag) => {
      setTag(tag, false);
    });

    setTimeout(() => {
      // When generator changes, reclick all tags
      filteredKeys.forEach((tag) => {
        const element = document.querySelector(
          `[data-tagid="${tag}"]`
        ) as HTMLElement;
        if (element) {
          element.click();
        }
      });
    }, 1000);
  }, [generator]);

  // For the number category, something must be selected at all times
  // TODO_urgent this code is horrible
  useEffect(() => {
    ["number", "base", "clothing"].forEach((categoryId) => {
      let outputId =
        CATEGORY_ID_TO_OUTPUT[categoryId as keyof typeof CATEGORY_ID_TO_OUTPUT];
      let category = TOP_LEVEL[outputId as keyof typeof TOP_LEVEL];

      let numSelected = 0;
      category.forEach((tag) => {
        if (selectedTags[tag]) {
          numSelected++;
        }
      });

      // Also check studio tags
      studioTags.forEach((tag) => {
        if (tag.category === categoryId && selectedTags["studio_" + tag.id]) {
          numSelected++;
        }
      });

      if (numSelected === 0) {
        category.forEach((tag) => {
          if (tag.indexOf("_default") !== -1) {
            setTag(tag, true);
          }
        });
      }
    });

    // Anime must have one subject selected
    // TODO more elegant way to specify tag options
    let numSelected = 0;
    ANIME_SUBJECT.forEach((tag) => {
      if (selectedTags[tag]) {
        numSelected++;
      }
    });

    // Also check studio tags
    studioTags.forEach((tag) => {
      if (
        tag.category === "anime_subject" &&
        selectedTags["studio_" + tag.id]
      ) {
        numSelected++;
      }
    });

    if (
      numSelected === 0 &&
      (generator === "anime" ||
        generator === "anime_detailed" ||
        generator === "anime_hd")
    ) {
      setTag("anime_subject_woman", true);
    }

    // Unselect characters for SDXL.
    // TODO (sdxl): Refactor to use all sdxl models.
    if (currentModel?.sdModel === "sdxl") {
      // Unselect any tags in CHARACTERS
      CHARACTER.forEach((tag) => {
        setTag(tag, false);
      });
    }
  }, [selectedTags, setTag, generator, studioTags]);

  // todo - make this more generic
  if (generator === "doggystyle") {
    return (
      <TagSelectorContainer>
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="tags"
          data={[
            "tags_skinny",
            "tags_chubby",
            "tags_small_ass",
            "tags_big_ass",
          ]}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="ethnicity"
          data={ETHNICITY}
          isSingleChoice
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="hair_color"
          data={HAIR_COLOR}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="hair_style"
          data={HAIR_STYLE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="environment"
          data={ENVIRONMENT}
          isSingleChoice
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="modifiers"
          data={MODIFIERS}
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="negative"
          data={NEGATIVE}
          alphabetical
          isSingleChoice
          subtitle="(beta) Effects image quality, composition, and colors"
        />
      </TagSelectorContainer>
    );
  }

  if (generator === "blowjob") {
    return (
      <TagSelectorContainer>
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="tags"
          data={BASE_TAGS}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="ethnicity"
          data={ETHNICITY}
          isSingleChoice
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="hair_color"
          data={HAIR_COLOR}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="hair_style"
          data={HAIR_STYLE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="style"
          data={STYLE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="modifiers"
          data={MODIFIERS}
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="negative"
          data={NEGATIVE}
          alphabetical
          isSingleChoice
          subtitle="(beta) Effects image quality, composition, and colors"
        />
      </TagSelectorContainer>
    );
  }

  if (generator === "missionary") {
    return (
      <TagSelectorContainer>
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="tags"
          data={BASE_TAGS}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="ethnicity"
          data={ETHNICITY}
          isSingleChoice
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="hair_color"
          data={HAIR_COLOR}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="hair_style"
          data={HAIR_STYLE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="environment"
          data={ENVIRONMENT}
          isSingleChoice
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="modifiers"
          data={MODIFIERS}
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="negative"
          data={NEGATIVE}
          alphabetical
          isSingleChoice
          subtitle="(beta) Effects image quality, composition, and colors"
        />
      </TagSelectorContainer>
    );
  }

  if (generator === "titfuck") {
    return (
      <TagSelectorContainer>
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="base"
          data={BASE}
          isSingleChoice
          showDefault={props.showDefault}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="titfuck_tags"
          data={TITFUCK_TAGS}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="titfuck_pose"
          data={TITFUCK_POSE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="titfuck_view"
          data={TITFUCK_VIEW}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="tags"
          data={BASE_TAGS}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="age"
          data={AGE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="hair_color"
          data={HAIR_COLOR}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="hair_style"
          data={HAIR_STYLE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="ethnicity"
          data={ETHNICITY}
          isSingleChoice
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="style"
          data={STYLE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="environment"
          data={ENVIRONMENT}
          isSingleChoice
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="clothing"
          data={CLOTHING}
          multiSelectDefault="clothes_nude_default"
          alphabetical
          showDefault={props.showDefault}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="clothing_modifiers"
          data={CLOTHING_MODIFIERS}
          alphabetical
          showDefault={props.showDefault}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="accessories"
          data={ACCESSORIES}
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="modifiers"
          data={MODIFIERS}
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="negative"
          data={NEGATIVE}
          alphabetical
          isSingleChoice
          subtitle="(beta) Effects image quality, composition, and colors"
        />
      </TagSelectorContainer>
    );
  }

  if (
    generator === "anime" ||
    generator === "anime_detailed" ||
    generator === "anime_hd"
  ) {
    return (
      <TagSelectorContainer>
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="anime_subject"
          data={ANIME_SUBJECT}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="anime_action"
          data={ANIME_ACTION}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="anime_tags"
          data={ANIME_TAGS}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="anime_style"
          data={ANIME_STYLE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="tags"
          data={BASE_TAGS}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="hair_color"
          data={HAIR_COLOR}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="hair_style"
          data={HAIR_STYLE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="view"
          data={VIEW}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="environment"
          data={ENVIRONMENT}
          isSingleChoice
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="clothing"
          data={CLOTHING}
          multiSelectDefault="clothes_nude_default"
          alphabetical
          showDefault={props.showDefault}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="clothing_modifiers"
          data={CLOTHING_MODIFIERS}
          alphabetical
          showDefault={props.showDefault}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="accessories"
          data={ACCESSORIES}
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="modifiers"
          data={MODIFIERS}
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="negative"
          data={NEGATIVE}
          alphabetical
          isSingleChoice
          subtitle="(beta) Effects image quality, composition, and colors"
        />
      </TagSelectorContainer>
    );
  }

  // Furry generator
  if (generator === "furry") {
    return (
      <TagSelectorContainer>
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="furry_base"
          data={FURRY_BASE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="furry_gender"
          data={FURRY_GENDER}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="furry_tags"
          data={FURRY_TAGS}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="furry_action"
          data={FURRY_ACTION}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="furry_style"
          data={FURRY_STYLE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="tags"
          data={BASE_TAGS}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="hair_color"
          data={HAIR_COLOR}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="hair_style"
          data={HAIR_STYLE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="view"
          data={VIEW}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="environment"
          data={ENVIRONMENT}
          isSingleChoice
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="clothing"
          data={CLOTHING}
          multiSelectDefault="clothes_nude_default"
          alphabetical
          showDefault={props.showDefault}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="clothing_modifiers"
          data={CLOTHING_MODIFIERS}
          alphabetical
          showDefault={props.showDefault}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="accessories"
          data={ACCESSORIES}
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="modifiers"
          data={MODIFIERS}
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="negative"
          data={NEGATIVE}
          alphabetical
          isSingleChoice
          subtitle="(beta) Effects image quality, composition, and colors"
        />
      </TagSelectorContainer>
    );
  }

  if (
    generator === "men" ||
    generator === "men_detailed" ||
    generator === "men_photography"
  ) {
    return (
      <TagSelectorContainer>
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="men_base"
          data={MEN_BASE}
          isSingleChoice
          showDefault={props.showDefault}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="men_tags"
          data={MEN_TAGS}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="age"
          data={AGE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="face"
          data={FACE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="hair_color"
          data={HAIR_COLOR}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="ethnicity"
          data={ETHNICITY}
          isSingleChoice
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="style"
          data={STYLE}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="environment"
          data={ENVIRONMENT}
          isSingleChoice
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="view"
          data={VIEW}
          isSingleChoice
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="clothing"
          data={CLOTHING}
          multiSelectDefault="clothes_nude_default"
          alphabetical
          showDefault={props.showDefault}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="clothing_modifiers"
          data={CLOTHING_MODIFIERS}
          alphabetical
          showDefault={props.showDefault}
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="accessories"
          data={ACCESSORIES}
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="modifiers"
          data={MODIFIERS}
          alphabetical
        />
        <TagSelector
          showCommunityTags={props.showCommunityTags}
          showSavedTags={props.showSavedTags}
          categoryId="negative"
          data={NEGATIVE}
          alphabetical
          isSingleChoice
          subtitle="(beta) Effects image quality, composition, and colors"
        />
      </TagSelectorContainer>
    );
  }

  // All women generators.
  return (
    <TagSelectorContainer>
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="base"
        data={BASE}
        isSingleChoice
        showDefault={props.showDefault}
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="number"
        data={NUMBER}
        isSingleChoice
        showDefault={props.showDefault}
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="tags"
        data={BASE_TAGS}
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="age"
        data={AGE}
        isSingleChoice
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="face"
        data={FACE}
        isSingleChoice
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="hair_color"
        data={HAIR_COLOR}
        isSingleChoice
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="hair_style"
        data={HAIR_STYLE}
        isSingleChoice
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="ethnicity"
        data={ETHNICITY}
        isSingleChoice
        alphabetical
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="style"
        data={STYLE}
        isSingleChoice
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="environment"
        data={ENVIRONMENT}
        isSingleChoice
        alphabetical
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="view"
        data={VIEW}
        isSingleChoice
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="action"
        data={ACTION}
        isSingleChoice
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="clothing"
        data={CLOTHING}
        multiSelectDefault="clothes_nude_default"
        alphabetical
        showDefault={props.showDefault}
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="clothing_modifiers"
        data={CLOTHING_MODIFIERS}
        alphabetical
        showDefault={props.showDefault}
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="accessories"
        data={ACCESSORIES}
        alphabetical
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="modifiers"
        data={MODIFIERS}
        alphabetical
      />
      <TagSelector
        showCommunityTags={props.showCommunityTags}
        showSavedTags={props.showSavedTags}
        categoryId="negative"
        data={NEGATIVE}
        alphabetical
        isSingleChoice
        subtitle="(beta) Effects image quality, composition, and colors"
      />
      {currentModel?.sdModel !== "sdxl" && (
        <TagSelector
          showCommunityTags={false}
          showSavedTags={false}
          categoryId="character"
          data={CHARACTER}
          isSingleChoice
          showDefault={props.showDefault}
          isPro
        />
      )}
    </TagSelectorContainer>
  );
}
