import {
  QueryConstraint,
  limit,
  limitToLast,
  query,
  collection,
  orderBy,
  documentId,
  where,
  getDocs,
  QuerySnapshot,
  DocumentData,
  startAfter,
} from "firebase/firestore";
import { db } from "./setupFirebase";

export interface SearchResult {
  imageUrl: string;
  imageId: string;
  isPrivate?: boolean;
  isUpscaled?: boolean;
  isSelected?: boolean;
}

export async function searchFirestore(
  selectedTags: any,
  maxResults: number,
  constraint?: QueryConstraint,
  isPrev?: boolean,
  isV2Only?: boolean,
  generator?: string
): Promise<[SearchResult[], QuerySnapshot<DocumentData>]> {
  console.log("search firestore");
  let lmt = limit(maxResults);

  if (isPrev) {
    lmt = limitToLast(maxResults);
  }

  let q = query(collection(db, "results"), lmt, orderBy(documentId(), "asc"));
  let filteredKeys = Object.keys(selectedTags)
    .filter((tag) => tag.indexOf("_default") === -1)
    .filter((tag) => selectedTags[tag]);

  if (filteredKeys.length < 3) {
    filteredKeys.forEach((tag) => {
      q = query(q, where(tag, "==", true));
    });
  } else {
    const promptHash = GetPromptHash(selectedTags);
    q = query(q, where("promptHash", "==", promptHash));
  }

  if (constraint) {
    q = query(q, constraint);
  }

  // Remove
  if (isV2Only) {
    q = query(q, where("version", "==", "v2"));
  }

  // Women generator should just be all generators (for now)
  // This is because some women images dont have a generator field set.
  if (generator) {
    q = query(q, where("generator", "==", generator));
  }

  let querySnapshot = await getDocs(q);

  let imageResults: SearchResult[] = [];

  querySnapshot.forEach((doc) => {
    let data = doc.data();

    if (!data.image_url || !data.task_id) {
      return;
    }

    imageResults.push({
      imageId: doc.id,
      imageUrl: data.image_url,
    });
  });
  return [imageResults, querySnapshot];
}

export async function getEdits(
  seed: number,
  seedOffset: number,
  version: "" | "v2",
  lastDoc: any | null,
  editFromId: string | null,
  isPrivate: boolean,
  uid: string,
  parentImageId?: string
): Promise<
  [results: SearchResult[], lastDoc: DocumentData | null, reachedEnd: boolean]
> {
  if (seed === undefined) {
    seed = 0;
  }

  if (isPrivate && !uid) {
    return [[], null, true];
  }

  const collectionRef = isPrivate
    ? collection(db, "users", uid, "private")
    : collection(db, "results");

  let q = query(
    collectionRef,
    where("seed", "==", seed),
    orderBy(documentId(), "asc"),
    limit(20)
  );

  // Seed offset is only used in first version
  if (version) {
    q = query(q, where("version", "==", version));
  }

  // Override any previous results and only set edited_from
  if (editFromId) {
    q = query(
      collectionRef,
      where("edited_from", "==", editFromId),
      orderBy(documentId(), "asc"),
      limit(20)
    );
  }

  if (lastDoc) {
    q = query(q, startAfter(lastDoc));
  }

  let querySnapshot = await getDocs(q);

  let imageResults: SearchResult[] = [];

  querySnapshot.forEach((doc) => {
    let data = doc.data();

    if (!data.image_url || !data.task_id) {
      return;
    }

    // Remove duplicates
    if (parentImageId && doc.id === parentImageId) {
      return;
    }

    imageResults.push({
      imageId: doc.id,
      imageUrl: data.image_url,
    });
  });
  return [
    imageResults,
    querySnapshot.docs[querySnapshot.docs.length - 1],
    querySnapshot.docs.length < 20,
  ];
}

export function GetPromptHash(selectedTags: any): number {
  let allTags: string[] = [];

  Object.keys(selectedTags).forEach((tag) => {
    // Remove default tags, as they are not stored in the database.
    if (tag.indexOf("_default") !== -1) {
      return;
    }

    if (selectedTags[tag]) {
      allTags.push(tag);
    }
  });

  allTags.sort();
  // join the tags
  let joinedTags = allTags.join("");

  // hash the joined tags
  var h = 0,
    l = joinedTags.length,
    i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + joinedTags.charCodeAt(i++)) | 0;
  return h;
}
