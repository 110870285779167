import { useEffect, useState } from "react";
import { adminGetTagInfo, adminUpdateTag } from "../firebase/functions";
import { TagCard } from "./BrowseTags";
import { Tag } from "../common/tagDefinitions";
import { toast } from "react-hot-toast";
import { EditTag } from "./EditTag";
import { useQuery } from "react-query";

export function AdminTagLookup() {
  const [tagQuery, setTagQuery] = useState<string>("");
  const [tag, setTag] = useState<Tag | null>(null);
  const [rejectReason, setRejectReason] = useState<string>("Tag taken down");
  const [_loading, setLoading] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>("");
  const { isLoading, isError, data, refetch, isRefetching, error } = useQuery(
    ["liveTags", tagQuery],
    ({ queryKey }) => adminGetTagInfo(queryKey[1]),
    {
      enabled: false,
      cacheTime: 0,
    }
  );

  // Error handling
  useEffect(() => {
    if (isError) {
      toast.error("Error fetching tag");
      console.error(error);
    }
  }, [isError, error]);

  // Refetch on tagQuery change
  useEffect(() => {
    if (tagQuery) {
      setLoading(true);
      refetch();
    }
  }, [tagQuery, refetch]);

  // Update tag data
  useEffect(() => {
    if (data) {
      setTag(data);
      setPrompt(data.prompt);
      setLoading(false);
    } else {
      setTag(null);
    }
  }, [data, isRefetching]);

  const onClickReject = (tagId: string) => {
    setLoading(true);
    adminUpdateTag(tagId, "rejected", rejectReason)
      .then(() => {
        setLoading(false);
        toast("Tag rejected");
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error rejecting tag");
      });
  };

  const onClickApprove = (tagId: string, isSilent?: boolean) => {
    setLoading(true);
    adminUpdateTag(tagId, "live", undefined, undefined, isSilent)
      .then(() => {
        setLoading(false);
        toast("Tag Approved");
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error approving tag");
      });
  };

  const onClickSavePrompt = (tagId: string) => {
    setLoading(true);
    adminUpdateTag(tagId, undefined, "", prompt)
      .then(() => {
        setLoading(false);
        toast("Prompt saved");
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e.message);
      });
  };

  const loading = isLoading || _loading;

  return (
    <div className="max-w-lg">
      <div className="text-center text-2xl text-white">Tag Lookup</div>
      <div className="flex flex-row mb-2">
        <input
          type="text"
          className="w-full"
          value={tagQuery}
          onChange={(e) => {
            setTagQuery(e.target.value);
          }}
          placeholder="Tag ID"
        />
      </div>
      {loading && <div className="text-white animate-pulse">Loading...</div>}
      {tag && (
        <TagCard
          name={tag.name}
          description={tag.description || ""}
          id={tag.id}
          category={tag.category}
          imageUrl={tag.imageUrl || ""}
          numSaves={tag.numSaves || 0}
        />
      )}
      {tag && (
        <div className="text-white pb-16">
          <div className="text-white text-center mb-4 text-xl">Admin Tools</div>
          <textarea
            className="text-black h-36 w-full"
            onChange={(e) => {
              setPrompt(e.target.value);
            }}
          >
            {prompt}
          </textarea>
          {tag.prompt !== prompt && (
            <button
              disabled={loading}
              className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:animate-pulse mb-2"
              onClick={() => {
                onClickSavePrompt(tagQuery);
              }}
            >
              Save Prompt
            </button>
          )}
          <div className="border border-white p-4 mb-4">
            <EditTag tagId={tagQuery} />
          </div>
          <button
            className="bg-green-500 text-white px-4 py-2 hover:opacity-30 mr-2 disabled:animate-pulse"
            disabled={loading}
            onClick={() => {
              onClickApprove(tagQuery);
            }}
          >
            Approve
          </button>
          <button
            className="bg-green-800 text-white px-4 py-2 hover:opacity-30 mr-2 disabled:animate-pulse"
            disabled={loading}
            onClick={() => {
              onClickApprove(tagQuery, true);
            }}
          >
            Silent Approve
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 hover:opacity-30 disabled:animate-pulse"
            disabled={loading}
            onClick={() => {
              onClickReject(tagQuery);
            }}
          >
            Reject
          </button>
        </div>
      )}
    </div>
  );
}
