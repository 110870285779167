import { DISCORD_LINK } from "../../components/NavBar";

export function ColorHintTutorialScreen() {
  return (
    <div className="text-white pb-32">
      <div className="text-2xl text-center">
        <b>Tutorial:</b> Color Hint
      </div>
      <div className="text-center mb-4 px-4 font-extralight">
        Influence the inpainting selection to be a certain color
      </div>
      <video autoPlay loop muted controls>
        <source src="/tutorials/color_hint.mp4" type="video/mp4" />
      </video>
      <div className="mt-8 text-center">
        <a href={DISCORD_LINK} className="underline">
          For more help, ask on our Discord
        </a>
      </div>
    </div>
  );
}
