export function ContactUs() {
  return (
    <div className="text-white">
      <div className="text-center text-white text-2xl">Contact Us</div>
      <div>Free Journey LLC</div>
      <div>Email us at support@pornpen.ai</div>
      <div>Sheridan, WY USA</div>
    </div>
  );
}
