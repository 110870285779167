import { Button, Label, TextInput } from "flowbite-react";
import React, { useCallback, useState } from "react";
import { useUtilityStore } from "../zustand/utilityStores";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { googleProvider } from "../firebase/auth";
import GoogleButton from "react-google-button";
import classNames from "classnames";
import { ModalContainer } from "./ModalContainer";
import { toast } from "react-hot-toast";

const domain =
  window.location.hostname === "localhost" ? "localhost:3000" : "pornpen.ai";

// Set protocol to http or https based on localhost
const protocol = window.location.hostname === "localhost" ? "http" : "https";

export const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: `${protocol}://${domain}/finishSignUp`,
  // This must be true.
  handleCodeInApp: true,
};

export default function SignInModal(props: { disableModal?: boolean }) {
  const signInRequired = useUtilityStore((state) => state.signInRequired);
  const setSignInRequired = useUtilityStore((state) => state.setSignInRequired);
  const [email, setEmail] = React.useState("");
  const [afterSent, setAfterSent] = React.useState(false);
  const [didStartSignIn, setDidStartSignIn] = React.useState(false);
  const isSignedIn = useUtilityStore((state) => state.isSignedIn);
  const [loginOption, setLoginOption] = React.useState<
    "link" | "password" | "none"
  >("none");

  const [password, setPassword] = React.useState("");
  const [errorText, setErrorText] = React.useState("");

  const handleClose = () => {
    setSignInRequired(false);
  };

  const handleSignIn = useCallback(() => {
    setDidStartSignIn(true);
    const auth = getAuth();
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        setAfterSent(true);
        setDidStartSignIn(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
        toast.error(errorMessage);
        setDidStartSignIn(false);
      });
  }, [email]);

  const onClickSignInGoogle = useCallback(() => {
    setErrorText("");
    const auth = getAuth();
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (!credential) {
          return;
        }
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        const errorMessage = error.message;
        setErrorText(errorMessage);
        // // The email of the user's account used.
        // const email = error.customData.email;
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
      });
  }, []);

  const onClickSignUp = useCallback(() => {
    setErrorText("");
    if (password.length < 8) {
      setErrorText("Password must be at least 8 characters");
      return;
    }

    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // // Signed in
        // const user = userCredential.user;
        // // ...
      })
      .catch((error) => {
        // const errorCode = error.code;
        const errorMessage = error.message;
        setErrorText(errorMessage);
        // // ..
      });
  }, [email, password]);

  const onClickSignInEmail = useCallback(() => {
    setErrorText("");
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // // Signed in
        // const user = userCredential.user;
        // // ...
      })
      .catch((error) => {
        // const errorCode = error.code;
        const errorMessage = error.message;
        setErrorText(errorMessage);
      });
  }, [email, password]);

  if (isSignedIn) {
    return null;
  }

  const modalContent = (
    <div className="flex flex-col gap-4 max-w-lg m-auto">
      <p>
        Create a free account to save images, browse tags, and more. An account
        is also required for Pro mode.
      </p>
      <div>
        Note: Your email will not be shown to users, and will not be associated
        with images that you create.
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="pt-4 mb-2 font-bold">Choose login option</div>
        <div className="mb-4">
          <GoogleButton onClick={onClickSignInGoogle} />
        </div>
        <div>
          <button
            onClick={() => setLoginOption("link")}
            className={classNames(
              "bg-blue-600 text-white py-4 px-8 rounded-lg mb-4",
              {
                "border-2 border-black": loginOption === "link",
              }
            )}
          >
            Send email link
          </button>
        </div>
        <div>
          <button
            onClick={() => setLoginOption("password")}
            className={classNames(
              "bg-gray-600 text-white py-4 px-8 rounded-lg",
              {
                "border-2 border-black": loginOption === "password",
              }
            )}
          >
            Email & password
          </button>
        </div>
      </div>

      {loginOption === "link" && (
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="email1"
              value="Enter your email to receive a link"
            />
          </div>
          <TextInput
            id="email1"
            type="email"
            placeholder="name@gmail.com"
            required={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {afterSent ? (
            <b>
              Check your email for the sign in link. Check the spam folder too!
            </b>
          ) : (
            <div className="mt-2">
              <Button disabled={didStartSignIn} onClick={handleSignIn}>
                Submit
              </Button>
            </div>
          )}
          <PasswordReset email={email}></PasswordReset>
          <div className="text-red-600">{errorText}</div>
        </div>
      )}

      {loginOption === "password" && (
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email2" value="Enter your email and password" />
          </div>
          <div className="mb-2">
            <TextInput
              id="email2"
              type="email"
              placeholder="name@gmail.com"
              required={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <TextInput
              id="password"
              type="password"
              placeholder="password"
              required={true}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex flex-row">
            <button
              className="border text-black border-black rounded-lg py-2 px-4 mr-2"
              disabled={didStartSignIn}
              onClick={onClickSignUp}
            >
              Sign Up
            </button>
            <button
              className="bg-blue-600 text-white py-2 px-4 rounded-lg border border-transparent"
              disabled={didStartSignIn}
              onClick={onClickSignInEmail}
            >
              Sign In
            </button>
          </div>
          <PasswordReset email={email}></PasswordReset>
          {errorText && (
            <div className="text-red-600 mt-2">
              {errorText} Please ask in Discord for more help.
            </div>
          )}
        </div>
      )}
    </div>
  );

  if (props.disableModal) {
    return (
      <div className="bg-neutral-50 p-8 rounded-lg mt-8 mx-2">
        <div className="font-bold text-lg">Sign in to continue</div>
        {modalContent}
      </div>
    );
  }

  return (
    <ModalContainer
      title="Enter your email to sign in..."
      showModal={signInRequired}
      setShowModal={() => {}}
      onClose={handleClose}
      disableDark
    >
      {modalContent}
    </ModalContainer>
  );
}

function PasswordReset(props: { email: string }) {
  const [didSend, setDidSend] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onClickReset = useCallback(() => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, props.email)
      .then(() => {
        setDidSend(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        if (errorMessage.indexOf("auth/missing-email") !== -1) {
          setErrorMessage("Please enter your email address.");
          return;
        }

        setErrorMessage(errorCode + ": " + errorMessage);
      });
  }, [props.email]);

  if (errorMessage) {
    return <div className="text-red-600">{errorMessage}</div>;
  }

  if (didSend) {
    return (
      <div className="text-green-600">
        Check your email for the password reset link. Check the spam folder too!
      </div>
    );
  }

  return (
    <div className="mt-4">
      <button onClick={onClickReset} className="underline">
        Forgot Password
      </button>
    </div>
  );
}
