import create from "zustand";
import { ImageModel } from "../common/modelDefinitions";
import { PRELOAD_MODELS } from "../common/preloadModels";

export interface ModelStore {
  models: ImageModel[];
}

export const useModelStore = create<ModelStore>((set) => ({
  models: PRELOAD_MODELS,
}));

export const useModelMap = () => {
  const { models } = useModelStore();
  return models.reduce((acc, model) => {
    acc[model.id] = model;
    return acc;
  }, {} as { [key: string]: ImageModel });
};
