import React from "react";
import { animated, useSpring, config } from "react-spring";

function BounceInComponent(props: any) {
  const bounceIn = useSpring({
    from: { transform: "scale(0)" },
    to: { transform: "scale(1)" },
    config: { tension: 250, friction: 20 },
  });

  return <animated.div style={bounceIn}>{props.children}</animated.div>;
}

export default BounceInComponent;
