import * as React from "react";
import { Routes, Route } from "react-router-dom";
import App from "./Make";
import "./App.css";
import { DebugOverlay } from "./components/DebugOverlay";
import DisclaimerModal from "./components/DisclaimerModal";
import NavBar from "./components/NavBar";
import ScrollToTop from "./components/ScrollToTop";
import SignInModal from "./components/SignInModal";
import About from "./screens/About";
import { BrowseTags } from "./screens/BrowseTags";
import { EditTag } from "./screens/EditTag";
import EditViewScreen from "./screens/EditViewScreen";
import Feed from "./screens/Feed";
import FinishSignUp from "./screens/FinishSignUp";
import { GetProScreen } from "./screens/GetProScreen";
import ImageViewScreen from "./screens/ImageViewScreen";
import { MyTags } from "./screens/MyTags";
import { Privacy } from "./screens/Privacy";
import { Private } from "./screens/Private";
import Profile from "./screens/Profile";
import { ReverseLookupScreen } from "./screens/ReverseLookupScreen";
import Search from "./screens/Search";
import SubsriberScreen from "./screens/SubscriberScreen";
import { TagStudio } from "./screens/TagStudio";
import { Terms } from "./screens/Terms";
import { ViewTag } from "./screens/ViewTag";
import { Toaster } from "react-hot-toast";
import { TagArtistScreen } from "./screens/TagArtistScreen";
import { ContactUs } from "./screens/ContactScreen";
import { SavedImagesScreen } from "./screens/SavedImagesScreen";
import { FolderViewScreen } from "./screens/FolderViewScreen";
import { CCSuccessScreen } from "./screens/CCSuccessScreen";
import { AdminScreen } from "./screens/AdminScreen";
import { AdminTagReview } from "./screens/AdminTagReview";
import { AdminFlagReview } from "./screens/AdminFlagReview";
import { AdminTagLookup } from "./screens/AdminTagLookup";
import { AdminUserLookup } from "./screens/AdminUserLookup";
import { AdminTagArtist } from "./screens/AdminTagArtist";
import { UidViewScreen } from "./screens/UidViewScreen";
import { Home } from "./screens/Home";
import { ColorHintTutorialScreen } from "./screens/tutorials/ColorHintTutorialScreen";
import { ChatScreen } from "./screens/ChatScreen";
import { EpochMock } from "./screens/EpochMock";
import { TwoTwoFiveSevenScreen } from "./screens/2257";
import { MakeTutorialScreen } from "./screens/MakeTutorialScreen";
import { GamesScreen } from "./screens/GamesScreen";
import { DatingScreen } from "./screens/DatingScreen";
import Footer from "./components/Footer";
import { ReportContentScreen } from "./screens/ReportContent";

export function AppRouter() {
  React.useEffect(() => {
    if (window.location.host.includes("pornziggs")) {
      // Redirect to pornpen.ai
      window.location.href = "https://pornpen.ai";
    }
  }, []);

  return (
    <div className="bg-gray-900 flex flex-col items-center min-h-screen">
      <NavBar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/make" element={<App />} />
        <Route path="/admin" element={<AdminScreen />} />
        <Route path="/admin/tagReview" element={<AdminTagReview />} />
        <Route path="/admin/flagReview" element={<AdminFlagReview />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/admin/tagLookup" element={<AdminTagLookup />} />
        <Route path="/admin/tagArtists" element={<AdminTagArtist />} />
        <Route path="/admin/users" element={<AdminUserLookup />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/feed" element={<Feed />} />
        <Route path="/cc_success" element={<CCSuccessScreen />} />
        <Route path="/saved" element={<SavedImagesScreen />} />
        <Route path="/reverse" element={<ReverseLookupScreen />} />
        <Route path="/about" element={<About />} />
        <Route path="/uid" element={<UidViewScreen />} />
        <Route path="/tags/studio" element={<TagStudio />} />
        <Route path="/tags/manage" element={<MyTags />} />
        <Route path="/tags/edit/:tagId" element={<EditTag />} />
        <Route path="/folder/:folderId" element={<FolderViewScreen />} />
        <Route path="/tags/view/:tagId" element={<ViewTag />} />
        <Route path="/tags/artist/:artistId" element={<TagArtistScreen />} />
        <Route path="/tags/browse" element={<BrowseTags />} />
        <Route path="/search" element={<Search />} />
        <Route path="/getpro" element={<GetProScreen />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/private" element={<Private />} />
        <Route path="/private/:imageId" element={<ImageViewScreen />} />
        <Route path="/subscriber" element={<SubsriberScreen />} />
        <Route path="/2257" element={<TwoTwoFiveSevenScreen />} />
        <Route path="/tutorial" element={<MakeTutorialScreen />} />
        <Route path="/games" element={<GamesScreen />} />
        <Route path="/games/dating" element={<DatingScreen />} />
        <Route path="/games/instagram" element={<ChatScreen />} />
        <Route
          path="/tutorials/colorHint"
          element={<ColorHintTutorialScreen />}
        />
        <Route path="/finishSignUp" element={<FinishSignUp />} />
        <Route path="/view/:imageId" element={<ImageViewScreen />} />
        <Route path="/edits/:imageId/:taskId" element={<EditViewScreen />} />
        <Route path="/chat/with/:chatId" element={<ChatScreen />} />
        <Route path="/chat" element={<ChatScreen />} />
        <Route path="/epoch" element={<EpochMock />} />
        <Route path="/reportcontent" element={ <ReportContentScreen /> } />
      </Routes>
      <DisclaimerModal />
      <SignInModal />
      <DebugOverlay />
      <Toaster position="bottom-center" />
      <Footer />
    </div>
  );
}
