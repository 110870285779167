import classNames from "classnames";
import {
  query,
  collection,
  limit,
  getDocs,
  orderBy,
  startAt,
  onSnapshot,
  QueryConstraint,
  endAt,
  limitToLast,
} from "firebase/firestore";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { logEvent, logPage } from "../analytics/googleAnalytics";
import { FEED_SPEEDS, FilterModal } from "../components/FilterModal";
import { db } from "../firebase/setupFirebase";
import { GENERATOR_TO_STRING, resolveImageUrl } from "../utils/strings";
import { useHistoryStore } from "../zustand/historyStore";

import "./Feed.css";
import { SavingHeart } from "../components/SavingHeart";
import { useUtilityStore } from "../zustand/utilityStores";
import ArrowBack from "../images/arrow_back.svg";
import ArrowForward from "../images/arrow_forward.svg";
import Play from "../images/play.svg";
import Pause from "../images/pause.svg";
import Settings from "../images/settings.svg";
import { isAnimatedUrl } from "../utils/gifs";

interface ImageFeed {
  imageId: string;
  imageUrl: string;
  isPainting?: boolean;
  generator: string;
  blockedTag?: boolean;
  date: Date;
}

export interface FilterSettings {
  showEdits: boolean;
  showGenerators: Record<keyof typeof GENERATOR_TO_STRING, boolean>;
  blockedTags: Record<string, boolean>;
  feedSpeed: string;
}

let wasLive: boolean = true;
let pausedDate = new Date();

export function loadFilterSettingsLocalStorage() {
  // Load from local storage
  const filterSettings = localStorage.getItem("filterSettings");
  if (filterSettings) {
    let result = JSON.parse(filterSettings) as FilterSettings;

    // Default values for new tags to be injected
    // Migrations
    if (!result.blockedTags) {
      result.blockedTags = {};
    }

    if (!result.feedSpeed) {
      result.feedSpeed = "fast";
    }

    if (result.showGenerators.women_accurate === undefined) {
      result.showGenerators.women_accurate = true;
    }

    return result;
  }

  // Default settings for users who don't have any settings saved.
  return {
    showEdits: true,
    showGenerators: {
      women: true,
      women_crisp: true,
      women_real: true,
      women_accurate: true,
      women_photography: true,
      doggystyle: true,
      titfuck: true,
      blowjob: true,
      missionary: true,
      anime: true,
      anime_detailed: false,
      furry: true,
      men: false,
      men_detailed: false,
      men_photography: false,
      women_hd: true,
      women_intricate: true,
      anime_hd: true,
      all: true,
    },
    blockedTags: {},
    feedSpeed: "fast",
  };
}

export default function Feed(props: { showPromo?: boolean }) {
  const [images, setImages] = useState<ImageFeed[]>([]);
  const didAccept = useHistoryStore((state) => state.didAccept);
  const [live, setLive] = useState(wasLive);
  const [isLoading, setIsLoading] = useState(true);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const filterSettings = useUtilityStore((state) => state.filterSettings);
  const setFilterSettings = useUtilityStore((state) => state.setFilterSettings);
  const lastItemAddedTimestamp = useRef(0);
  const [hoveredImage, setHoveredImage] = useState<string>("");

  const filterModal = useMemo(
    () => (
      <FilterModal
        showModal={showFilterModal}
        setShowModal={setShowFilterModal}
        filterSettings={filterSettings}
        setFilterSettings={setFilterSettings}
      />
    ),
    [filterSettings, showFilterModal]
  );

  useEffect(() => {
    if (!wasLive) {
      fetchData(startAt(pausedDate), limit(20));
    }

    logPage("feed");
  }, []);

  // When filter settings changes, save to localStorage
  useEffect(() => {
    localStorage.setItem("filterSettings", JSON.stringify(filterSettings));
  }, [filterSettings]);

  const onClickLive = useCallback(async () => {
    setLive(!live);
    logEvent("toggle_live_feed");
  }, [live]);

  const onClickMore = () => {
    fetchData(startAt(images[images.length - 1].date), limit(20));
    setLive(false);
    logEvent("click_more_feed");
  };

  const onClickPrev = () => {
    fetchData(endAt(images[0].date), limitToLast(20));
    setLive(false);
    logEvent("click_prev_feed");
  };

  const onClickShowFilterModal = () => {
    setShowFilterModal(true);
  };

  const isValid = useCallback(
    (image: ImageFeed) => {
      if (image.isPainting && !filterSettings.showEdits) {
        return false;
      }

      if (
        filterSettings.showGenerators[
          image.generator as keyof typeof GENERATOR_TO_STRING
        ] === false
      ) {
        return false;
      }

      if (image.blockedTag) {
        return false;
      }

      return true;
    },
    [filterSettings.showEdits, filterSettings.showGenerators]
  );

  useEffect(() => {
    wasLive = live;
    if (!live) {
      return;
    }

    let q = query(
      collection(db, "results"),
      limit(20),
      orderBy("create_date", "desc")
    );

    return onSnapshot(q, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type !== "added") {
          return;
        }

        let maxImages = 20;
        // If the window width is greater than 640px
        // each image is 224px wide, and we want 3 rows
        // There is also a grid gap of 10px between images. The remainder of space is the margin
        // calculate the max number of images to show given these constraints
        if (window.innerWidth > 640) {
          maxImages = Math.floor(window.innerWidth / 224) * 3;
        }

        const secondsBetweenImage =
          FEED_SPEEDS[filterSettings.feedSpeed as keyof typeof FEED_SPEEDS] ||
          2;

        if (
          Date.now() - lastItemAddedTimestamp.current <
          secondsBetweenImage * 1000
        ) {
          if (
            filterSettings.feedSpeed !== "max" &&
            images.length === maxImages
          ) {
            return;
          }
        }

        lastItemAddedTimestamp.current = Date.now();

        let doc = change.doc;

        let data = doc.data();
        if (!data.image_url || !data.task_id) {
          return;
        }

        let isPainting = false;
        if (data.edited_from || data.uncrop_parent_image_id) {
          isPainting = true;
        }

        let blockedTag = false;

        Object.keys(filterSettings.blockedTags).forEach((tag) => {
          if (filterSettings.blockedTags[tag] && data[tag]) {
            blockedTag = true;
          }
        });

        const newImage = {
          imageUrl: data.image_url,
          imageId: doc.id,
          isPainting,
          generator: data.generator || "women",
          blockedTag,
          date: data.create_date.toDate(),
        };

        if (!isValid(newImage)) {
          return;
        }

        // If image is already in images, don't add it again
        // TODO why is this happening??
        if (images.find((image) => image.imageId === newImage.imageId)) {
          return;
        }

        pausedDate = data.create_date.toDate();

        setImages((images) => [newImage, ...images].splice(0, maxImages));
      });

      setIsLoading(false);
    });
  }, [live, filterSettings, images.length, isValid, images]);

  const fetchData = async (
    inputQuery: QueryConstraint,
    order: QueryConstraint
  ) => {
    let q = query(collection(db, "results"), orderBy("create_date", "desc"));

    q = query(q, inputQuery);
    q = query(q, order);

    let querySnapshot = await getDocs(q);

    let imageResults: ImageFeed[] = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();

      if (!data.image_url || !data.task_id) {
        return;
      }

      let blockedTag = false;

      Object.keys(filterSettings.blockedTags).forEach((tag) => {
        if (filterSettings.blockedTags[tag] && data[tag]) {
          blockedTag = true;
        }
      });

      imageResults.push({
        imageId: doc.id,
        imageUrl: data.image_url,
        isPainting:
          data.edited_from || data.uncrop_parent_image_id ? true : false,
        generator: data.generator || "women",
        blockedTag,
        date: data.create_date.toDate(),
      });
    });

    if (imageResults.length < 20) {
      setLive(true);
    } else {
      setImages(imageResults);
    }

    pausedDate = imageResults[0].date;
    setIsLoading(false);
  };

  const onHoverImage = useCallback((imageId: string) => {
    setHoveredImage(imageId);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === " ") {
        // Check if the space bar is pressed
        onClickLive();
        event.preventDefault();
      } else if (event.key === "ArrowLeft") {
        // Check if the left arrow key is pressed
        onClickPrev();
      } else if (event.key === "ArrowRight") {
        // Check if the right arrow key is pressed
        onClickMore();
      }
    };

    // Add the event listener to the document when the component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  if (!didAccept) {
    return <div className="text-white">Please accept terms to view Feed</div>;
  }

  const renderFeed = images.map((image, idx) => {
    return (
      <div
        key={image.imageId}
        className="sm:w-56 w-36 relative"
        onMouseOver={() => {
          onHoverImage(image.imageId);
        }}
      >
        {!isValid(image) && (
          <div
            onClick={() => setShowFilterModal(true)}
            className="absolute sm:w-56 w-36 sm:h-56 h-36 backdrop-blur-lg flex justify-center items-center text-white select-none"
          >
            Filtered
          </div>
        )}
        <Link to={`/view/${image.imageId}`}>
          {isAnimatedUrl(image.imageUrl) ? (
            <video src={image.imageUrl} autoPlay loop muted playsInline />
          ) : (
            <img
              className="lazyload"
              data-src={resolveImageUrl(image.imageUrl)}
              alt="result"
            />
          )}
        </Link>
        <SavingHeart
          imageId={image.imageId}
          show={hoveredImage === image.imageId || !live}
        />
      </div>
    );
  });

  return (
    <div className="w-full flex flex-col pb-32">
      {isLoading && (
        <div className="flex justify-center text-white animate-pulse text-2xl">
          Loading...
        </div>
      )}
      {props.showPromo && (
        <div className="flex justify-center">
          <Link
            to="/make"
            className="text-white GradientButton rounded-lg px-4 py-2 text-3xl font-bold mb-4 border-2 hover:opacity-30"
          >
            Create Porn
          </Link>
        </div>
      )}
      <div className="Feed">{renderFeed}</div>
      {filterModal}
      {!isLoading && (
        <div className="fixed bottom-0 w-full flex justify-center pointer-events-none">
          <div className="bg-black/80 mb-4 rounded-lg px-4 backdrop-blur flex flex-row pointer-events-auto">
            <button className="mx-4 hover:scale-95" onClick={onClickPrev}>
              <img className="w-12" src={ArrowBack} alt="Previous page" />
            </button>
            <button
              className={classNames(" hover:scale-95", {
                "animate-pulse": live,
              })}
              onClick={onClickLive}
            >
              <img
                className="w-14"
                src={live ? Pause : Play}
                alt="Play/Pause"
              />
            </button>
            <button className="mx-4 hover:scale-95" onClick={onClickMore}>
              <img className="w-12" src={ArrowForward} alt="Next page" />
            </button>
            <button
              className="mx-4 hover:scale-95"
              onClick={onClickShowFilterModal}
            >
              <img className="w-8" src={Settings} alt="Settings" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
