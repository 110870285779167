import classnames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { submitUpscale, upscaleToSDXL } from "../firebase/functions";
import { ModalContainer } from "./ModalContainer";
import { IS_TEST_CLOUD_FN } from "../test/SetupTest";
import { toast } from "react-hot-toast";
import { Result } from "../generator/tagGenerator";
import { lastSDXLUpscaleInfo } from "../utils/painting";
import { GeneratorSelect } from "./GeneratorSelector";
import { useModelStore } from "../zustand/modelStore";
import { TagStore } from "../zustand/tagStore";

const UPSCALERS = {
  crisp: {
    name: "Crisp",
    description: "Sharp lines, artifacts",
  },
  detailed: {
    name: "Sharp",
    description: "Sharp lines, more details",
  },
  smooth: {
    name: "Smooth",
    description: "Smooth, but blurry",
  },
  fixdetails: {
    name: "Fix Details",
    description:
      "Apply 'Fix Details' while scaling. Fixes faces and some deformations. Takes more time.",
  },
  sdxl: {
    name: "SDXL",
    description: "Upscale using SDXL models. Slightly changes image details.",
  },
};

const SIZES = {
  "2x": {
    name: "2x",
    value: 2,
  },
  "4x": {
    name: "4x",
    value: 4,
  },
};

export function ScaleModal(props: {
  showModal?: boolean;
  isPrivate: boolean;
  setShowModal: (disableModal: boolean) => void;
  imageId: string;
  onClickEnhance: (isUpscale: boolean) => void;
  promptData: Result;
  model?: string;
  generator?: string;
}) {
  const [selectedUpscaler, setSelectedUpscaler] =
    React.useState<string>("crisp");
  const [selectedSize, setSelectedSize] = React.useState<string>("2x");
  const [isWaiting, setIsWaiting] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const models = useModelStore((state) => state.models);

  const isAnime = props.generator && props.generator.includes("anime");

  const [sdxlUpscaleModel, setSDXLUpscaleModel] = React.useState<
    TagStore["generator"]
  >(isAnime ? "anime_hd" : "women_intricate");

  const handleClose = () => {
    props.setShowModal(false);
  };

  const onSelectUpscaler = (upscaler: string) => {
    setSelectedUpscaler(upscaler);

    // If upscaler is fixdetails, set size to 2x
    if (upscaler === "fixdetails" || upscaler === "sdxl") {
      setSelectedSize("2x");
    }
  };

  const onSelectSize = (size: string) => {
    setSelectedSize(size);
  };

  const onClickUpscale = async () => {
    if (selectedUpscaler === "fixdetails") {
      props.onClickEnhance(true);
      handleClose();
      return;
    }

    setIsWaiting(true);

    // SDXL upscale uses a different cloud function
    // Otherwise, just use regular submitUpscale fn
    try {
      const result =
        selectedUpscaler === "sdxl"
          ? await upscaleToSDXL(
              props.imageId,
              props.isPrivate,
              props.promptData,
              sdxlUpscaleModel,
              IS_TEST_CLOUD_FN
            )
          : await submitUpscale(
              props.imageId,
              props.isPrivate,
              selectedUpscaler,
              SIZES[selectedSize as keyof typeof SIZES].value
            );

      if (selectedUpscaler === "sdxl") {
        lastSDXLUpscaleInfo.lastTaskId = result.id;
        lastSDXLUpscaleInfo.lastImageId = props.imageId;
        lastSDXLUpscaleInfo.lastPromptData = props.promptData as any;
        lastSDXLUpscaleInfo.lastPrivate = props.isPrivate;
        lastSDXLUpscaleInfo.lastGenerator = sdxlUpscaleModel;
      }

      setIsWaiting(false);

      const upscaleType =
        selectedUpscaler === "sdxl" ? "upscale_sdxl" : "upscale";
      let url = `/edits/${props.imageId}/${result.id}?type=${upscaleType}`;
      if (props.isPrivate) {
        url += "&private=true";
      }
      navigate(url);
      handleClose();
    } catch (e) {
      // TODO Implement SDXL upscale for edits!
      setIsWaiting(false);
      toast.error("Error upscaling, please try again.");
    }
  };

  const UpscalerButtons = Object.keys(UPSCALERS)
    .filter((key) => {
      if (props.model === "sdxl") {
        if (key === "fixdetails" || key === "sdxl") {
          return false;
        } else {
          return true;
        }
      } else {
        if (props.generator === "furry" && key === "sdxl") {
          return false;
        }

        return true;
      }
    })
    .map((upscaler) => {
      return (
        <button
          key={upscaler}
          className={classnames("px-4 py-2 rounded-lg mr-2 mb-2", {
            "text-white bg-green-500 border border-transparent":
              upscaler === selectedUpscaler,
            "border border-gray-400": upscaler !== selectedUpscaler,
          })}
          onClick={() => onSelectUpscaler(upscaler)}
        >
          {UPSCALERS[upscaler as keyof typeof UPSCALERS].name}
        </button>
      );
    });

  const SizeButtons = Object.keys(SIZES).map((size) => {
    return (
      <button
        key={size}
        className={classnames("px-4 py-2 rounded-lg mr-2 disabled:opacity-30", {
          "text-white bg-green-500 border border-transparent":
            size === selectedSize,
          "border border-gray-400": size !== selectedSize,
        })}
        onClick={() => onSelectSize(size)}
        disabled={
          (selectedUpscaler === "fixdetails" || selectedUpscaler === "sdxl") &&
          size !== "2x"
        }
      >
        {SIZES[size as keyof typeof SIZES].name}
      </button>
    );
  });

  const sdxlModels = models.filter((model) => {
    return model.sdModel === "sdxl";
  });

  return (
    <ModalContainer
      title="Upscale"
      showModal={props.showModal}
      onClose={handleClose}
      setShowModal={() => {}}
    >
      <div className="text-white">
        <div className="font-bold h-12">
          {UPSCALERS[selectedUpscaler as keyof typeof UPSCALERS].description}
        </div>
        <div className="mb-2">Upscaler</div>
        <div className="mb-4">{UpscalerButtons}</div>
        {selectedUpscaler === "sdxl" && (
          <div>
            <div className="mb-2">SDXL Generator</div>
            <GeneratorSelect
              value={sdxlUpscaleModel}
              onyList={
                isAnime
                  ? sdxlModels.map((m) => m.id)
                  : ["women_intricate", "women_hd"]
              }
              onChangeGenerator={(generator) => {
                setSDXLUpscaleModel(generator);
              }}
            ></GeneratorSelect>
          </div>
        )}
        <div className="mb-2">Size</div>
        <div className="mb-4">{SizeButtons}</div>
      </div>
      <div className="flex justify-center">
        <button
          className="GradientButton text-white px-8 py-4 rounded-lg disabled:animate-pulse text-xl"
          onClick={onClickUpscale}
          disabled={isWaiting}
        >
          {isWaiting ? "Loading..." : "Upscale"}
        </button>
      </div>
    </ModalContainer>
  );
}
